import {
  Image,
  Badge,
  Button,
  Flex,
  Select,
  Text,
  Divider,
  ScrollArea,
  Box,
  Skeleton,
} from '@mantine/core';
import {
  IconBath,
  IconBed,
  IconCircleCheck,
  IconDownload,
  IconHome,
  IconLoader,
  IconStatusChange,
} from '@tabler/icons-react';
import {
  useAddress,
  useExportToCRM,
  useInboxStore,
  useLead,
  useLeadContract,
  useOrganisationStore,
  useUpdateLead,
} from 'fe/queries';
import React, { useEffect } from 'react';
import classes from './ConversationDetailSidenav.module.scss';
import { IconCashBanknote } from '@tabler/icons-react';
import {
  Products,
  StatusOfCampaignProperty,
  StatusOfCampaignPropertyLabels,
} from 'shared/db';
import { formatPhoneNumber, generateOfferPrice } from 'shared/formatter';
import { CreateNoteButton } from './CreateNoteButton';
import { ZillowLogo } from 'fe/ui/shared';
import { notifications } from '@mantine/notifications';
import { trackLeadStatusChange } from 'fe/analytics';
import { PropertyItem } from './Sidenav/PropertyInfoItem';
const CampaignTypeMapping: Record<Products, string> = {
  [Products.LISTINGS]: 'Active Listings',
  [Products.EXPIRED]: 'Expired Listings',
  [Products.COMMERCIAL]: 'Commercial Listings',
  [Products.FSBO]: 'FSBO Listings',
};

export function ConversationDetailSidenav() {
  const item = useInboxStore((state) => state.activeInboxItem);
  if (!item) return null;
  const { data: lead } = useLead(item.status_id);
  const {
    mutateAsync: updateLead,
    isPending: updatingLead,
    isSuccess: leadWasUpdated,
    reset: resetLeadUpdateQuery,
  } = useUpdateLead(item.status_id);
  const { data: address, isLoading: isLoadingAddress } = useAddress(
    item.zip_code,
  );
  const { organisation } = useOrganisationStore();
  const { mutateAsync: exportToCRM, isPending: loadingExport } =
    useExportToCRM();

  const { data: contractPDF, isLoading: isLoadingContract } = useLeadContract(
    Number(item.status_id),
  );

  let SelectIcon = <IconStatusChange size="1rem" />;
  if (updatingLead) {
    SelectIcon = <IconLoader color="blue" size="1rem" />;
  } else if (leadWasUpdated) {
    SelectIcon = <IconCircleCheck color="green" size="1rem" />;
  }

  useEffect(() => {
    if (leadWasUpdated) {
      setTimeout(() => {
        resetLeadUpdateQuery();
      }, 1000);
    }
  }, [leadWasUpdated]);

  return (
    <Flex
      direction="column"
      className={classes.wrapper}
      justify="space-between"
      h="100%"
      pb="lg"
    >
      <Flex direction="column" px="sm" gap="xs">
        <Flex justify="flex-start" mt="md" gap="sm">
          <Image src={item.img_src} radius="md" width={60} height={60} />
          <div>
            <Text fw={600} fz="md">
              {item.street_address}
            </Text>
            <Text c="dimmed" fz="sm">
              {isLoadingAddress ? (
                <Skeleton height={12} mt={8} radius="xl" />
              ) : (
                `${address?.city}, ${address?.state_id} ${item.zip_code}`
              )}
            </Text>
          </div>
        </Flex>

        <Flex gap="xs" direction="column">
          <Button
            variant="outline"
            style={{ borderColor: '#0069FF' }}
            c="#0069FF"
            onClick={() => {
              const externalUrl = `https://www.zillow.com/homedetails/p/${item.zpid}_zpid/?`;
              window.open(externalUrl, '_blank');
            }}
            leftIcon={<Image src={ZillowLogo} width={18} />}
          >
            Visit Zillow
          </Button>
        </Flex>

        <Select
          aria-label="Select status"
          disabled={updatingLead}
          mb="xs"
          size="sm"
          label="Status"
          placeholder="Pick a new status"
          value={lead?.status}
          icon={SelectIcon}
          onChange={async (value) => {
            if (value) {
              const oldValue = lead?.status || null;
              await updateLead(value as StatusOfCampaignProperty);
              await trackLeadStatusChange({
                old_status: oldValue,
                new_status: value,
                street_address: item.street_address,
                zip_code: item.zip_code,
              });
              notifications.show({
                title: 'Status updated',
                message: 'The status has been updated successfully',
              });
            }
          }}
          data={StatusOfCampaignPropertyLabels}
        />
      </Flex>

      <Box style={{ position: 'relative', flex: 1 }}>
        <ScrollArea
          style={{ position: 'absolute', height: '100%', width: '100%' }}
          px="sm"
        >
          <Flex
            direction="column"
            justify="space-between"
            h="100%"
            pb="lg"
            gap="xs"
          >
            <PropertyItem
              icon={<IconCashBanknote />}
              label="Listed price"
              value={item.price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
              color="green"
            />

            <PropertyItem
              icon={<IconCashBanknote />}
              label="Offer price"
              value={
                item.offer_price?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }) ||
                generateOfferPrice(
                  item.price,
                  item.campaign_price_discount,
                ).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })
              }
              color="grape"
            />

            <PropertyItem
              icon={<IconHome />}
              label="Property Type"
              value={item.property_type}
              color="pink"
            />

            <PropertyItem
              icon={<IconBed />}
              label="Beds"
              value={item.bedrooms || 0}
              color="yellow"
            />

            <PropertyItem
              icon={<IconBath />}
              label="Baths"
              value={item.bathrooms || 0}
              color="orange"
            />

            {item.agent_phone && (
              <>
                <Divider />
                <Text
                  size="sm"
                  variant="light"
                  component="a"
                  href={`tel:+1${item.agent_phone}`}
                >
                  {formatPhoneNumber(item.agent_phone)}
                </Text>
              </>
            )}

            <Divider />

            <Text
              size="sm"
              variant="light"
              component="a"
              href={`mailto:+1${item.agent_email}`}
            >
              {item.agent_email}
            </Text>

            <Divider />

            {/*  */}
          </Flex>
        </ScrollArea>
      </Box>

      <Flex px="sm" mt="sm" direction="column" gap="lg">
        <CreateNoteButton item={item} />
        <div>
          <Badge size="sm" variant="transparent" radius="xs" fullWidth>
            {item.campaign_type &&
              CampaignTypeMapping[item.campaign_type as Products]}
          </Badge>
          <Text fz="sm" align="center">
            {item.campaign_name}
          </Text>
        </div>

        {contractPDF || isLoadingContract ? (
          <Button
            color="gray.7"
            variant="subtle"
            loading={isLoadingContract}
            onClick={() => {
              const a = document.createElement('a');
              a.href = 'data:application/pdf;base64,' + contractPDF;
              a.download = `Contract for ${item.street_address}, ${item.zip_code}.pdf`;
              a.click();
            }}
            compact
            leftIcon={<IconDownload size="1rem" />}
          >
            Download contract
          </Button>
        ) : null}

        {organisation?.lead_webhook_url && (
          <Button
            loading={loadingExport}
            onClick={() =>
              exportToCRM({
                street_address: item.street_address,
                zip_code: item.zip_code,
                organisation,
              })
            }
            variant="outline"
            fullWidth
            size="sm"
            compact
          >
            Export to CRM
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
