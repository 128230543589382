import { SupabaseClient } from '@supabase/supabase-js';

export interface CampaignPropertyNote {
  id: string;
  created_at: string;
  content: string;
  street_address: string;
  zip_code: number;
  organisation_id: number;
}

export type NewCampaignPropertyNote = Omit<
  CampaignPropertyNote,
  'id' | 'created_at'
>;

export async function createPropertyNote(
  supabase: SupabaseClient,
  newNote: NewCampaignPropertyNote,
): Promise<CampaignPropertyNote> {
  const { data, error } = await supabase
    .from('campaign_property_notes')
    .insert(newNote)
    .select()
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't create note");
  }

  return data;
}
