import { Container, Flex, Title, Image, Anchor, } from '@mantine/core'
import { Gmail, Outlook, Typography } from 'fe/ui/shared'
import React from 'react'

export function CheckYourEmail() {
    return (

        <Container size={600} >
            <Flex mb={40} direction="column">
                <Typography align="center" c="dimmed" variant="label" fz="xl">SIGN IN</Typography>
                <Title align="center" order={1}>Check your email</Title>

                <Typography c="dimmed" fz="xl" mt="xs" align='center' w="100%">
                    We've sent you an email with a link to log in. Please check your inbox.
                </Typography>
            </Flex>


            <Flex mt={100} justify="center" gap="xl">
                <Anchor href='https://mail.google.com/mail/u/0/' target='__blank'>
                    <Flex align="center" gap="xs">
                        <Image
                            src={Gmail}
                            width={24}
                        />
                        Open Gmail
                    </Flex>
                </Anchor>

                <Anchor href='https://outlook.live.com/mail/0/inbox' target='__blank'>
                    <Flex align="center" gap="xs">
                        <Image
                            src={Outlook}
                            width={24}
                        />
                        Open Outlook
                    </Flex>
                </Anchor>
            </Flex>
        </Container>
    )
}