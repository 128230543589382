import { Flex, Text } from '@mantine/core';
import React from 'react';
import { CampaignPropertyNote } from 'shared/db';
import classes from './ConversationMessage.module.scss';

interface ConversationNoteProps {
  note: CampaignPropertyNote;
}

export function ConvoItemNote({ note }: ConversationNoteProps) {
  return (
    <Flex
      className={`${classes.bubble} ${classes.note}`}
      style={{ flex: 1 }}
      direction="column"
      gap="xs"
      w="100%"
      bg={'yellow.0'}
    >
      <Text>{note.content}</Text>

      <Flex justify="space-between">
        <Text fz="sm" c="dimmed">
          {note.created_at
            ? `Created on ${new Date(note.created_at).toLocaleString()}`
            : 'New'}
        </Text>
      </Flex>
    </Flex>
  );
}
