import { Box } from '@mantine/core';
import { TeamMembers } from '../TeamMembers';
import { useOrganisationStore } from 'fe/queries';
import { OrganisationBasicForm } from './../BasicForm/BasicForm';

export function Overview() {
  const { organisation } = useOrganisationStore();
  return (
    <Box>
      <OrganisationBasicForm organisation={organisation!} />

      <Box pt="xl">
        <TeamMembers organisation={organisation!} />
      </Box>
    </Box>
  );
}
