import { ApiEndpoint } from './../types';
import { z } from 'zod';

const downloadAttachmentRouteSchema = z.object({
  attachment_id: z.number(),
});

export const downloadAttachmentEndpoint: ApiEndpoint<
  typeof downloadAttachmentRouteSchema
> = {
  apiUrl: '/attachments/:attachment_id',
  clientUrl: ({ attachment_id }) => `/attachments/${attachment_id}`,
  method: 'POST',
};
