import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  CampaignPropertyNote,
  InboxItem,
  NewCampaignPropertyNote,
  Organisation,
  Products,
  PropertyMessage,
  createPropertyNote,
} from 'shared/db';
import { callApi, postApi, supabase } from '../utils';
import { useInboxStore } from './useInboxStore';
import { notifications } from '@mantine/notifications';
import { getLabelFromUrl, getRandomInt } from 'shared/formatter';
import {
  analyzeLeadInboxEndpoint,
  downloadAttachmentEndpoint,
  fetchLeadInboxEndpoint,
  organizationFetchInboxEndpoint,
  postLeadAiFeedbackEndpoint,
  sendEmailEndpoint,
} from 'shared/api';
import {
  LeadAiSuggestions,
  LeadStatusChanges,
  PropertyMessageAttachments,
  PropertyMessageWithAttachments,
} from 'be/db';
import { Selectable } from 'kysely';

export const useInboxItems = (
  organisation_id: number | undefined,
  search_term: string | undefined = undefined,
  lead_status: string[] | undefined = undefined,
  campaign_ids: number[] | undefined = undefined,
  campaign_type: Products | undefined = undefined,
) => {
  return useInfiniteQuery<InboxItem[]>({
    gcTime: 0,
    queryKey: [
      'inbox',
      organisation_id,
      search_term,
      lead_status,
      campaign_ids,
      campaign_type,
    ],
    queryFn: ({ pageParam }) =>
      organisation_id
        ? callApi(
            organizationFetchInboxEndpoint,
            {
              organization_id: organisation_id,
            },
            {
              search_term: search_term || null,
              lead_status: lead_status || null,
              campaign_ids: campaign_ids || null,
              campaign_type: campaign_type as string,
              latest_property_message_id: Number(pageParam),
            },
          ).then((res) => {
            return res.data;
          })
        : [],
    getNextPageParam: (lastPage) => {
      if (lastPage.length === 0) {
        return null;
      }
      return lastPage[lastPage.length - 1].latest_property_message_id;
    },
    initialPageParam: '',
  });
};

interface SendEmailParams {
  street_address: string;
  zip_code: number;
  organisation: Organisation;
  content: string;
}

export const sendEmail = async ({
  street_address,
  zip_code,
  organisation,
  content,
}: SendEmailParams): Promise<PropertyMessage> => {
  await callApi(
    sendEmailEndpoint,
    {
      organization_id: organisation.id,
    },
    {
      street_address,
      zip_code,
      content,
    },
  );

  // Create fake message for instant display
  return {
    id: getRandomInt(100, 2000),
    created_at: new Date().toISOString(),
    content,
    outbound: true,
    type: 'EMAIL',
    subject: 'New email',
    campaign_id: null,
    campaign_step_id: null,
    email_id: null,
    street_address,
    zip_code: Number(zip_code),
    organisation_id: organisation.id,
    email_opened: null,
    email_clicked: null,
    email_status: null,
    sendgrid_msg_id: null,
    sentiment_analysis: null,
    counter_offer: null,
    workflow_step_id: null,
  };
};

export const useSendEmail = (organisation_id: number) => {
  const queryClient = useQueryClient();
  const { setActiveInboxItem, activeInboxItem } = useInboxStore(
    (state) => state,
  );
  const newActiveInboxItem: InboxItem = { ...activeInboxItem! };
  return useMutation({
    mutationFn: sendEmail,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['inbox', organisation_id],
      });
      queryClient.setQueriesData<LeadInbox>(
        { queryKey: ['lead_inbox', activeInboxItem?.status_id] },
        (previous: any) =>
          previous
            ? {
                ...previous,
                messages: [...previous.messages, data],
              }
            : {
                messages: [data],
                notes: [],
                suggestedActions: [],
              },
      );
      setActiveInboxItem(newActiveInboxItem);
    },
  });
};

export const useMarkAllMessagesAsSeen = (
  lead_id: number,
  organisation_id: number,
  searchTerm: string | undefined = undefined,
  leadStatus: string[] | undefined = undefined,
  campaignIds: number[] | undefined = undefined,
  campaign_type: Products | undefined = undefined,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: markMessagesAsSeen,
    onSuccess: () => {
      queryClient.setQueriesData<LeadInbox>(
        { queryKey: ['lead_inbox', lead_id] },
        (previous) => {
          if (!previous) return previous;
          return {
            ...previous,
            messages: previous?.messages.map((message) => ({
              ...message,
              attachments: message.attachments || [],
              seen: true,
            })),
          };
        },
      );

      queryClient.setQueriesData(
        {
          queryKey: [
            'inbox',
            organisation_id,
            searchTerm,
            leadStatus,
            campaignIds,
            campaign_type,
          ],
        },
        (previous: any) => {
          const pages = previous?.pages || [];
          const updatedPages = pages.map((page: any) =>
            page.map((item: any) =>
              item.status_id !== lead_id ? item : { ...item, num_unseen: 0 },
            ),
          );
          return {
            pages: updatedPages,
            pageParams: previous?.pageParams,
          };
        },
      );
    },
  });
};

export const markMessagesAsSeen = async (
  propertyMessages: { id: number }[],
) => {
  const allPromises = propertyMessages.map((message) =>
    supabase
      .from('campaign_property_messages')
      .update({ seen: true })
      .eq('id', message.id),
  );

  await Promise.all(allPromises);
};

interface ExportToCRMParams {
  street_address: string;
  zip_code: number;
  organisation: Organisation;
}

const exportToCRM = async ({
  street_address,
  zip_code,
  organisation,
}: ExportToCRMParams) => {
  return postApi<PropertyMessage>(
    `/sendLeadWebhook?organisation_id=${organisation.id}`,
    {
      street_address,
      zip_code,
    },
  );
};

export const useExportToCRM = () => {
  return useMutation({
    mutationFn: exportToCRM,
    onSuccess: () => {
      notifications.show({
        title: 'Lead has been exported to CRM',
        message: null,
      });
    },
  });
};

interface ForwardToParams {
  street_address: string;
  zip_code: number;
  forward_to_email: string;
}

const forwardConversation = async ({
  street_address,
  zip_code,
  forward_to_email,
}: ForwardToParams) => {
  return postApi<PropertyMessage>(`/forwardConversation`, {
    street_address,
    zip_code,
    forward_to_email,
  });
};

export const useForwardConversation = () => {
  return useMutation({
    mutationFn: forwardConversation,
    onSuccess: () => {
      notifications.show({
        title: 'Email has been forwarded',
        message: null,
      });
    },
  });
};

export const useCreateNote = (organisation_id: number) => {
  const queryClient = useQueryClient();
  const { setActiveInboxItem, activeInboxItem } = useInboxStore(
    (state) => state,
  );
  const newActiveInboxItem: InboxItem = { ...activeInboxItem! };
  return useMutation({
    mutationFn: (newLead: NewCampaignPropertyNote) =>
      createPropertyNote(supabase, newLead),
    onSuccess: (data) => {
      queryClient.setQueriesData<LeadInbox>(
        { queryKey: ['lead_inbox', activeInboxItem?.status_id] },
        (previous) =>
          previous && {
            ...previous,
            notes: [...previous.notes, data],
          },
      );
      queryClient.invalidateQueries({
        queryKey: ['inbox', organisation_id],
      });
      setActiveInboxItem(newActiveInboxItem);
    },
  });
};

export interface LeadInbox {
  messages: PropertyMessageWithAttachments[];
  notes: CampaignPropertyNote[];
  suggestedActions: Selectable<LeadAiSuggestions>[];
  statusChanges: Selectable<LeadStatusChanges>[];
}

export const useLeadInbox = (status_id: number | undefined) =>
  useQuery<LeadInbox | undefined>({
    queryKey: ['lead_inbox', status_id],
    queryFn: () =>
      status_id
        ? callApi(fetchLeadInboxEndpoint, { lead_id: Number(status_id) }).then(
            (res) => res.data,
          )
        : {
            messages: [],
            notes: [],
            suggestedActions: [],
            statusChanges: [],
          },
  });

export const useDownloadAttachment = () => {
  return useMutation({
    mutationFn: (attachment: Selectable<PropertyMessageAttachments>) =>
      callApi(
        downloadAttachmentEndpoint,
        {
          attachment_id: attachment.id,
        },
        undefined,
      ).then((res) => res.data),
    onSuccess: (data, attachment) => {
      const a = document.createElement('a');
      a.href = 'data:application/pdf;base64,' + data;
      a.download = getLabelFromUrl(attachment.filename) || 'attachment.pdf';
      a.click();

      notifications.show({
        title: 'Attachment downloaded',
        message: null,
      });
    },
  });
};

export const useAnalyzeInbox = (lead_id: number) =>
  useMutation({
    mutationFn: () =>
      callApi(analyzeLeadInboxEndpoint, {
        lead_id,
      }),
    onSuccess: () => {
      notifications.show({
        title: 'Inbox analysis has been scheduled.',
        message: null,
      });
    },
  });

export const useGiveAiSuggestionFeedback = (suggestion_id: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (approved: boolean) =>
      callApi(
        postLeadAiFeedbackEndpoint,
        {
          suggestion_id,
        },
        {
          approved,
        },
      ).then((res) => res.data),
    onSuccess: (data) => {
      queryClient.setQueriesData<LeadInbox>(
        { queryKey: ['lead_inbox', data.lead_id] },
        (previous) =>
          previous && {
            ...previous,
            suggestedActions: previous.suggestedActions.filter(
              (suggestion) => suggestion.id !== suggestion_id,
            ),
          },
      );
    },
  });
};
