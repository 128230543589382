import { Flex } from '@mantine/core'
import { OfferSettings, PropertyInfo } from 'fe/feature/arv';
import { Layout } from 'fe/feature/layout';
import { offerRoute } from '../../router';
import { useProperty } from 'fe/queries';
import { useNavigate } from '@tanstack/react-router';

export function OfferToolPage() {
  const { street_address, zip_code } = offerRoute.useParams();
  const { data: property, isLoading } = useProperty(decodeURIComponent(street_address), Number(zip_code))
  const navigate = useNavigate()

  const redirectToInbox = () => navigate({
    to: '/', search: () => ({})
  })

  return (
    <Layout noScrolling noPadding>
      <Flex h="100%" w="100%">
        <PropertyInfo property={property} isLoading={isLoading} />

        <OfferSettings property={property} onApprove={redirectToInbox} />
      </Flex>
    </Layout>
  )
}
