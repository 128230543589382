import {
  Divider,
  Flex,
  Text,
  Paper,
  Mark,
  ActionIcon,
  Tooltip,
} from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { useGiveAiSuggestionFeedback } from 'fe/queries';
import React from 'react';

interface ConvoSuggestionSkeletonProps {
  suggestion_id: number;
  title: React.ReactNode;
  description?: string;
  reasoning: string;
  callToActions: React.ReactNode;
}

export function ConvoSuggestionSkeleton({
  suggestion_id,
  title,
  description,
  reasoning,
  callToActions,
}: ConvoSuggestionSkeletonProps) {
  const { mutateAsync: createFeedback, isPending: isCreatingFeedback } =
    useGiveAiSuggestionFeedback(suggestion_id);
  return (
    <Paper p="sm">
      <Flex align="center" justify="space-between">
        <Flex direction="column" gap="xs">
          <Text size="sm" fw="bold">
            {title}
          </Text>

          {description && (
            <Mark color="violet.0" p={4}>
              <Text>{description}</Text>
            </Mark>
          )}
        </Flex>

        <Flex gap="xs">
          {callToActions}

          <Tooltip label="Dismiss. We will recommend less suggestions like this in the future.">
            <ActionIcon
              onClick={() => createFeedback(false)}
              loading={isCreatingFeedback}
              variant="outline"
            >
              <IconTrash size="1.125rem" />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </Flex>

      <Divider my="md" />

      <Text c="dimmed" size="sm">
        Reasoning: {reasoning}
      </Text>
    </Paper>
  );
}
