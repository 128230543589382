import {
  ActionIcon,
  Text,
  Flex,
  Switch,
  TextInput,
  rem,
  useMantineTheme,
  Box,
  Avatar,
  Table,
  Badge,
  Menu,
  Skeleton,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconCrown, IconDots, IconMail, IconTrash } from '@tabler/icons-react';
import {
  FrontendOrganisationMember,
  useDeleteOrganisationMember,
  useInviteToOrganisation,
  useOrganisationMembers,
  useOrganisationStore,
  useUpdateOrganisationMember,
} from 'fe/queries';
import { Organisation } from 'shared/db';
import { z } from 'zod';
import { Typography } from 'fe/ui/shared';

interface TeamMembersProps {
  organisation: Organisation;
}

export function TeamMembers({ organisation }: TeamMembersProps) {
  const { data, isLoading } = useOrganisationMembers(organisation.id);
  const { mutateAsync: inviteMember, isPending: isLoadingInvite } =
    useInviteToOrganisation();
  const { mutateAsync: removeMember, isPending: isLoadingRemove } =
    useDeleteOrganisationMember();
  const { mutateAsync: updateMember } = useUpdateOrganisationMember();
  const { updateOrganisation, isUpdating: isLoadingUpdate } =
    useOrganisationStore();

  const theme = useMantineTheme();

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: zodResolver(
      z.object({
        email: z.string().email().min(1),
      }),
    ),
  });

  const onSubmit = form.onSubmit(async (values) => {
    await inviteMember({
      email: values.email,
      organization_id: organisation.id,
    });
    notifications.show({
      title: 'Member invited',
      message: `Member with email: ${values.email} was invited. Ask them to log in with their email to access the organisation campaigns.`,
    });
    form.reset();
  });

  const remove = async (member: FrontendOrganisationMember) => {
    await removeMember(member);
    notifications.show({
      title: 'Member removed from organisation',
      message: `They won't have access to the organisation campaigns anymore.`,
    });
  };
  const isOwner = (member: FrontendOrganisationMember) =>
    member.user_id === organisation.owned_by;

  const rows = (data || [])
    .sort((a) => (isOwner(a) ? -1 : 1))
    .map((member) => (
      <tr key={member.email}>
        <td>
          <Flex align="center" gap="xs">
            <Avatar radius="md" />
            <Text size="lg">{member.email}</Text>
          </Flex>
        </td>
        <td>
          {isOwner(member) ? (
            <Badge variant="filled">Owner</Badge>
          ) : (
            <Badge variant="outline">Member</Badge>
          )}
        </td>
        <td>
          <Switch
            defaultChecked={member.notifications_enabled}
            onChange={(event) =>
              updateMember({
                ...member,
                notifications_enabled: event.currentTarget.checked,
              })
            }
            size="md"
          />
        </td>
        <td>
          {!isOwner(member) && (
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <ActionIcon>
                  <IconDots />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Actions</Menu.Label>
                <Menu.Item
                  onClick={async () => {
                    await updateOrganisation({
                      ...organisation,
                      owned_by: member.user_id,
                    });
                    notifications.show({
                      title: 'Owner changed',
                      message: `The owner of the organisation has been changed to ${member.email}`,
                    });
                  }}
                  icon={<IconCrown size={14} />}
                >
                  Make owner
                </Menu.Item>
                <Menu.Item
                  color="red"
                  icon={<IconTrash size={14} />}
                  onClick={() => remove(member)}
                >
                  Remove member
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </td>
      </tr>
    ));

  return (
    <Box>
      <Flex align="center" justify="space-between" my="xl">
        <Flex direction="column" style={{ flex: 1 }}>
          <Typography variant="label">Team members</Typography>
          <Typography>
            Manage your team members and their account permissions.
          </Typography>
        </Flex>
        <form onSubmit={onSubmit}>
          <Flex align="flex-end" justify="flex-end">
            <TextInput
              size="sm"
              w={300}
              placeholder="Invite new team member"
              rightSectionWidth={36}
              rightSection={
                <ActionIcon
                  type="submit"
                  color={theme.primaryColor}
                  variant="filled"
                  loading={isLoadingInvite}
                >
                  <IconMail
                    style={{ width: rem(18), height: rem(18) }}
                    stroke={1.5}
                  />
                </ActionIcon>
              }
              {...form.getInputProps('email')}
            />
          </Flex>
        </form>
      </Flex>

      <Table
        mb="xl"
        variant="outline"
        fontSize="md"
        verticalSpacing="md"
        withBorder
      >
        <thead>
          <tr>
            <th style={{ width: '400px' }}>Email</th>
            <th style={{ width: '200px' }}>Role</th>
            <th>Notifications</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td>
                <Skeleton height={20} />
              </td>
              <td>
                <Skeleton height={20} />
              </td>
              <td>
                <Skeleton height={20} />
              </td>
              <td>
                <Skeleton height={20} />
              </td>
            </tr>
          ) : (
            rows
          )}
        </tbody>
      </Table>
    </Box>
  );
}
