import { ApiEndpoint } from './types';
import { z } from 'zod';

const generateRecommendationsRouteSchema = z.object({
  id: z.number(),
});

export const generateCampaignRecommendationsEndpoint: ApiEndpoint<
  typeof generateRecommendationsRouteSchema
> = {
  apiUrl: '/campaigns/:id/recommendations',
  clientUrl: ({ id }) => `/campaigns/${id}/recommendations`,
  method: 'POST',
};
