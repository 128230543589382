import { ActionIcon, Box, Flex, Paper, } from "@mantine/core";
import { useDeleteRepairLevel, useOrganisationStore, useRepairLevels } from "fe/queries";
import { RepairLevel } from "shared/db";
import { Typography } from "fe/ui/shared";
import { IconPencil, IconPlus, IconX } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { RepairLevelForm } from "./RepairLevelForm";

export function RepairLevelSettings() {
    const { organisation } = useOrganisationStore()
    if (!organisation) return null

    const { data: repairLevels } = useRepairLevels(organisation.id)
    const { mutateAsync: deleteRepairLevel, isPending: isDeleting } = useDeleteRepairLevel(organisation.id)

    const open = (repairLevel?: RepairLevel) => {
        modals.open({
            title: repairLevel ? 'Update repair level' : 'Create repair level',
            children: (
                <RepairLevelForm onSuccess={() => modals.closeAll()} repairLevel={repairLevel} />
            ),
        });
    }

    return (
        <Flex display="flex" h="100%" py="md">
            <Box px="xl" maw={800} w="100%">
                <Flex align="center" justify="space-between">
                    <Typography variant="label">Repair Levels</Typography>
                    <ActionIcon variant="filled" color="brand" onClick={() => open()}>
                        <IconPlus size="1.125rem" />
                    </ActionIcon>
                </Flex>

                <Flex mt="xl" direction="column" gap="md">
                    {repairLevels?.map(level => (
                        <Paper p="xs" w="100%" radius="md">
                            <Flex justify="space-between" w="100%" align="center">
                                <Flex direction="column">
                                    <Typography fz="xs">Level Name</Typography>
                                    <Typography>{level.name}</Typography>
                                </Flex>

                                <Flex gap="xs">
                                    <ActionIcon variant="outline" color="brand" onClick={() => open(level)}>
                                        <IconPencil size="1.125rem" />
                                    </ActionIcon>
                                    <ActionIcon
                                        variant="light"
                                        color="gray"
                                        loading={isDeleting}
                                        onClick={() => deleteRepairLevel(level.id)}
                                    >
                                        <IconX size="1.125rem" />
                                    </ActionIcon>
                                </Flex>
                            </Flex>
                        </Paper>
                    ))}
                </Flex>
            </Box>
        </Flex>
    )
}