import { PricingStrategy, Property } from 'shared/db';

export function generateOfferPrice(
  price: number,
  discountPercentage: number,
): number {
  return price * (discountPercentage / 100);
}
export function getDiscountedPrice(price: number, discountPercentage: number) {
  return price * (discountPercentage / 100);
}

interface CalculatorParams {
  arv: number;
  repairCost: number;
  strategy: PricingStrategy;
}

export const offerPriceStrategyCalculator = ({
  arv,
  repairCost,
  strategy,
}: CalculatorParams) => {
  const discountedArv = arv * (strategy.arv_pct_discount / 100);
  const repairCostDiscount = strategy.include_repair_costs ? repairCost : 0;
  const flatDiscount = strategy.flat_discount;
  return discountedArv - repairCostDiscount - flatDiscount;
};

export const calculateRepairBudget = (
  property: Property,
  pricePerSqft: number,
) => (property.living_area_sqft ? property.living_area_sqft * pricePerSqft : 0);

export const calculateArv = (property: Property, comps: Property[]) => {
  if (!property.living_area_sqft || !comps.length) {
    return null;
  }

  const highestComp = comps.reduce(
    (acc, comp) => (acc.price > comp.price ? acc : comp),
    comps[0],
  );

  if (!highestComp.living_area_sqft) {
    return null;
  }

  const price = highestComp.price;
  const compPricePerSqft = highestComp.living_area_sqft
    ? price / highestComp.living_area_sqft
    : price / property.living_area_sqft;
  return property.living_area_sqft * compPricePerSqft;
};

export function percentage(partialValue: number, totalValue: number) {
  return ((100 * partialValue) / totalValue).toFixed(2);
}

export const percentageDifference = (a: number, b: number) =>
  Math.abs(((a - b) / ((a + b) / 2)) * 100).toFixed(2);

export const roundUp = (num: number) => Math.ceil(num);
