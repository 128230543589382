import posthog from 'posthog-js';
import { ANALYTIC_EVENTS } from 'shared/types';
interface TrackLeadStatusChangeParams {
  old_status: string | null;
  new_status: string;
  street_address: string;
  zip_code: number;
}

export const trackLeadStatusChange = (params: TrackLeadStatusChangeParams) => {
  return posthog.capture(ANALYTIC_EVENTS.LeadStatusChange, params);
};
