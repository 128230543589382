import { ApiEndpoint } from './types';
import { z } from 'zod';

const deleteContractRouteSchema = z.object({
  contract_id: z.number(),
});

export const deleteContractEndpoint: ApiEndpoint<
  typeof deleteContractRouteSchema
> = {
  apiUrl: '/contracts/:contract_id',
  clientUrl: ({ contract_id }) => `/contracts/${contract_id}`,
  method: 'DELETE',
};

const fetchExampleContractRouteSchema = z.object({
  contract_id: z.number(),
});

export const fetchExampleContractEndpoint: ApiEndpoint<
  typeof fetchExampleContractRouteSchema
> = {
  apiUrl: '/contracts/:contract_id',
  clientUrl: ({ contract_id }) => `/contracts/${contract_id}`,
  method: 'GET',
};
