import { Flex, Title } from '@mantine/core';
import { OutreachInsightsCards } from './OutreachInsightsCards';
import { useInsightsStore, useOrganisationStore, useOutreachInsights } from 'fe/queries';

export function OutreachInsights() {
    const { organisation } = useOrganisationStore();
    if (!organisation) {
        return null
    }
    const params = useInsightsStore((state) => ({
        campaignId: state.campaignId,
        dateRange: state.dateRange,
        granularity: state.granularity,
    }))
    const { data, isLoading } = useOutreachInsights(organisation.id, params)
    const quickData = data?.quickData || []
    return (
        <Flex h={200}>
            <Flex style={{ flex: 1 }} gap="lg" direction="column">
                <Title order={2}>Outreach Insights</Title>

                <OutreachInsightsCards data={quickData} loading={isLoading} />
            </Flex>
        </Flex>
    );
}
