import React, { useEffect } from 'react';
import {
  Button,
  Divider,
  Flex,
  ScrollArea,
  Image,
  Title,
  Container,
  Loader,
  Skeleton,
  Text,
  Box,
} from '@mantine/core';
import {
  useInboxStore,
  useLeadInbox,
  useMarkAllMessagesAsSeen,
  useOrganisationStore,
  useSendEmail,
} from 'fe/queries';
import { ConversationDetailSidenav } from '../ConversationDetailSidenav';
import { ConvoItem } from './ConvoItem/ConvoItem';
import { scrollToBottom, sortMessages } from 'shared/formatter';
import { notifications } from '@mantine/notifications';
import { InboxIllustration, NotFound, StyledEditor } from 'fe/ui/shared';
import { InboxItem, Products } from 'shared/db';
import { useDisclosure } from '@mantine/hooks';
import { ConversationActions } from './ConversationActions';
import { ConvoSuggestions } from './ConvoSuggestions/ConvoSuggestions';

interface ConversationDetailProps {
  items: InboxItem[];
  isLoading: boolean;
}

export function ConversationDetail({
  items,
  isLoading,
}: ConversationDetailProps) {
  const { organisation } = useOrganisationStore();
  const { activeItem, markAllMessagesAsSeen } = useInboxStore((state) => ({
    activeItem: state.activeInboxItem,
    markAllMessagesAsSeen: state.markAllMessagesAsSeen,
  }));
  const {
    filterCampaignType,
    filterCampaigns,
    filterLeadStatus,
    filterSearchTerm,
  } = useInboxStore((state) => ({
    filterLeadStatus: state.filterLeadStatus,
    filterCampaigns: state.filterCampaigns,
    filterCampaignType: state.filterCampaignType,
    filterSearchTerm: state.filterSearchTerm,
  }));

  const { mutateAsync: markAsSeen } = useMarkAllMessagesAsSeen(
    activeItem?.status_id || 0,
    organisation?.id || 0,
    filterSearchTerm,
    filterLeadStatus,
    filterCampaigns,
    filterCampaignType as Products,
  );

  const { mutateAsync: sendEmail, isPending } = useSendEmail(
    organisation?.id || 0,
  );
  const { data: inbox, isLoading: isLoadingInbox } = useLeadInbox(
    activeItem?.status_id,
  );
  const [displayReply, { toggle: toggleReply, close }] = useDisclosure(false);
  const messages = inbox?.messages || [];

  useEffect(() => {
    close();
  }, [activeItem?.status_id]);

  useEffect(() => {
    const numUnseen = inbox?.messages.filter((message) => !message.seen).length;
    if (numUnseen) {
      markAllMessagesAsSeen();
      markAsSeen(messages);
    }
    setTimeout(() => scrollToBottom('chat-scroll'), 100);
  }, [inbox, displayReply, messages]);

  if (isLoading) {
    return (
      <Flex
        style={{ flex: 1 }}
        align="center"
        justify="center"
        direction="column"
        gap="md"
      >
        <Loader color="gray" />
      </Flex>
    );
  }

  if (!isLoading && items.length === 0) {
    return (
      <Flex
        style={{ flex: 1 }}
        align="center"
        justify="center"
        direction="column"
        gap="xl"
      >
        <Image src={NotFound} maw={400} w="80%" />

        <Container size="xs">
          <Title order={2} align="center">
            No conversations found. Send some offers to see items here.
          </Title>
        </Container>
      </Flex>
    );
  }

  if (!activeItem || !organisation)
    return (
      <Flex
        style={{ flex: 1 }}
        align="center"
        justify="center"
        direction="column"
        gap="xl"
      >
        <Image src={InboxIllustration} maw={400} w="80%" />

        <Container size="xs">
          <Title order={3} align="center">
            Click on a message to view the full conversation
          </Title>
        </Container>
      </Flex>
    );

  const submitEmail = async (content: string) => {
    if (!content) {
      notifications.show({
        title: 'Email failed to send',
        message: "Can't send an empty email. Please fill your email.",
        color: 'red',
      });
      return;
    }
    await sendEmail({
      content,
      street_address: activeItem.street_address,
      zip_code: activeItem.zip_code,
      organisation: organisation,
    });
    notifications.show({
      title: 'Email sent',
      message: null,
    });
    scrollToBottom('chat-scroll');
    toggleReply();
  };

  const notes = inbox?.notes || [];
  const statusChanges = inbox?.statusChanges || [];
  const suggestedActions = inbox?.suggestedActions || [];

  return (
    <Flex style={{ flex: 1 }}>
      <Flex style={{ flex: 1 }} direction="column">
        <Flex align="center" justify="space-between" py="xl" px="xl">
          <Text fw="bold">{activeItem.street_address}</Text>

          <ConversationActions item={activeItem} toggleReply={toggleReply} />
        </Flex>

        <div style={{ flex: 1, position: 'relative' }}>
          <ScrollArea
            id="chat-scroll"
            style={{ position: 'absolute', height: '100%', width: '100%' }}
            p="xl"
            pt="0"
          >
            <Flex direction="column" style={{ flex: 1 }} gap="xl">
              {isLoadingInbox ? (
                <>
                  <Skeleton key="loader-1" height={200} mb="xs" />
                  <Skeleton key="loader-2" height={200} mb="xs" />
                </>
              ) : (
                sortMessages([...messages, ...notes, ...statusChanges]).map(
                  (message, index) => {
                    let key = '';
                    switch (true) {
                      case 'outbound' in message:
                        key = `message-${message.id}`;
                        break;
                      case 'content' in message:
                        key = `note-${message.id}`;
                        break;
                      default:
                        key = `statusChange-${message.id}`;
                        break;
                    }
                    return (
                      <React.Fragment key={key}>
                        {index ? <Divider key={`${key}-divider`} /> : null}

                        <ConvoItem key={`${key}-content`} item={message} />
                      </React.Fragment>
                    );
                  },
                )
              )}

              <ConvoSuggestions
                key="content-suggestions"
                suggestions={suggestedActions}
              />

              {!displayReply && (
                <Box my="xl" key="email-actions">
                  <ConversationActions
                    item={activeItem}
                    toggleReply={toggleReply}
                  />
                </Box>
              )}
            </Flex>
          </ScrollArea>
        </div>

        {displayReply && (
          <Flex direction="column" align="flex-end" m="lg" h={360}>
            <div style={{ width: '100%', height: 300 }}>
              <Flex direction="column" gap="xs">
                <StyledEditor
                  buttons={({ content }) => (
                    <Flex justify="flex-start" w="100%" gap="xs">
                      <Button
                        onClick={() => submitEmail(content)}
                        loading={isPending}
                        mt="md"
                        size="md"
                      >
                        Send Email
                      </Button>

                      <Button
                        onClick={toggleReply}
                        loading={isPending}
                        mt="md"
                        variant="default"
                        size="md"
                      >
                        Cancel
                      </Button>
                    </Flex>
                  )}
                />
              </Flex>
            </div>
          </Flex>
        )}
      </Flex>

      <Divider orientation="vertical" />

      <div>
        <ConversationDetailSidenav />
      </div>
    </Flex>
  );
}
