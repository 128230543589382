import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CampaignOfferRecommendation,
  CampaignOfferRecommendationWithProperty,
  updateCampaignOfferRecommendation,
} from 'shared/db';
import { callApi, supabase } from './../utils';
import {
  generateCampaignRecommendationsEndpoint,
  getOfferQueueEndpoint,
} from 'shared/api';

export const useRecommendations = (campaignId: number) =>
  useQuery({
    queryKey: ['recommendations', campaignId],
    queryFn: () =>
      callApi(getOfferQueueEndpoint, { campaign_id: campaignId }).then(
        (res) => res.data || [],
      ),
  });

interface RemoveParams {
  street_address: string;
  zip_code: number;
}

export const useRemoveOfferRecommendation = (campaign_id: number) => {
  const queryClient = useQueryClient();
  return ({ street_address, zip_code }: RemoveParams) => {
    queryClient.setQueriesData<CampaignOfferRecommendationWithProperty[]>(
      { queryKey: ['recommendations', campaign_id] },
      (previous) =>
        (previous || []).filter(
          (recommendation) =>
            recommendation.street_address !== street_address &&
            recommendation.zip_code !== zip_code,
        ),
    );
  };
};

export const useGenerateCampaignRecommendations = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (campaign_id: number) =>
      callApi(generateCampaignRecommendationsEndpoint, { id: campaign_id }),
    onSuccess: (_, campaign_id) => {
      queryClient.invalidateQueries({
        queryKey: ['recommendations', campaign_id],
      });
    },
  });
};

export const useUpdateCampaignOfferRecommendation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (recommendation: CampaignOfferRecommendation) =>
      updateCampaignOfferRecommendation(supabase, recommendation),
    onSuccess: (_, campaign_id) => {
      queryClient.invalidateQueries({
        queryKey: ['recommendations', campaign_id],
      });
    },
  });
};
