import { Avatar, Flex, Text } from '@mantine/core';
import React from 'react';
import classes from './ConversationMessage.module.scss';
import { extractMessageContent } from 'shared/formatter';
import { PropertyMessageWithAttachments } from 'be/db';
import { AttachmentCard } from './../AttachmentCard';
import { IconStar } from '@tabler/icons-react';
import { useInboxStore } from 'fe/queries';

interface ConversationMessageProps {
  message: PropertyMessageWithAttachments;
}

export function ConvoItemMessage({ message }: ConversationMessageProps) {
  const isMessage = true;
  const { activeInboxItem } = useInboxStore((state) => ({
    activeInboxItem: state.activeInboxItem,
  }));

  let extraClass: string;
  switch (true) {
    case isMessage && message.outbound:
      extraClass = classes.outbound;
      break;
    default:
      extraClass = classes.inbound;
  }
  const regex =
    /On\s+(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s+\d{1,2},\s+\d{4},?\s+at\s+\d{1,2}:\d{2}\s+(AM|PM),?\s+.*?wrote:.*/m;
  const removedReply = message.content
    .replace(/(\r\n|\n|\r)/gm, '')
    .replace(regex, '');

  return (
    <Flex
      className={`${classes.bubble} ${extraClass}`}
      style={{ flex: 1 }}
      direction="column"
      gap="xs"
      w="100%"
      bg={undefined}
    >
      <Flex align="center" gap="sm">
        {message.outbound ? (
          <Avatar color="blue" radius="sm">
            <IconStar />
          </Avatar>
        ) : (
          <Avatar color="blue" radius="sm" src={activeInboxItem?.img_src} />
        )}
        <Flex direction="column" justify="center">
          <Flex gap={6}>
            <Text fz="sm" component="a" fw="bold">
              {message.outbound ? 'You' : message.from_name}
            </Text>
            {message.from_email && (
              <Text fz="sm" component="a">
                {message.from_email}
              </Text>
            )}
          </Flex>
          <Text fz="sm" c="dimmed">
            {message.created_at
              ? new Date(message.created_at!).toLocaleString()
              : 'New email'}
          </Text>
        </Flex>
      </Flex>

      <div
        dangerouslySetInnerHTML={{
          __html: extractMessageContent(removedReply.trim()),
        }}
      />

      {message.attachments && (
        <Flex gap="sm" wrap="wrap">
          {message.attachments?.map((attachment) => (
            <AttachmentCard attachment={attachment} key={attachment.id} />
          ))}
        </Flex>
      )}
    </Flex>
  );
}
