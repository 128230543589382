import { Box, Select } from "@mantine/core";
import { UseFormReturnType, } from "@mantine/form";
import { Organisation } from "shared/db";
import { InputWrapper } from '../InputWrapper/InputWrapper';
import { CampaignFormValues } from "./useCampaignForm";
import { useOrganisationContracts, useOrganisationWorkflows } from "fe/queries";

interface SmartCampaignFormExtraProps {
    form: UseFormReturnType<CampaignFormValues>;
    organisation: Organisation;
}

export function SmartCampaignFormExtra({
    form,
    organisation
}: SmartCampaignFormExtraProps) {
    const { data: workflows } = useOrganisationWorkflows(organisation.id);
    const { data: contracts } = useOrganisationContracts(organisation.id);
    return (
        <Box>
            <InputWrapper
                title="Workflow"
                description="Select the workflow for this campaign."
                rightWidth="50%"
            >
                <Select
                    data={workflows?.map(workflow => ({
                        value: String(workflow.id),
                        label: workflow.name
                    })) || []}
                    mb="sm"
                    {...form.getInputProps('workflow_id')}
                />
            </InputWrapper>

            <InputWrapper
                title="Contract"
                description="Select the contract for this campaign."
                rightWidth="50%"
            >
                <Select
                    data={contracts?.map(contract => ({
                        value: String(contract.id),
                        label: contract.label
                    })) || []}
                    mb="sm"
                    {...form.getInputProps('contract_id')}
                />
            </InputWrapper>
        </Box>
    )
}