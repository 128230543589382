import { ApiEndpoint, ResponseObject } from './types';
import { z } from 'zod';
import { FullProperty } from 'be/db';

export type GetPropertyEndpointResponse = ResponseObject<FullProperty>;

const getPropertyRouteSchema = z.object({
  street_address: z.string(),
  zip_code: z.number(),
});

export const getPropertyEndpoint: ApiEndpoint<typeof getPropertyRouteSchema> = {
  apiUrl: '/properties/:street_address/:zip_code',
  clientUrl: ({ street_address, zip_code }) =>
    `/properties/${street_address}/${zip_code}`,
  method: 'GET',
};
