import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { callApi, supabase } from './../utils';
import { deleteOrganisationMember, updateOrganisationMember } from 'shared/db';
import { fetchMembersEndpoint, inviteMemberEndpoint } from 'shared/api';

export interface FrontendOrganisationMember {
  id: number;
  name: string;
  user_id: string;
  email: string;
  type: 'Owner' | 'Member';
  organisation_id: number;
  notifications_enabled?: boolean;
}

export const useDeleteOrganisationMember = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (member: FrontendOrganisationMember) =>
      deleteOrganisationMember(supabase, member.id),
    onSuccess: (memberId) => {
      queryClient.setQueriesData<FrontendOrganisationMember[]>(
        { queryKey: ['organisation_members'] },
        (previous) => previous?.filter((member) => member.id !== memberId),
      );
    },
  });
};

interface InviteMemberParams {
  email: string;
  organization_id: number;
}

export const useInviteToOrganisation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ email, organization_id }: InviteMemberParams) =>
      callApi(inviteMemberEndpoint, { organization_id }, { email }).then(
        (res) => res.data,
      ),
    onSuccess: (data, params) => {
      queryClient.setQueriesData<FrontendOrganisationMember[]>(
        { queryKey: ['organisation_members'] },
        (previous) => [
          ...(previous || []),
          {
            ...data,
            email: params.email,
          } as any,
        ],
      );
    },
  });
};

export const useOrganisationMembers = (organisationId: number) =>
  useQuery<FrontendOrganisationMember[]>({
    queryKey: ['organisation_members', organisationId],
    queryFn: () =>
      callApi(fetchMembersEndpoint, { organization_id: organisationId }).then(
        (res) => res.data,
      ),
  });

export const useUpdateOrganisationMember = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (member: FrontendOrganisationMember) =>
      updateOrganisationMember(supabase, {
        id: member.id,
        user_id: member.user_id,
        organisation_id: member.organisation_id,
        notifications_enabled:
          member.notifications_enabled !== undefined
            ? member.notifications_enabled
            : true,
      }),
    onSuccess: (data, params) => {
      queryClient.setQueriesData<FrontendOrganisationMember[]>(
        { queryKey: ['organisation_members'] },
        (previous) =>
          previous?.map((member) =>
            member.id !== params.id ? member : params,
          ),
      );
    },
  });
};
