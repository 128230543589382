import { Avatar, Text, Flex } from "@mantine/core";
import { forwardRef } from "react";

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: string;
    label: string;
    description: string;
    totalzipcodes: number;
    selectedzipcodes: number;
}
export const LocationSelectorItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, description, totalzipcodes, selectedzipcodes, ...others }: ItemProps, ref) => (
        <div ref={ref} {...others}>
            <Flex justify="space-between" direction="row" align="center">
                <Flex align="center" gap="sm">
                    <Avatar src={image} />
                    <Text>{label}</Text>
                    <Text size="xs" color="dimmed">
                        {description}
                    </Text>
                </Flex>

                {totalzipcodes && (
                    <Text size="xs" color="dimmed">
                        Zipcodes: {selectedzipcodes} / {totalzipcodes}
                    </Text>
                )}
            </Flex>
        </div>
    )
);