import { SupabaseClient } from '@supabase/supabase-js';
import { Property } from '../property/properties.types';

export type CampaignListing = Property & {
  city: string;
  state: string;
};

export async function getCampaignProperties(
  supabase: SupabaseClient,
  campaign_id: number,
): Promise<CampaignListing[]> {
  const { data, error } = await supabase.rpc('get_campaign_properties', {
    campaign_id,
  });

  if (error) {
    console.error('Error fetching campaign properties:', error);
    return [];
  }

  return data;
}
