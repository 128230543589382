import { SupabaseClient } from '@supabase/supabase-js';

export interface RepairLevel {
  id: number;
  organisation_id: number;
  name: string;
  price_per_sqft: number;
  description: string;
}

export type NewRepairLevel = Omit<RepairLevel, 'id'>;

export async function createRepairLevels(
  supabase: SupabaseClient,
  repairLevel: Omit<RepairLevel, 'id'>,
): Promise<RepairLevel> {
  const { data, error } = await supabase
    .from('organisation_repair_levels')
    .insert(repairLevel)
    .select()
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't create repair level");
  }

  return data;
}

export async function updateRepairLevels(
  supabase: SupabaseClient,
  repairLevel: RepairLevel,
) {
  const { error } = await supabase
    .from('organisation_repair_levels')
    .update(repairLevel)
    .eq('id', repairLevel.id);

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't update repair level");
  }

  return repairLevel;
}

export async function fetchRepairLevels(
  supabase: SupabaseClient,
  organisation_id: number,
): Promise<RepairLevel[]> {
  const { data, error } = await supabase
    .from('organisation_repair_levels')
    .select()
    .eq('organisation_id', organisation_id);

  if (error) {
    console.log('error fetching repair levels: ', error);
    return [];
  }

  return data;
}

export async function deleteRepairLevels(
  supabase: SupabaseClient,
  contract_id: number,
) {
  const { error } = await supabase
    .from('organisation_repair_levels')
    .delete()
    .eq('id', contract_id);

  if (error) {
    console.log('error deleting repair levels: ', error);
  }
}
