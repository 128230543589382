import { ApiEndpoint } from '../types';
import { z } from 'zod';

const generateCampaignLeadsRouteSchema = z.object({
  campaign_id: z.number(),
});

export const generateCampaignLeadsEndpoint: ApiEndpoint<
  typeof generateCampaignLeadsRouteSchema
> = {
  apiUrl: '/campaigns/:campaign_id/generateLeads',
  clientUrl: ({ campaign_id }) => `/campaigns/${campaign_id}/generateLeads`,
  method: 'POST',
};
