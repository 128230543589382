import { PropertySearchSoldInLast } from 'shared/db';

export function mapStringToBoolean(value?: string) {
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  }
  return null;
}
export function mapBooleanToString(value?: boolean | null) {
  if (value === true) {
    return 'true';
  } else if (value === false) {
    return 'false';
  }
  return '';
}

export const transformSoldInLastToNumber = (
  soldInLast: PropertySearchSoldInLast,
) => {
  switch (soldInLast) {
    case PropertySearchSoldInLast.OneDay:
      return 1;
    case PropertySearchSoldInLast.OneWeek:
      return 7;
    case PropertySearchSoldInLast.TwoWeeks:
      return 14;
    case PropertySearchSoldInLast.OneMonth:
      return 30;
    case PropertySearchSoldInLast.ThreeMonths:
      return 90;
    case PropertySearchSoldInLast.SixMonths:
      return 180;
    case PropertySearchSoldInLast.OneYear:
      return 365;
    case PropertySearchSoldInLast.TwoYears:
      return 730;
    case PropertySearchSoldInLast.ThreeYears:
      return 1095;
  }
};
