import {
  archiveCampaign,
  Campaign,
  createCampaign,
  fetchActiveCampaigns,
  fetchCampaignWithLocation,
  fetchSingleCampaign,
  updateCampaign,
  updateCampaignPartially,
} from 'shared/db';
import { callApi, supabase } from '../utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { generateCampaignLeadsEndpoint } from 'shared/api';

const campaignKeys = {
  single: (id: number) => ['campaign', id] as const,
};

export const useCampaigns = (organisation_id: number) =>
  useQuery({
    queryKey: ['campaigns', organisation_id],
    queryFn: () => fetchActiveCampaigns(supabase, organisation_id),
  });

export const useGenerateCampaignProperties = () =>
  useMutation({
    mutationFn: (campaign_id: number) =>
      callApi(generateCampaignLeadsEndpoint, {
        campaign_id,
      }),
  });

export const useUpdateCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updatedCampaign: Partial<Campaign> & { id: number }) => {
      delete (updatedCampaign as any).usa_zip_codes;
      return updateCampaignPartially(
        supabase,
        updatedCampaign.id,
        updatedCampaign,
      );
    },
    onSuccess: async (data) => {
      const updatedCampaign = await fetchCampaignWithLocation(
        supabase,
        Number(data.id),
      );
      queryClient.setQueriesData<Campaign[]>(
        { queryKey: ['campaigns', data.organisation_id] },
        (previous) =>
          previous?.map((prevCampaign) =>
            prevCampaign.id! === updatedCampaign.id!
              ? updatedCampaign
              : prevCampaign,
          ) || [],
      );

      queryClient.setQueriesData<Campaign>(
        { queryKey: campaignKeys.single(data.id) },
        () => data,
      );
    },
  });
};

export const useCreateCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (newCampaign: Omit<Campaign, 'id'>) =>
      createCampaign(supabase, newCampaign),
    onSuccess: (data) => {
      queryClient.setQueriesData<Campaign[]>(
        { queryKey: ['campaigns', data.organisation_id] },
        (previous) => [...(previous || []), data],
      );
    },
  });
};

export const useArchiveCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (campaign: Campaign) => archiveCampaign(supabase, campaign),
    onSuccess: (data) => {
      queryClient.setQueriesData<Campaign[]>(
        { queryKey: ['campaigns', data.organisation_id] },
        (previous) => [
          ...(previous || []).filter(
            (prevCampaign) => prevCampaign.id !== data.id,
          ),
        ],
      );
    },
  });
};

export const useCampaign = (id: number | string) =>
  useQuery({
    queryKey: campaignKeys.single(Number(id)),
    queryFn: () => fetchSingleCampaign(supabase, Number(id)),
  });

// Create todo
export async function uploadCampaignContract(
  attachment: File,
  campaign: Campaign,
) {
  const contractFilename = `private/${campaign.organisation_id}/${attachment.name}`;
  const { error } = await supabase.storage
    .from('contracts')
    .upload(contractFilename, attachment, {
      upsert: true,
    });

  if (error) {
    console.log('error', error);
    throw new Error("Couldn't upload contract");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { usa_zip_codes, ...baseCampaign } = campaign as any;

  await updateCampaign(supabase, {
    ...baseCampaign,
    contract_filename: contractFilename,
  });

  return { campaign, filename: contractFilename };
}

export const useCampaignContractUpload = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      contractFile,
      campaign,
    }: {
      contractFile: File;
      campaign: Campaign;
    }) => {
      return uploadCampaignContract(contractFile, campaign);
    },
    onSuccess: ({ campaign, filename }) => {
      // queryClient.setQueryData(['email_templates', { id: data.id }], data)
      queryClient.setQueriesData<Campaign[]>(
        { queryKey: ['campaigns'] },
        (previous) => {
          return [
            ...(previous?.map((prevCampagin) => {
              return {
                ...prevCampagin,
                contract_filename:
                  campaign.id === prevCampagin.id
                    ? filename
                    : prevCampagin.contract_filename,
              };
            }) || []),
          ];
        },
      );
    },
  });
};
