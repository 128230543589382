import { Container, Flex, Title, Image, Text, Avatar, Box, TextInput, Button, Checkbox, Anchor, } from '@mantine/core'
import { BillBeck, LogoLargeDark, Typography } from 'fe/ui/shared'
import { Layout } from 'fe/feature/layout'
import React, { useState } from 'react'
import { useLogin, } from 'fe/queries'
import classes from './LoginPage.module.scss'
import { isNotEmpty, useForm } from '@mantine/form'
import { CheckYourEmail } from './CheckYourEmail'


export function LoginPage() {
    const { mutateAsync: login, isPending } = useLogin()

    const [displayCheckYourEmail, setDisplayCheckYourEmail] = useState(false)

    const form = useForm({
        initialValues: {
            email: '',
            consent: false,
        },
        validate: {
            email: isNotEmpty(),
            consent: (value) => value ? null : 'You must agree to the privacy policy, terms of service and service agreement.',
        }
    });
    const onFormSubmit = form.onSubmit(async (values) => {
        await login(values.email)
        setDisplayCheckYourEmail(true)
    })

    return (
        <Layout noMenu noScrolling noPadding>
            <Flex w="100%">
                <Flex bg="#FAFAFA" w="30%" direction="column" justify="space-between" p="xl" align="center">
                    <Flex my="xl" justify="center" w="100%" h={42}>
                        <Image
                            radius="sm"
                            src={LogoLargeDark}
                            width={116}
                            height={42}
                        />
                    </Flex>

                    <Flex direction="column-reverse" justify="center" gap="xl" align="center">
                        <Flex direction="column" gap="xl">
                            <Typography c="dimmed" align="center" fz="xl">
                                "We have completely automated our offer process with Hey Rafi; all my team does is pick up the phone on in-bound calls."
                            </Typography>

                            <Flex direction="column" align="center">
                                <Avatar radius="50%" src={BillBeck} alt="it's me" />
                                <Typography fz="sm" fw="bold">Bill Beck</Typography>
                                <Typography fz="sm" c="dimmed">CEO, Rise Home Buyers</Typography>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Box h={42}>
                        <Text c="dimmed" align='center'>Used by the most progressive real estate investors.</Text>
                    </Box>
                </Flex>

                <Flex bg="white" align="center" style={{ flex: 1 }}>
                    {displayCheckYourEmail ? <CheckYourEmail /> : (
                        <Container size={600} >
                            <Flex mb={40} direction="column">
                                <Typography align="left" c="dimmed" variant="label" fz="xl">SIGN IN</Typography>
                                <Title align="left" order={1}>Welcome To Hey Rafi</Title>

                                <Typography c="dimmed" fz="xl" mt="xs" align='left' w="100%">
                                    Please enter your email to receive an email with a link to log in
                                </Typography>
                            </Flex>

                            <form onSubmit={onFormSubmit}>
                                <Flex direction="column" gap="xl">
                                    <TextInput
                                        placeholder="Your email"
                                        size="lg"
                                        {...form.getInputProps('email')}
                                    />

                                    <Checkbox
                                        classNames={{
                                            body: classes.checkboxBody,
                                        }}
                                        label={<Text c="dimmed">
                                            I have read and agree to the {' '}
                                            <Anchor className={classes.legalLink} href="/privacy-policy" target="_blank" c="dimmed" underline>
                                                Privacy policy
                                            </Anchor>, {' '}
                                            <Anchor className={classes.legalLink} href="/terms-of-service" target="_blank" c="dimmed" underline>
                                                Terms of service
                                            </Anchor>, and {' '}
                                            <Anchor className={classes.legalLink} href="/service-agreement" target="_blank" c="dimmed" underline>
                                                Service agreement
                                            </Anchor>.

                                        </Text>}
                                        size="lg"
                                        {...form.getInputProps('consent')}
                                    />

                                    <Button type='submit' fullWidth size="lg" loading={isPending}>
                                        Submit
                                    </Button>
                                </Flex>
                            </form>
                        </Container>
                    )}
                </Flex>
            </Flex>
        </Layout>
    )
}