import { Box, Flex } from '@mantine/core';
import { differenceInDays } from 'date-fns';
import { MRT_ColumnDef } from 'mantine-react-table';
import React from 'react';
import { useMemo } from 'react';
import { CampaignListing } from 'shared/db';

export function useOffersTableColumns() {
  const columns = useMemo<MRT_ColumnDef<CampaignListing>[]>(
    () => [
      {
        id: 'name', //id is still requigreen when using accessorFn instead of accessorKey
        header: 'Address',
        size: 300,
        accessorKey: 'street_address',
        filterVariant: 'autocomplete',
        Cell: ({ row }) => (
          <Flex gap="sm" align="center">
            {row.original.img_src && row.original.img_src !== '' && (
              <img alt="avatar" height={30} src={row.original.img_src} />
            )}
            <span>{row.original.street_address}</span>
          </Flex>
        ),
      },
      {
        accessorFn: (row) => row && row.condition_analysis,
        header: 'Condition',
        size: 150,
      },
      {
        accessorFn: (row) => row && row.city,
        header: 'City',
        size: 125,
      },
      {
        accessorFn: (row) => row && row.state,
        header: 'State',
        size: 125,
      },
      {
        accessorKey: 'zip_code',
        header: 'Zip Code',
        size: 125,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        filterVariant: 'range-slider',
        size: 150,
        mantineFilterRangeSliderProps: {
          color: 'indigo',
          label: (value) =>
            value?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
        },
        // //custom conditional format and styling
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue<number>()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        ),
      },
      {
        accessorKey: 'zestimate',
        header: 'Zestimate',
        filterVariant: 'range-slider',
        size: 150,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue<number>()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        ),
      },
      {
        accessorKey: 'rent_zestimate',
        header: 'Rent Zestimate',
        filterVariant: 'range-slider',
        size: 150,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue<number>()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        ),
      },
      {
        accessorKey: 'property_type',
        header: 'Property Type',
        size: 200,
      },
      {
        accessorKey: 'lot_area_sqft',
        enableClickToCopy: true,
        header: 'Lot Area',
        size: 200,
      },
      {
        accessorKey: 'living_area_sqft',
        enableClickToCopy: true,
        header: 'Living Area',
        size: 200,
      },
      {
        accessorFn: (originalRow) =>
          originalRow && originalRow.listed_date
            ? String(
                differenceInDays(new Date(), new Date(originalRow.listed_date)),
              )
            : null,
        enableClickToCopy: true,
        header: 'Days on market',
        size: 200,
      },
      {
        accessorKey: 'bathrooms',
        header: 'Bathrooms',
        size: 200,
      },
      {
        accessorKey: 'bedrooms',
        header: 'Bedrooms',
        size: 200,
      },
    ],
    [],
  );
  return columns;
}
