import { SupabaseClient } from '@supabase/supabase-js';
import { PropertyCondition, PropertyType } from '../property/properties.types';

export enum LeaseType {
  LeaseOKWithRestrictions = 'lease_ok_with_restrictions',
  LeaseOKNoRestrictions = 'lease_ok_no_restrictions',
}

export type CampaignFilters = {
  campaign_id: number;
  time_on_market: number | null;
  property_types: PropertyType[];
  min_beds: number | null;
  max_beds: number | null;
  min_baths: number | null;
  max_baths: number | null;
  min_price: number | null;
  max_price: number | null;
  min_sqft: number | null;
  max_sqft: number | null;
  min_year_built: number | null;
  max_year_built: number | null;
  keywords: string[] | null;
  community_55: boolean | null;
  max_hoa: number | null;
  equity_purchase: boolean | null;
  club_membership: boolean | null;
  lease: LeaseType | null;
  min_lot_sqft: number | null;
  max_lot_sqft: number | null;
  property_condition?: PropertyCondition[] | null;
  must_have_garage: boolean;
};

export async function fetchCampaignFilters(
  supabase: SupabaseClient,
  campaignId: number,
): Promise<CampaignFilters | null> {
  const { data, error } = await supabase
    .from('campaign_filters')
    .select('*')
    .eq('campaign_id', campaignId)
    .single();

  if (error) {
    return null;
  }

  return data;
}
