import { useQueries, useQuery } from '@tanstack/react-query';

import { postApi } from '../utils';
import { CampaignFilters } from 'shared/db';
import { useDebouncedValue } from '@mantine/hooks';

interface CalculateListingsResponse {
  numFound: number;
}

const keys = {
  all: ['availableListings'] as const,
  city: (city: string) => [...keys.all, city],
  campaign: (campaignId: number, filters?: CampaignFilters) => [
    ...keys.all,
    campaignId,
    filters,
  ],
};

export const useAvailableListingsByLocations = (addresses: string[]) =>
  useQueries({
    queries: addresses.map((address) => ({
      queryKey: keys.city(address),
      queryFn: () =>
        postApi<CalculateListingsResponse>(
          `/calculateAvailableAddressListings`,
          {
            address,
          },
        ).then((response) => response.numFound),
    })),
    combine: (results) => ({
      data: results.reduce((acc, val) => acc + (val.data || 0), 0),
      isPending: results.some((result) => result.isPending),
    }),
  });

interface UseAvailableListingsByCampaignFilters {
  campaignId: number;
  filters?: CampaignFilters;
}

export const useAvailableListingsByCampaignFilters = ({
  campaignId,
  filters,
}: UseAvailableListingsByCampaignFilters) => {
  const [debounced] = useDebouncedValue(filters, 1000);

  return useQuery({
    queryKey: keys.campaign(campaignId, debounced),
    queryFn: () =>
      filters
        ? postApi<CalculateListingsResponse>(
            `/calculateAvailableCampaignListings`,
            {
              campaignId,
              filters,
            },
          ).then((response) => response.numFound)
        : 0,
  });
};
