import { Button, Flex, NumberInput, TextInput, Textarea } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCreateRepairLevel, useOrganisationStore, useUpdateRepairLevel } from "fe/queries";
import { RepairLevel } from "shared/db";

interface RepairLevelFormProps {
    repairLevel?: RepairLevel;
    onSuccess: () => void;
}

export function RepairLevelForm({
    repairLevel,
    onSuccess
}: RepairLevelFormProps) {
    const { organisation } = useOrganisationStore()

    if (!organisation) return null

    const { mutateAsync: create, isPending: isCreateLoading } = useCreateRepairLevel(organisation.id)
    const { mutateAsync: update, isPending: isUpdateLoading } = useUpdateRepairLevel(organisation.id)

    const form = useForm({
        initialValues: {
            name: repairLevel?.name || '',
            price_per_sqft: repairLevel?.price_per_sqft || 50,
            description: repairLevel?.description || '',
        },
        validate: {
            name: isNotEmpty(),
            price_per_sqft: isNotEmpty(),
            description: isNotEmpty(),
        }
    });

    const onSubmit = form.onSubmit(async (values) => {
        if (repairLevel) {
            await update({
                ...repairLevel,
                ...values
            })
        } else {
            await create({
                organisation_id: organisation.id,
                ...values
            })
        }
        onSuccess()
    })

    return (
        <div>
            <form onSubmit={onSubmit}>
                <TextInput
                    mb="sm"
                    withAsterisk
                    label="Name"
                    placeholder="High"
                    {...form.getInputProps('name')}
                />

                <NumberInput
                    mb="sm"
                    withAsterisk
                    label="Price Per SQFT"
                    {...form.getInputProps('price_per_sqft')}
                />

                <Textarea
                    mb="sm"
                    withAsterisk
                    label="Description"
                    placeholder="Windows, roof, drywall"
                    {...form.getInputProps('description')}
                />

                <Flex w="100%" justify="flex-end">
                    <Button type="submit" mt="md" loading={isCreateLoading || isUpdateLoading}>
                        {repairLevel ? 'Update' : 'Create'}
                    </Button>
                </Flex>
            </form>
        </div>
    )
}