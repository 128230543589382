import { SupabaseClient } from '@supabase/supabase-js';

export interface Location {
  zip: number;
  zip_code: string;
  lat: number;
  lng: number;
  city: string;
  state_id: string;
  state_name: string;
  zcta: boolean;
  parent_zcta: string;
  population: number;
  density: number;
  county_name: string;
}

export async function fetchCampaignLocations(
  supabase: SupabaseClient,
  campaignId: number,
): Promise<Location[]> {
  const { data, error } = await supabase
    .from('zip_codes')
    .select('*, campaign_locations!inner(*)')
    .eq('campaign_locations.campaign_id', campaignId);

  if (error) {
    return [];
  }

  return data;
}

export async function fetchZipCodesByCounty(
  supabase: SupabaseClient,
  countyName: string,
): Promise<Location[]> {
  const { data, error } = await supabase
    .from('zip_codes')
    .select('*')
    .eq('county_name', countyName);

  if (error) {
    throw new Error("Couldn't fetch locations");
  }

  return data;
}

export async function updateOrganisationLocations(
  supabase: SupabaseClient,
  organisationId: number,
  locations: number[],
  currentLocations: number[],
): Promise<boolean[]> {
  const toDelete = currentLocations.filter(
    (location) => !locations.includes(location),
  );
  if (toDelete && toDelete.length > 0) {
    await supabase
      .from('organisation_locations')
      .delete()
      .in('zip_code', toDelete);
  }

  if (locations.length === 0) {
    return [];
  }

  const mappedLocations = locations.map((location) => ({
    organisation_id: organisationId,
    zip_code: location,
  }));

  const { data, error } = await supabase
    .from('organisation_locations')
    .upsert(mappedLocations)
    .select();

  if (error) {
    throw new Error("Couldn't update locations");
  }

  return data;
}

interface OrganisationLocation {
  organisation_id: number;
  zip_code: number;
}

export async function fetchOrganisationLocations(
  supabase: SupabaseClient,
  organisationId: number,
): Promise<OrganisationLocation[]> {
  const { data, error } = await supabase
    .from('organisation_locations')
    .select('*')
    .eq('organisation_id', organisationId);

  if (error) {
    throw new Error("Couldn't fetch locations");
  }

  return data;
}
