import { Badge, Flex, Text } from '@mantine/core';
import React from 'react';
import classes from './ConversationMessage.module.scss';
import { LeadStatusChanges } from 'be/db';
import { Selectable } from 'kysely';
import { formatStatusToLabel } from 'shared/formatter';

interface ConversationStatusProps {
  change: Selectable<LeadStatusChanges>;
}

export function ConvoItemStatus({ change }: ConversationStatusProps) {
  return (
    <Flex
      data-testid="convo-item-status"
      className={`${classes.bubble} ${classes.note}`}
      style={{ flex: 1 }}
      direction="column"
      gap="xs"
      w="100%"
      bg={'gray.1'}
    >
      <Flex align="center" gap="xs" data-testid="label">
        Lead status updated to
        <Badge variant="dot" color="dark" data-testid="value">
          {formatStatusToLabel(change.new_status)}
        </Badge>
      </Flex>

      <Text fz="sm" c="dimmed">
        {new Date().toLocaleString()}
      </Text>
    </Flex>
  );
}
