import { Button, Container, Flex, NumberInput, Select, Switch } from "@mantine/core";
import { zodResolver, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useOrganisationCompCriteria, useOrganisationStore, useUpsertCompCriteria } from "fe/queries";
import { Typography } from "fe/ui/shared";
import { useEffect } from "react";
import { PropertySearchSoldInLast } from "shared/db";
import { z } from 'zod';


export function CompCriteriaSettings() {
    const { organisation } = useOrganisationStore()

    if (!organisation) return null

    const { data: criteria } = useOrganisationCompCriteria(organisation.id)
    const { mutateAsync: upsert, isPending: isUpsertLoading } = useUpsertCompCriteria(organisation.id)

    const schema = z.object({
        sqft_range: z.number().min(1),
        proximity_miles: z.number().min(0.25),
        sold_days: z.string() || z.number(),
    });

    const form = useForm({
        initialValues: {
            sqft_range: criteria?.sqft_range,
            proximity_miles: criteria?.proximity_miles,
            sold_days: criteria?.sold_days,
            year_built_range: criteria?.year_built_range || '',
            lot_size_range: criteria?.lot_size_range || '',
            must_match_stories: criteria?.must_match_stories || false,
            must_match_basement: criteria?.must_match_basement || false,
            must_match_parking: criteria?.must_match_parking || false,
            must_match_school_district: criteria?.must_match_school_district || false,
        },
        validate: zodResolver(schema),
    });

    useEffect(() => {
        if (!criteria) return
        form.setValues({
            sqft_range: criteria.sqft_range,
            proximity_miles: criteria.proximity_miles,
            sold_days: criteria.sold_days,
            year_built_range: criteria.year_built_range || '',
            lot_size_range: criteria.lot_size_range || '',
            must_match_stories: criteria.must_match_stories || false,
            must_match_basement: criteria.must_match_basement || false,
            must_match_parking: criteria.must_match_parking || false,
            must_match_school_district: criteria.must_match_school_district || false,
        })
    }, [criteria])

    const onSubmit = form.onSubmit(async (values) => {
        if (!values.sqft_range || !values.proximity_miles || !values.sold_days) return
        await upsert({
            ...values,
            sqft_range: values.sqft_range,
            proximity_miles: values.proximity_miles,
            sold_days: values.sold_days,
            organisation_id: organisation.id,
            lot_size_range: Number(values.lot_size_range) === 0 || !values.lot_size_range ? null : Number(values.lot_size_range),
            year_built_range: Number(values.year_built_range) === 0 ? null : Number(values.year_built_range),
        })

        notifications.show({
            title: 'Comp criteria updated',
            message: `We will find comparables that match your criteria.`,
        })
    })

    return (
        <Container size="xs" pt="lg">
            <Typography variant="label">Comparable Criteria</Typography>

            <Typography mb="xl" c="dimmed" fz="sm">
                Please let us know your criteria when selecting comps for a property.
                <br /><br />
                We might still show you comps that don't fully match your criteria, but we will highlight the ones that do.
            </Typography>

            <form onSubmit={onSubmit}>
                <NumberInput
                    mb="sm"
                    withAsterisk
                    description="Ideal plus or minus sqft range compared to subject property"
                    label="SQFT Range"
                    {...form.getInputProps('sqft_range')}
                    step={100}
                    min={100}
                />

                <NumberInput
                    mb="sm"
                    withAsterisk
                    precision={2}
                    step={0.1}
                    min={0.1}
                    label="Proximity (miles)"
                    description="How close should the comparable be relative to the subject property"
                    {...form.getInputProps('proximity_miles')}
                />

                <Select
                    label="Sold days"
                    placeholder="Pick one"
                    data={Object.values(PropertySearchSoldInLast).map((value) => ({
                        label: value,
                        value
                    }))}
                    {...form.getInputProps('sold_days')}
                />

                <NumberInput
                    mb="sm"
                    step={100}
                    max={5000}
                    label="Max lot sqft size difference"
                    description="Maximum difference in lot size between the subject property and the comp"
                    placeholder="Leave empty for no limit"
                    min={0}
                    {...form.getInputProps('lot_size_range')}
                />

                <NumberInput
                    mb="md"
                    label="Max year built difference"
                    max={100}
                    description="Maximum difference in built years between the subject property and the comp"
                    placeholder="Leave empty for no limit"
                    step={5}
                    min={0}
                    {...form.getInputProps('year_built_range')}
                />

                <Switch
                    mb="md"
                    label="Number of stories must match"
                    description="If the subject property has 2 stories, the comp must also have 2 stories"
                    {...form.getInputProps('must_match_stories')}
                    checked={form.values.must_match_stories}
                />

                <Switch
                    mb="md"
                    label="Basement must match"
                    description="If the subject property has a basement, the comp must also have a basement"
                    {...form.getInputProps('must_match_basement')}
                    checked={form.values.must_match_basement}
                />

                <Switch
                    mb="md"
                    label="Parking must match"
                    description="If the subject property has a garage, the comp must also have a garage"
                    {...form.getInputProps('must_match_parking')}
                    checked={form.values.must_match_parking}
                />

                <Switch
                    mb="md"
                    label="School district must match"
                    description="If the subject property is in a specific school district, the comp must also be in the same district"
                    {...form.getInputProps('must_match_school_district')}
                    checked={form.values.must_match_school_district}
                />

                <Flex w="100%" justify="flex-end">
                    <Button type="submit" mt="md" loading={isUpsertLoading}>
                        {criteria ? 'Update' : 'Create'}
                    </Button>
                </Flex>
            </form>
        </Container>
    )
}