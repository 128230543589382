import { Divider, Flex } from '@mantine/core';
import { useInboxItems, useInboxStore, useOrganisationStore } from 'fe/queries';
import React from 'react';
import { ConversationsList } from './ConversationsList';
import { ConversationDetail } from './Conversation/ConversationsDetail';
import { PageLoader } from 'fe/ui/shared';
import { Layout } from 'fe/feature/layout';
import { Products } from 'shared/db';

export function InboxPage() {
  const { organisation, isLoading: isLoadingOrganisation } =
    useOrganisationStore();
  const {
    filterCampaignType,
    filterCampaigns,
    filterLeadStatus,
    filterSearchTerm,
  } = useInboxStore((state) => ({
    filterLeadStatus: state.filterLeadStatus,
    filterCampaigns: state.filterCampaigns,
    filterCampaignType: state.filterCampaignType,
    filterSearchTerm: state.filterSearchTerm,
  }));

  const {
    data: pages,
    fetchNextPage,
    isFetching: isLoadingInboxItems,
  } = useInboxItems(
    organisation?.id,
    filterSearchTerm,
    filterLeadStatus,
    filterCampaigns,
    filterCampaignType as Products,
  );

  const data = pages?.pages.map((group) => group).flat() || [];

  const isLoading = isLoadingOrganisation;

  if (isLoading) return <PageLoader />;

  return (
    <Layout noScrolling noPadding>
      <Flex w="100%">
        <ConversationsList
          items={data || []}
          fetchNextPage={fetchNextPage}
          isLoading={isLoadingInboxItems && data.length === 0}
        />

        <Divider orientation="vertical" />

        <ConversationDetail items={data} isLoading={isLoadingInboxItems} />
      </Flex>
    </Layout>
  );
}
