import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  OrganisationWorkflow,
  createOrganisationWorkflow,
  deleteOrganisationWorkflow,
  updateOrganisationWorkflow,
} from 'shared/db';
import { supabase } from '../utils';
import { trpc } from '../setup';

export const useOrganisationWorkflows = (organisationId: number) =>
  trpc.organization.getWorkflows.useQuery({ organization_id: organisationId });

export const useCreateOrganisationWorkflow = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (workflowName: string) =>
      createOrganisationWorkflow(supabase, {
        organisation_id: organisationId,
        name: workflowName,
      }),
    onSuccess: (data) => {
      queryClient.setQueriesData<OrganisationWorkflow[]>(
        { queryKey: ['organisation_workflows', organisationId] },
        (old_data) => [...(old_data || []), data],
      );
    },
  });
};

export const useUpdateOrganisationWorkflow = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (contract: OrganisationWorkflow) =>
      updateOrganisationWorkflow(supabase, contract),
    onSuccess: (data) => {
      queryClient.setQueriesData<OrganisationWorkflow[]>(
        { queryKey: ['organisation_workflows', organisationId] },
        (old_data) =>
          (old_data || []).map((c) => (c.id === data.id ? data : c)),
      );
    },
  });
};

export const useDeleteOrganisationWorkflow = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (workflow_id: number) =>
      deleteOrganisationWorkflow(supabase, workflow_id),
    onSuccess: (_, workflow_id) => {
      queryClient.setQueriesData<OrganisationWorkflow[]>(
        { queryKey: ['organisation_workflows', organisationId] },
        (old_data) => (old_data || []).filter((c) => c.id !== workflow_id),
      );
    },
  });
};
