import { ActionIcon, Flex, Paper, Text, ThemeIcon } from '@mantine/core';
import React from 'react';
import { getLabelFromUrl } from 'shared/formatter';
import { IconDownload } from '@tabler/icons-react';
import { PropertyMessageAttachments } from 'be/db';
import { FileIcon } from 'fe/ui/shared';
import { useDownloadAttachment } from 'fe/queries';
import { Selectable } from 'kysely';

interface AttachmentCardProps {
  attachment: Selectable<PropertyMessageAttachments>;
}

export function AttachmentCard({ attachment }: AttachmentCardProps) {
  const { mutateAsync: downloadAttachment, isPending: isDownloading } =
    useDownloadAttachment();

  return (
    <Paper
      color="transparent"
      bg="transparent"
      withBorder
      p="xs"
      w="fit-content"
    >
      <Flex align="center" gap="sm" h="100%">
        <ThemeIcon color="red" size="lg">
          <FileIcon mimeType={attachment.mime_type} />
        </ThemeIcon>

        <Flex direction="column">
          <Text size="xs" style={{ whiteSpace: 'nowrap' }}>
            {getLabelFromUrl(attachment.filename)}
          </Text>
          <Flex>
            <Text c="dimmed" size="xs" style={{ whiteSpace: 'nowrap' }}>
              {attachment.mime_type}
            </Text>
          </Flex>
        </Flex>

        <Flex>
          <ActionIcon
            size="sm"
            onClick={() => downloadAttachment(attachment)}
            loading={isDownloading}
          >
            <IconDownload />
          </ActionIcon>
        </Flex>
      </Flex>
    </Paper>
  );
}
