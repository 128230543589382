import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  NewPricingStrategy,
  PricingStrategy,
  createPricingStrategy,
  deletePricingStrategy,
  fetchPricingStrategies,
  updatePricingStrategy,
} from 'shared/db';
import { supabase } from '../utils';

export const usePricingStrategies = (organisation_id: number) =>
  useQuery({
    queryKey: ['pricing_strategies', organisation_id],
    queryFn: () => fetchPricingStrategies(supabase, organisation_id),
  });

export const useCreatePricingStrategy = (organisation_id: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (pricingStrategy: NewPricingStrategy) =>
      createPricingStrategy(supabase, pricingStrategy),
    onSuccess: (data) => {
      queryClient.setQueriesData<PricingStrategy[]>(
        { queryKey: ['pricing_strategies', organisation_id] },
        (old_data) => [...(old_data || []), data],
      );
    },
  });
};

export const useUpdatePricingStrategy = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (strategy: PricingStrategy) =>
      updatePricingStrategy(supabase, strategy),
    onSuccess: (data) => {
      queryClient.setQueriesData<PricingStrategy[]>(
        { queryKey: ['pricing_strategies', organisationId] },
        (old_data) =>
          (old_data || []).map((c) => (c.id === data.id ? data : c)),
      );
    },
  });
};

export const useDeletePricingStrategy = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (strategy_id: number) =>
      deletePricingStrategy(supabase, strategy_id),
    onSuccess: (_, strategy_id) => {
      queryClient.setQueriesData<PricingStrategy[]>(
        { queryKey: ['pricing_strategies', organisationId] },
        (old_data) => (old_data || []).filter((c) => c.id !== strategy_id),
      );
    },
  });
};
