import { Flex, Text, ActionIcon, FileInput, List, ThemeIcon, rem } from "@mantine/core";
import { useDeleteWorkflowStepAttachment, useOrganisationStore, useUploadWorkflowStepAttachment } from "fe/queries";
import { WorkflowStep } from "shared/db";
import { IconFile, IconUpload, IconX } from "@tabler/icons-react";

interface WorkflowStepFormProps {
    workflowStep: WorkflowStep;
}

export function WorkflowStepAttachmentForm({
    workflowStep,
}: WorkflowStepFormProps) {
    const { organisation } = useOrganisationStore()
    const {
        mutateAsync: saveAttachment,
        isPending: isSavingAttachment
    } = useUploadWorkflowStepAttachment(organisation!, workflowStep)
    const {
        mutateAsync: deleteAttachment,
        isPending: isDeletingAttachment
    } = useDeleteWorkflowStepAttachment(workflowStep)

    return (
        <div>
            <List mt="md" icon={
                <ThemeIcon color="teal" size={24} radius="xl">
                    <IconFile size="1rem" />
                </ThemeIcon>
            }>
                {workflowStep?.attachments?.map(attachment => (
                    <List.Item key={attachment} my="md">
                        <Flex gap="md" align="center">
                            {attachment}
                            <ActionIcon
                                loading={isDeletingAttachment}
                                onClick={() => deleteAttachment(attachment)}
                                variant="filled"
                                color="red"
                                size="xs">
                                <IconX />
                            </ActionIcon>
                        </Flex>
                    </List.Item>
                ))}
            </List>

            <FileInput
                style={{ flex: 1 }}
                label="Attachments to send alongside the contract"
                placeholder="Upload as many files as you want"
                multiple
                onChange={(files) => saveAttachment(files)}
                icon={<IconUpload size={rem(14)} />}
                disabled={isSavingAttachment}
            />

            <Text mt="sm" fz="sm" c="dimmed">
                {isSavingAttachment ? 'Saving...' : 'Auto save enabled'}
            </Text>
        </div>
    )
}