import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { callApi, supabase } from '../utils';
import {
  Property,
  PropertyComp,
  fetchFormattedAddress,
  fetchProperty,
  fetchPropertyComps,
  fetchPropertyImages,
  updatePropertyComp,
} from 'shared/db';
import {
  getPropertyEndpoint,
  GetPropertyEndpointResponse,
  organizationCalculateCompScoreEndpoint,
} from 'shared/api';

export const useFullProperty = (
  street_address: string | undefined,
  zip_code: number | undefined,
) =>
  useQuery({
    queryKey: ['property', street_address, zip_code],
    queryFn: () =>
      street_address && zip_code
        ? callApi(getPropertyEndpoint, { street_address, zip_code }).then(
            (res: GetPropertyEndpointResponse) => res.data,
          )
        : null,
    retry: 0,
  });

export const useProperty = (street_address: string, zip_code: number) =>
  useQuery({
    queryKey: ['property', street_address, zip_code],
    queryFn: () => fetchProperty(supabase, street_address, zip_code),
    retry: 0,
  });

export const usePropertyImages = (
  street_address: string | undefined,
  zip_code: number | undefined,
) =>
  useQuery({
    queryKey: ['property_images', street_address, zip_code],
    queryFn: () => {
      if (!street_address || !zip_code) return [];
      return fetchPropertyImages(supabase, street_address, zip_code);
    },
    retry: 0,
  });

export const usePropertyComps = (
  street_address: string | undefined,
  zip_code: number | undefined,
) =>
  useQuery({
    queryKey: ['property_comps', street_address, zip_code],
    queryFn: () => {
      if (!street_address || !zip_code) return [];
      return fetchPropertyComps(supabase, street_address, zip_code);
    },
  });

export const useUpdatePropertyComp = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (comp: PropertyComp) => {
      return updatePropertyComp(supabase, {
        ...comp,
      });
    },
    onSuccess: (data) => {
      queryClient.setQueriesData<PropertyComp[]>(
        { queryKey: ['property_comps', data.street_address, data.zip_code] },
        (previous) => previous?.filter((step) => step.id !== data.id),
      );
    },
  });
};

export const usePropertyAddress = (property: Property | undefined) =>
  useQuery({
    queryKey: [
      'property_address',
      property?.street_address,
      property?.zip_code,
    ],
    queryFn: () => {
      if (!property?.street_address || !property?.zip_code) return '';
      return fetchFormattedAddress(supabase, property);
    },
  });

const keys = {
  all: ['availableListings'] as const,
  city: (city: string) => [...keys.all, city],
  comp_score: ({
    organisationId,
    subjectProperty,
    comparableProperty,
  }: CalculateCompScoreParams) => [
    'comp_score',
    organisationId,
    subjectProperty,
    comparableProperty,
  ],
};

interface CalculateCompScoreParams {
  organisationId?: number;
  subjectProperty: Property;
  comparableProperty: Property;
}

export const useCalculateCompScore = ({
  organisationId,
  subjectProperty,
  comparableProperty,
}: CalculateCompScoreParams) =>
  useQuery({
    queryKey: keys.comp_score({
      organisationId,
      subjectProperty,
      comparableProperty,
    }),
    queryFn: () => {
      return organisationId
        ? callApi(
            organizationCalculateCompScoreEndpoint,
            { organization_id: organisationId },
            { subjectProperty, comparableProperty },
          ).then((res) => res.score)
        : null;
    },
  });
