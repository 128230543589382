import { useMutation, useQueryClient } from '@tanstack/react-query';
import { callApi } from './../utils';
import {
  analyzeComparablesEndpoint,
  analyzeConditionEndpoint,
  analyzeContactEndpoint,
  analyzePropertyInfoEndpoint,
  campaignScheduleAutoOfferEndpoint,
  organizationScheduleOfferEndpoint,
} from 'shared/api';
import { CampaignListing } from 'shared/db';

export const useAnalyzePropertyInfo = (organization_id: number) =>
  useMutation({
    mutationFn: (address: string) =>
      callApi(analyzePropertyInfoEndpoint, { organization_id }, { address }),
  });

interface PropertyParams {
  street_address: string;
  zip_code: number;
}

export const useAnalyzeComparables = (organization_id: number) =>
  useMutation({
    mutationFn: ({ street_address, zip_code }: PropertyParams) =>
      callApi(
        analyzeComparablesEndpoint,
        { organization_id },
        { street_address, zip_code },
      ),
  });

export const useAnalyzeCondition = (organization_id: number) =>
  useMutation({
    mutationFn: ({ street_address, zip_code }: PropertyParams) =>
      callApi(
        analyzeConditionEndpoint,
        { organization_id },
        { street_address, zip_code },
      ),
  });

export const useAnalyzeContact = (organization_id: number) =>
  useMutation({
    mutationFn: ({ street_address, zip_code }: PropertyParams) =>
      callApi(
        analyzeContactEndpoint,
        { organization_id },
        { street_address, zip_code },
      ),
  });

interface OfferParams {
  street_address: string;
  zip_code: number;
  contract_id: number;
  campaign_id?: number;
  offer_price: number;
  workflow_id: number;
  subject: string;
  content: string;
  email: string;
  name: string;
}

export const useScheduleOffer = (organisationId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: OfferParams) => {
      return callApi(
        organizationScheduleOfferEndpoint,
        { organization_id: organisationId, workflow_id: params.workflow_id },
        { ...params },
      );
    },
    onSuccess: (_, params) => {
      queryClient.setQueriesData<CampaignListing[]>(
        { queryKey: ['listings'] },
        (previous) =>
          (previous || []).filter(
            (offer) =>
              offer.street_address !== params.street_address ||
              offer.zip_code !== params.zip_code,
          ),
      );
    },
  });
};

interface AutoOfferParams {
  street_address: string;
  zip_code: number;
}

export const useScheduleAutoOffer = (campaign_id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: AutoOfferParams) => {
      return callApi(
        campaignScheduleAutoOfferEndpoint,
        { campaign_id },
        { ...params },
      );
    },
    onSuccess: (_, params) => {
      queryClient.setQueriesData<CampaignListing[]>(
        { queryKey: ['listings'] },
        (previous) =>
          (previous || []).filter(
            (offer) =>
              offer.street_address !== params.street_address ||
              offer.zip_code !== params.zip_code,
          ),
      );
    },
  });
};
