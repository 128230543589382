import { Button, Flex, NumberInput, Switch, TextInput, Textarea } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCreatePricingStrategy, useOrganisationStore, useUpdatePricingStrategy } from "fe/queries";
import { PricingStrategy } from "shared/db";

interface RepairLevelFormProps {
    pricingStrategy?: PricingStrategy;
    onSuccess: () => void;
}

export function PricingStrategyForm({
    pricingStrategy,
    onSuccess
}: RepairLevelFormProps) {
    const { organisation } = useOrganisationStore()

    if (!organisation) return null

    const { mutateAsync: create, isPending: isCreateLoading } = useCreatePricingStrategy(organisation.id)
    const { mutateAsync: update, isPending: isUpdateLoading } = useUpdatePricingStrategy(organisation.id)

    const form = useForm({
        initialValues: {
            name: pricingStrategy?.name || '',
            include_repair_costs: pricingStrategy?.include_repair_costs || true,
            flat_discount: pricingStrategy?.flat_discount || 0,
            arv_pct_discount: pricingStrategy?.arv_pct_discount || 80,
            description_target: pricingStrategy?.description_target || '',
        },
        validate: {
            name: isNotEmpty(),
            flat_discount: isNotEmpty(),
            arv_pct_discount: isNotEmpty(),
            description_target: isNotEmpty(),
        }
    });

    const onSubmit = form.onSubmit(async (values) => {
        if (pricingStrategy) {
            await update({
                ...pricingStrategy,
                ...values
            })
        } else {
            await create({
                organisation_id: organisation.id,
                ...values
            })
        }
        onSuccess()
    })

    return (
        <div>
            <form onSubmit={onSubmit}>
                <TextInput
                    mb="sm"
                    withAsterisk
                    label="Name"
                    placeholder="High"
                    {...form.getInputProps('name')}
                />

                <Switch
                    label="Include repair costs"
                    defaultChecked={pricingStrategy?.include_repair_costs}
                    {...form.getInputProps('include_repair_costs')}
                    mb="sm"
                />

                <NumberInput
                    mb="sm"
                    label="Flat discount"
                    {...form.getInputProps('flat_discount')}
                />

                <NumberInput
                    mb="sm"
                    withAsterisk
                    label="ARV % discount"
                    {...form.getInputProps('arv_pct_discount')}
                    min={0}
                    max={100}
                />

                <Textarea
                    mb="sm"
                    withAsterisk
                    label="Description of target"
                    {...form.getInputProps('description_target')}
                />

                <Flex w="100%" justify="flex-end">
                    <Button type="submit" mt="md" loading={isCreateLoading || isUpdateLoading}>
                        {pricingStrategy ? 'Update' : 'Create'}
                    </Button>
                </Flex>
            </form>
        </div>
    )
}