import {
  ActionIcon,
  Box,
  Divider,
  Flex,
  Image,
  Modal,
  ScrollArea,
  Skeleton,
  Tooltip,
} from '@mantine/core';
import { useRecommendationsStore } from 'fe/queries';
import { Typography } from 'fe/ui/shared';
import { Campaign, CampaignOfferRecommendation } from 'shared/db';
import classes from './OfferQueue.module.scss';
import { IconFilter } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { CampaignFiltersView } from 'fe/feature/campaign';

interface ARV_SidenavProps {
  campaign: Campaign;
}

export function ARV_Sidenav({ campaign }: ARV_SidenavProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const { recommendations, isLoading, activeOffer, setActiveOffer } =
    useRecommendationsStore(Number(campaign.id));
  const isActive = (offer: CampaignOfferRecommendation) =>
    activeOffer?.street_address === offer.street_address &&
    activeOffer?.zip_code === offer.zip_code;

  const list = isLoading
    ? new Array(20).fill(0).map((_) => (
        <Flex gap="xs" style={{ opacity: 0.7 }} align="center">
          <Skeleton height={24} width={24} />

          <Skeleton height={16} style={{ flex: 1 }} mt={6} radius="sm" />
        </Flex>
      ))
    : recommendations?.map((offer) => (
        <Flex
          gap="xs"
          className={isActive(offer) ? classes.activeItem : classes.item}
          align="center"
          key={offer.street_address}
          onClick={() => setActiveOffer(offer)}
        >
          <Image
            maw={24}
            height={24}
            src={offer.property.img_src}
            alt="Random image"
          />

          <Typography className={isActive(offer) && classes.activeText}>
            {offer.street_address}
          </Typography>
        </Flex>
      ));

  return (
    <Box w={248} pl={12} pt={32}>
      <Modal
        opened={opened}
        onClose={close}
        title="Filters"
        h="100%"
        classNames={{ content: classes.modalContent }}
      >
        <CampaignFiltersView campaign={campaign} />
      </Modal>

      <Flex justify="space-between">
        <Typography fw={800} tt="uppercase">
          Offer Queue
        </Typography>

        <Flex gap="xs">
          <Tooltip label="Filters">
            <ActionIcon onClick={open} variant="outline" color="#0169FE">
              <IconFilter size="1rem" />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </Flex>

      <Divider c="dimmed" mt="xs" />

      <Box
        h="90%"
        style={{ position: 'relative', flex: 1, overflow: 'hidden' }}
      >
        <ScrollArea
          id="chat-scroll"
          style={{ position: 'absolute', height: '100%', width: '100%' }}
        >
          <Flex direction="column" mt="xl">
            {list}
          </Flex>
        </ScrollArea>
      </Box>
    </Box>
  );
}
