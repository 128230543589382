import {
  CampaignListing,
  CampaignOfferRecommendationWithProperty,
  NewCampaignPropertyStatus,
  StatusOfCampaignProperty,
  createCampaignPropertyStatus,
  fetchCampaignPropertyStatusById,
} from 'shared/db';
import { callApi, supabase } from '../utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { fetchLeadContractEndpoint, updateLeadEndpoint } from 'shared/api';
import { LeadInbox } from '../inbox/useInbox';
import { Selectable } from 'kysely';
import { LeadAiSuggestions, LeadStatusChanges } from 'be/db';

export const useCreateCampaignPropertyStatus = (campaignId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (campaign: NewCampaignPropertyStatus) =>
      createCampaignPropertyStatus(supabase, campaign),
    onSuccess: (data) => {
      queryClient.setQueriesData<CampaignOfferRecommendationWithProperty[]>(
        { queryKey: ['recommendations', campaignId] },
        (previous) => [
          ...(previous || []).filter(
            (recommendation) =>
              recommendation.street_address !== data.street_address &&
              recommendation.zip_code !== data.zip_code,
          ),
        ],
      );

      queryClient.setQueriesData<CampaignListing[]>(
        { queryKey: ['listings'] },
        (previous) =>
          (previous || []).filter(
            (offer) =>
              offer.street_address !== data.street_address ||
              offer.zip_code !== data.zip_code,
          ),
      );
    },
  });
};

export const useLeadContract = (lead_id: number | null | undefined) =>
  useQuery({
    queryKey: ['lead_contract_pdf', lead_id],
    queryFn: async () =>
      lead_id &&
      callApi(fetchLeadContractEndpoint, { lead_id }).then(
        (res) => res.data as string,
      ),
  });

export const useLead = (lead_id: number | null | undefined) =>
  useQuery({
    queryKey: ['lead', lead_id],
    queryFn: async () =>
      lead_id ? fetchCampaignPropertyStatusById(supabase, lead_id) : null,
  });

export const useUpdateLead = (lead_id: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (status: StatusOfCampaignProperty) =>
      callApi(updateLeadEndpoint, { lead_id }, { status }).then(
        (res) =>
          res.data as {
            leadStatusChange: Selectable<LeadStatusChanges>;
            aiSuggestion: Selectable<LeadAiSuggestions> | undefined;
          },
      ),
    onSuccess: (data) => {
      queryClient.setQueriesData<CampaignOfferRecommendationWithProperty[]>(
        { queryKey: ['lead', lead_id] },
        (previous) =>
          previous && {
            ...previous,
            status: data.leadStatusChange.new_status,
          },
      );
      queryClient.setQueriesData<LeadInbox>(
        { queryKey: ['lead_inbox', lead_id] },
        (previous) =>
          previous && {
            ...previous,
            statusChanges: [...previous.statusChanges, data.leadStatusChange],
            suggestedActions: previous.suggestedActions.filter(
              (suggestion) => suggestion.id !== data.aiSuggestion?.id,
            ),
          },
      );
    },
  });
};
