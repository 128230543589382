import { SupabaseClient } from '@supabase/supabase-js';
import { Organisation } from './organisations';

export interface OrganisationMember {
  id: number;
  user_id: string;
  organisation_id: number;
  notifications_enabled: boolean;
}

export async function fetchOrganisationMembers(
  supabase: SupabaseClient,
  organisationId: number,
): Promise<OrganisationMember[]> {
  const { data, error } = await supabase
    .from('organisation_members')
    .select()
    .eq('organisation_id', organisationId)
    .order('created_at', { ascending: true });

  if (error) {
    throw new Error("Couldn't fetch organisation members");
  }

  return data;
}

export async function createOrganisationMember(
  supabase: SupabaseClient,
  organisationMember: Omit<OrganisationMember, 'id'>,
): Promise<OrganisationMember> {
  const { data, error } = await supabase
    .from('organisation_members')
    .insert(organisationMember)
    .select()
    .single();

  if (error) {
    throw new Error("Couldn't create member");
  }

  return data;
}

export async function fetchOrganisationMembersAndOwnerWithEmail(
  supabase: SupabaseClient,
  organisation: Organisation,
) {
  const members = await fetchOrganisationMembers(supabase, organisation.id);
  const {
    data: { users },
    error,
  } = await supabase.auth.admin.listUsers();

  if (error) {
    console.log('error: ', error);
    throw Error(' Error fetching organisation');
  }

  const membersWithEmails = [];
  membersWithEmails.push(
    ...members.map((member) => {
      return {
        id: member.id,
        user_id: member.user_id,
        email: users.find((user) => user.id === member.user_id)?.email,
        type: 'Member',
        notifications_enabled: member.notifications_enabled,
      };
    }),
  );

  return membersWithEmails;
}

export async function fetchOrganisationMembersWithEmail(
  supabase: SupabaseClient,
  organisation: Organisation,
) {
  const members = await fetchOrganisationMembers(supabase, organisation.id);
  const {
    data: { users },
    error,
  } = await supabase.auth.admin.listUsers();

  if (error) {
    return { error };
  }

  const membersWithEmails = [];
  membersWithEmails.push(
    ...members.map((member) => {
      return {
        id: member.id,
        email: users.find((user) => user.id === member.user_id)?.email,
        type: 'Member',
        notifications_enabled: member.notifications_enabled,
      };
    }),
  );

  return { data: membersWithEmails };
}

export async function deleteOrganisationMember(
  supabase: SupabaseClient,
  memberId: OrganisationMember['id'],
): Promise<OrganisationMember['id']> {
  const { error } = await supabase
    .from('organisation_members')
    .delete()
    .eq('id', memberId);

  if (error) {
    throw new Error("Couldn't delete member");
  }

  return memberId;
}

export async function updateOrganisationMember(
  supabase: SupabaseClient,
  member: OrganisationMember,
): Promise<OrganisationMember> {
  const { error } = await supabase
    .from('organisation_members')
    .update(member)
    .eq('id', member.id);

  if (error) {
    throw new Error("Couldn't update member");
  }

  return member;
}
