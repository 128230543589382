import {
  OrganisationBlacklistedEmail,
  createBlacklistedEmail,
  deleteBlacklistedEmail,
  fetchBlacklistedEmails,
} from 'shared/db';
import { supabase } from '../utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const BLACKLISTED_EMAILS_KEY = 'blacklisted_emails';

export const useBlacklistedEmails = (organisation_id: number) =>
  useQuery({
    queryKey: [BLACKLISTED_EMAILS_KEY, organisation_id],
    queryFn: () => fetchBlacklistedEmails(supabase, organisation_id),
  });

export const useBlacklistEmail = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (email: string) =>
      createBlacklistedEmail(supabase, {
        email,
        organisation_id: organisationId,
      }),
    onSuccess: (data) => {
      queryClient.setQueriesData<OrganisationBlacklistedEmail[]>(
        { queryKey: [BLACKLISTED_EMAILS_KEY] },
        (previous) => [...(previous || []), data],
      );
    },
  });
};

export const useDeleteBlacklistedEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (emailId: number) => deleteBlacklistedEmail(supabase, emailId),
    onSuccess: (emailId) => {
      queryClient.setQueriesData<OrganisationBlacklistedEmail[]>(
        { queryKey: [BLACKLISTED_EMAILS_KEY] },
        (previous) => (previous || []).filter((email) => email.id !== emailId),
      );
    },
  });
};
