import {
  Navbar,
  Image,
  Flex,
  Button,
  Text,
  Divider,
  ScrollArea,
  Box,
  Modal,
} from '@mantine/core';
import Logo from './Logo.svg';
import {
  IconChartArrowsVertical,
  IconInbox,
  IconPlus,
  IconSearch,
  IconSettings,
  TablerIconsProps,
} from '@tabler/icons-react';
import {
  NavigateOptions,
  useNavigate,
  useRouterState,
} from '@tanstack/react-router';
import { OrganizationSwitch } from '../OrganisationSwitch';
import { useCampaigns, useOrganisationStore } from 'fe/queries';
import { SidebarButton } from './SidebarButton';
import { useDisclosure } from '@mantine/hooks';
import { CampaignForm } from '../CampaignForm/CampaignForm';
import { SidebarCampaignButton } from './SidebarCampaignButton';

export function Sidebar() {
  const router = useRouterState();
  const navigate = useNavigate();
  const { organisation } = useOrganisationStore();
  const { data: campaigns } = useCampaigns(organisation?.id || 0);
  const [opened, { open, close }] = useDisclosure(false);

  const links: {
    id: string;
    label: string;
    icon: (props: TablerIconsProps) => JSX.Element;
    navigateProps: NavigateOptions;
  }[] = [
    {
      id: '',
      label: 'Inbox',
      icon: IconInbox,
      navigateProps: {
        to: '/',
      },
    },
    {
      id: 'insights',
      label: 'Insights',
      icon: IconChartArrowsVertical,
      navigateProps: {
        to: '/insights',
      },
    },
    {
      id: 'offer',
      label: 'Offer',
      icon: IconSearch,
      navigateProps: {
        to: '/offer',
      },
    },
    {
      id: 'settings',
      label: 'Settings',
      icon: IconSettings,
      navigateProps: {
        to: '/settings',
      },
    },
  ];

  const isRouteActive = (id: string) =>
    id === ''
      ? router.location.pathname === '/'
      : router.location.pathname.includes(id);

  return (
    <Navbar width={{ base: 250 }} pt="md">
      <Modal
        opened={opened}
        onClose={close}
        size="md"
        title="Create new campaign"
        centered
      >
        <CampaignForm smallMode />
      </Modal>

      <Flex direction="column" justify="space-between" h="100%">
        <Flex style={{ flex: 1 }} direction="column">
          <Flex key={'logo'} align="center" justify="center" mb="xs">
            <Image radius="sm" src={Logo} width={38} height={42} />
          </Flex>

          <Flex
            key="menu"
            mt="xl"
            direction="column"
            style={{ margin: '20px 0px' }}
          >
            {links.map((link) => (
              <SidebarButton
                key={link.id}
                mt="xs"
                active={isRouteActive(link.id)}
                leftSection={<link.icon size={24} stroke={1.5} />}
                reverse
                onClick={() => navigate(link.navigateProps)}
              >
                <Text key={link.id} component="a" size="sm">
                  {link.label}
                </Text>
              </SidebarButton>
            ))}
          </Flex>

          <Divider />

          <Flex
            key="campaigns"
            mt="xl"
            direction="column"
            style={{ margin: '20px 0', flex: 1 }}
            gap="xs"
          >
            <Text fz="xs" fw="bold" transform="uppercase" mx={20}>
              Campaigns
            </Text>

            <Box pos="relative" style={{ flex: 1 }}>
              <ScrollArea
                style={{ position: 'absolute', height: '100%', width: '100%' }}
              >
                {campaigns?.map((campaign) => (
                  <SidebarCampaignButton
                    key={campaign.id}
                    campaign={campaign}
                    isActive={isRouteActive(String(campaign.id))}
                  />
                ))}
              </ScrollArea>
            </Box>
          </Flex>
        </Flex>

        <Flex direction="column" gap="sm">
          <Button variant="light" leftIcon={<IconPlus />} onClick={open}>
            Create new campaign
          </Button>

          <OrganizationSwitch />
        </Flex>
      </Flex>
    </Navbar>
  );
}
