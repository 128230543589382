interface Location {
  zip: number;
  zip_code_string: string;
  lat: number;
  lng: number;
  city: string;
  state_id: string;
  state_name: string;
  zcta: boolean;
  parent_zcta: string;
  population: number;
  density: number;
  county_name: string;
}

interface getCityDropdownData {
  cityZipcodes: Record<string, number[]>;
  selectedZipCodes: number[];
}

export function getCityDropdownData({
  cityZipcodes,
  selectedZipCodes,
}: getCityDropdownData) {
  const allCities = Object.keys(cityZipcodes || {}).map((city) => {
    return {
      label: city,
      value: city,
      // group: county,
      type: 'city',
      totalzipcodes: cityZipcodes[city].length,
      selectedzipcodes: selectedZipCodes.filter((zipCode) =>
        cityZipcodes[city].includes(zipCode),
      ).length,
    };
  });
  const dropdownData = allCities.flat() || [];
  const dropdownValue = allCities
    .flat()
    .filter((city) =>
      cityZipcodes[city.value].every((zipCode) =>
        selectedZipCodes.includes(zipCode),
      ),
    )
    .map((city) => city.value);
  return { dropdownData, dropdownValue };
}

interface getZipDropdownData {
  locations: Location[];
  selectedZipCodes: number[];
}

export function getZipDropdownData({
  locations,
  selectedZipCodes,
}: getZipDropdownData) {
  const allZipCodes = locations?.map((location) => ({
    label: String(location.zip_code_string),
    value: String(location.zip),
    group: location.city,
    type: 'zip',
  }));
  const dropdownData = allZipCodes || [];
  const dropdownValue =
    allZipCodes
      ?.filter((zip) => selectedZipCodes.includes(Number(zip.value)))
      .map((zip) => zip.value) || [];
  return { dropdownData, dropdownValue };
}

interface getCountyDropdownData {
  countyZipCodes: Record<string, number[]>;
  selectedZipCodes: number[];
}

export function getCountyDropdownData({
  countyZipCodes,
  selectedZipCodes,
}: getCountyDropdownData) {
  const allCounties = Object.keys(countyZipCodes || {}).map((county) => {
    return {
      label: county,
      value: county,
      type: 'county',
      totalzipcodes: countyZipCodes[county].length,
      selectedzipcodes: selectedZipCodes.filter((zipcode) =>
        countyZipCodes[county].includes(zipcode),
      ).length,
    };
  });
  const dropdownData = allCounties || [];
  const dropdownValue = allCounties
    .filter((county) =>
      countyZipCodes[county.value].every((zipCode) =>
        selectedZipCodes.includes(zipCode),
      ),
    )
    .map((county) => county.value);
  return { dropdownData, dropdownValue };
}

interface getStateDropdownDataParams {
  stateZipCodes: Record<string, number[]>;
  selectedZipCodes: number[];
}

export function getStateDropdownData({
  stateZipCodes,
  selectedZipCodes,
}: getStateDropdownDataParams) {
  const allCounties = Object.keys(stateZipCodes || {}).map((state) => {
    return {
      label: state,
      value: state,
      type: 'state',
      totalzipcodes: stateZipCodes[state].length,
      selectedzipcodes: selectedZipCodes.filter((zipcode) =>
        stateZipCodes[state].includes(zipcode),
      ).length,
    };
  });
  const dropdownData = allCounties || [];
  const dropdownValue = allCounties
    .filter((state) =>
      stateZipCodes[state.value].every((zipCode) =>
        selectedZipCodes.includes(zipCode),
      ),
    )
    .map((state) => state.value);
  return { dropdownData, dropdownValue };
}
