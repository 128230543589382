import { Carousel } from '@mantine/carousel';
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Popover,
  ScrollArea,
  SimpleGrid,
  Skeleton,
  Spoiler,
  Table,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  useFullProperty,
  usePropertyAddress,
  usePropertyComps,
  usePropertyImages,
} from 'fe/queries';
import { Typography } from 'fe/ui/shared';
import {
  calculateArv,
  findLastSoldPrice,
  formatDateToLocale,
  formatPropertyStatusToLabel,
  formatUIPrice,
  getLatLong,
} from 'shared/formatter';
import Map, { Marker } from 'react-map-gl';
import {
  IconInfoCircle,
  IconLink,
  IconMapPin,
  IconPencil,
} from '@tabler/icons-react';
import { Property, PropertyStatus } from 'shared/db';
import classes from './PropertyInfo.module.scss';
import { modals } from '@mantine/modals';
import { CompCriteriaSettings } from 'fe/feature/organisation-settings';
import { useEffect, useState } from 'react';
import { ComparableRow } from './ComparableRow';

interface PropertyInfoProps {
  property?: Property;
  isLoading: boolean;
}

export function PropertyInfo({ property, isLoading }: PropertyInfoProps) {
  const { data: fullProperty } = useFullProperty(
    property?.street_address,
    property?.zip_code,
  );
  const { data: propertyAddress } = usePropertyAddress(property);
  const { data: propertyImages } = usePropertyImages(
    property?.street_address,
    property?.zip_code,
  );
  const { data: comps, isLoading: isCompsLoading } = usePropertyComps(
    property?.street_address,
    property?.zip_code,
  );
  const [viewState, setViewState] = useState({
    longitude: -100,
    latitude: 40,
    zoom: 3.5,
  });

  const calculatedArv =
    comps?.length && property && calculateArv(property, comps);
  const arv = calculatedArv ? formatUIPrice(calculatedArv) : 'N/A';

  const coordinates =
    property && property?.coordinates
      ? getLatLong(property.coordinates)
      : {
          longitude: 0,
          latitude: 0,
        };

  useEffect(() => {
    setViewState({
      longitude: coordinates?.longitude,
      latitude: coordinates?.latitude,
      zoom: 12,
    });
  }, [property?.street_address]);

  if (isLoading || !property) {
    return (
      <Box
        h="100%"
        style={{ position: 'relative', flex: 1, overflow: 'hidden' }}
      >
        <ScrollArea
          style={{ position: 'absolute', height: '100%', width: '100%' }}
        >
          <Flex
            p={32}
            style={{ flex: 1, display: 'flex' }}
            w="100%"
            justify="center"
          >
            <Typography>Loading...</Typography>
          </Flex>
        </ScrollArea>
      </Box>
    );
  }

  const propertyFacts = [
    { label: 'Type', value: property?.property_type },
    {
      label: 'Bed / Bath',
      value: `${property?.bedrooms} / ${property?.bathrooms}`,
    },
    { label: 'SQFT', value: property?.living_area_sqft },
    { label: 'Lot Size', value: property?.lot_area_sqft },
    { label: 'Year built', value: property?.year_built },
    {
      label: 'Rental Income Estimate (per month)',
      value: `${property.rent_zestimate && formatUIPrice(property.rent_zestimate)}`,
    },
  ];

  const openCompSettings = () => {
    modals.open({
      children: <CompCriteriaSettings />,
      classNames: {
        content: classes.modal,
      },
    });
  };

  const isActive = property.status === PropertyStatus.FOR_SALE;
  const lastSoldPrice = findLastSoldPrice(fullProperty?.price_history);
  return (
    <Box h="100%" style={{ position: 'relative', flex: 1, overflow: 'hidden' }}>
      <ScrollArea
        classNames={{
          root: classes.scrollAreaRoot,
        }}
      >
        <Flex
          p={32}
          style={{ flex: 1, display: 'flex' }}
          w="100%"
          justify="center"
        >
          <Box maw={600}>
            <Flex justify="space-between">
              <Typography fw={800} tt="uppercase">
                Property info
              </Typography>

              <Flex gap="sm">
                <Badge
                  size="lg"
                  variant="light"
                  radius="xs"
                  color={isActive ? 'green' : 'gray'}
                >
                  {formatPropertyStatusToLabel(property.status)}
                </Badge>

                <Tooltip label="Go to Zillow property page" withinPortal>
                  <ActionIcon
                    variant="outline"
                    component="a"
                    target="__blank"
                    href={`https://www.zillow.com/homedetails/placeholder/${property.zpid}_zpid/?`}
                  >
                    <IconLink size="1rem" />
                  </ActionIcon>
                </Tooltip>
              </Flex>
            </Flex>

            <Divider c="dimmed" mt="xs" />

            <Carousel mt="xl" maw="100%" mx="auto" withIndicators height={300}>
              {propertyImages?.map((imageSrc) => (
                <Carousel.Slide key={imageSrc.id}>
                  <Image width="100%" height={300} src={imageSrc.url} />
                </Carousel.Slide>
              ))}
            </Carousel>

            <Typography mt="md">{propertyAddress}</Typography>

            <Flex
              mt="md"
              justify="space-between"
              align="flex-start"
              wrap="wrap"
              gap="lg"
            >
              <Flex direction="column">
                {isActive ? (
                  <Flex align="flex-end" gap="xs" justify="flex-end">
                    <Title order={1}>{formatUIPrice(property.price)}</Title>
                    <Flex direction="row" align="flex-end" gap="">
                      <Typography mb={4} variant="label" c="dimmed">
                        LIST PRICE
                      </Typography>
                    </Flex>
                  </Flex>
                ) : (
                  lastSoldPrice && (
                    <Flex align="flex-end" gap="xs" justify="flex-end">
                      <Title order={1}>
                        {formatUIPrice(lastSoldPrice.price)}
                      </Title>
                      <Flex direction="column" mb={2} align="flex-end">
                        <Typography variant="label" size="xs">
                          Sold price
                        </Typography>
                        <Typography variant="label" c="dimmed" size="xs">
                          {formatDateToLocale(lastSoldPrice.event_date)}
                        </Typography>
                      </Flex>
                    </Flex>
                  )
                )}

                {isCompsLoading ? (
                  <Skeleton height={16} mt={5} width={200} />
                ) : (
                  <Typography variant="label" c="dimmed">
                    {`${arv} `}
                    ARV
                  </Typography>
                )}
              </Flex>

              <Flex direction="column" style={{ opacity: 0.8 }}>
                <Typography fz={22} fw="bold" order={3}>
                  {property.zestimate && formatUIPrice(property.zestimate)} per
                  month
                </Typography>
                <Typography variant="label" c="dimmed">
                  Zestimate
                </Typography>
              </Flex>
            </Flex>

            <Flex gap="sm" mt="md" wrap="wrap">
              <Badge variant="filled">
                {property.condition_analysis} condition
              </Badge>
              <Badge variant="filled">ARV upside</Badge>
            </Flex>

            <SimpleGrid cols={2} mt="xl">
              {propertyFacts.map((fact) => (
                <Flex justify="space-between" key={fact.label}>
                  <Typography c="dimmed">{fact.label}</Typography>
                  <Typography>{fact.value}</Typography>
                </Flex>
              ))}
            </SimpleGrid>

            <Typography mt={20} fz={22} fw="bold" order={3}>
              Description
            </Typography>

            <Spoiler maxHeight={50} showLabel="Show more" hideLabel="Hide">
              <Typography fz={16} c="dimmed">
                {property.description}
              </Typography>
            </Spoiler>

            <Flex align="center" mt={20} gap="xs">
              <Typography fz={22} fw="bold" order={3}>
                Comps
              </Typography>

              <ActionIcon size="sm" onClick={openCompSettings}>
                <IconPencil size="1rem" />
              </ActionIcon>
            </Flex>

            <Box
              style={{ position: 'relative', width: '100%' }}
              h={200}
              my="xl"
            >
              <Map
                mapboxAccessToken="pk.eyJ1IjoicnViZW5tZXNlZ3VlcjciLCJhIjoiY2x3dXQ0N25xMGw4YjJzc2dhcDRvMzF3MSJ9.XsS5lkMAE9YkYWgIyWFQrg"
                {...viewState}
                onMove={(evt) => setViewState(evt.viewState)}
                style={{ width: '100%', height: 200 }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
              >
                <Marker
                  key={property.street_address}
                  longitude={coordinates?.longitude}
                  latitude={coordinates?.latitude}
                  anchor="bottom"
                >
                  <IconMapPin size="2rem" />
                </Marker>

                {comps?.map((comp) => (
                  <Marker
                    key={comp.street_address}
                    longitude={
                      comp.coordinates
                        ? getLatLong(comp.coordinates)?.longitude
                        : null
                    }
                    latitude={
                      comp.coordinates
                        ? getLatLong(comp.coordinates)?.latitude
                        : null
                    }
                    anchor="bottom"
                  >
                    <Popover
                      width={200}
                      position="bottom"
                      withArrow
                      shadow="md"
                      withinPortal
                    >
                      <Popover.Target>
                        <ActionIcon color="red" variant="subtle">
                          <IconMapPin size="2rem" />
                        </ActionIcon>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <Flex direction="column" justify="center" gap="xs">
                          <Image
                            width={40}
                            height={40}
                            mx="auto"
                            radius="xs"
                            src={comp.img_src}
                            alt={comp.street_address}
                          />
                          <Typography align="center" size="sm" fw="bold">
                            {comp.street_address}
                          </Typography>

                          <Button
                            component="a"
                            target="__blank"
                            href={`https://www.zillow.com/homedetails/11213/${comp.zpid}_zpid/?`}
                            variant="outline"
                            compact
                          >
                            Zillow
                          </Button>
                        </Flex>
                      </Popover.Dropdown>
                    </Popover>
                  </Marker>
                ))}
              </Map>
            </Box>

            <Box style={{ position: 'relative', width: '100%' }}>
              <ScrollArea w="100%" style={{ position: 'absolute' }}>
                <Table fontSize="xs" striped highlightOnHover mb="md">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Property</th>
                      <th>
                        <Flex gap="xs" align="center">
                          Price
                          <Tooltip
                            multiline
                            label="Sold price is based on the last listing price on non-disclosure states (e.g Texas)."
                          >
                            <IconInfoCircle size="1rem" />
                          </Tooltip>
                        </Flex>
                      </th>
                      <th>Sold</th>
                      <th>SQFT</th>
                      <th style={{ whiteSpace: 'nowrap' }}>Year built</th>
                      <th>Proximity</th>
                      <th>Confidence</th>
                      <th>-</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isCompsLoading
                      ? Array.from(Array(5).keys()).map((key) => (
                          <tr className={classes.tableRow} key={key}>
                            <td>
                              <Skeleton height={14} />
                            </td>
                            <td>
                              <Skeleton height={14} />
                            </td>
                            <td>
                              <Skeleton height={14} />
                            </td>
                            <td>
                              <Skeleton height={14} />
                            </td>
                            <td>
                              <Skeleton height={14} />
                            </td>
                            <td>
                              <Skeleton height={14} />
                            </td>
                            <td>
                              <Skeleton height={14} />
                            </td>
                            <td>
                              <Skeleton height={14} />
                            </td>
                            <td>
                              <Skeleton height={14} />
                            </td>
                          </tr>
                        ))
                      : (
                          comps?.sort(
                            (a, b) =>
                              a.property_comps[0].proximity -
                              b.property_comps[0].proximity,
                          ) || []
                        ).map((comparable) => (
                          <ComparableRow
                            key={
                              comparable.street_address + comparable.zip_code
                            }
                            property={property}
                            comparable={comparable}
                          />
                        ))}
                  </tbody>
                </Table>
              </ScrollArea>
            </Box>
          </Box>
        </Flex>
      </ScrollArea>
    </Box>
  );
}
