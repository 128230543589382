import { subDays } from 'date-fns';
import { create } from 'zustand';

export enum InsightsGranularity {
  OVERALL = 'Overall',
  INITIAL = 'Initial',
  FOLLOWUP = 'Follow up',
}

export interface InsightsState {
  campaignId: string;
  setCampaignId: (campaignIds: string) => void;
  dateRange: [Date | null, Date | null];
  setDateRange: (startDate: Date | null, endDate: Date | null) => void;
  granularity: InsightsGranularity;
  setGranularity: (granularity: InsightsGranularity) => void;
}

export const useInsightsStore = create<InsightsState>()((set) => ({
  campaignId: '',
  setCampaignId: (campaignId) => set({ campaignId }),
  dateRange: [subDays(new Date(), 7), new Date()],
  setDateRange: (startDate, endDate) =>
    set({ dateRange: [startDate, endDate] }),
  granularity: InsightsGranularity.OVERALL,
  setGranularity: (granularity) => set({ granularity }),
}));
