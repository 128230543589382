import React from 'react';
import { LeadAiSuggestions } from 'be/db';
import { Selectable } from 'kysely';
import { ConvoSuggestionSkeleton } from './ConvoSuggestionSkeleton';

interface ConvoStatusSuggestionProps {
  suggestion: Selectable<LeadAiSuggestions>;
}

export function MessageSuggestion({ suggestion }: ConvoStatusSuggestionProps) {
  return (
    <ConvoSuggestionSkeleton
      suggestion_id={suggestion.id}
      title="Send message to lead"
      description={suggestion.data}
      reasoning={suggestion.reasoning}
      callToActions={null}
    />
  );
}
