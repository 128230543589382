import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  NewWorkflowStep,
  Organisation,
  WorkflowStep,
  batchUploadCampaignAttachments,
  createWorkflowStep,
  deleteWorkflowStep,
  updateWorkflowStep,
} from 'shared/db';
import { supabase } from '../utils';
import { notifications } from '@mantine/notifications';
import { trpc } from '../setup';

export const useWorkflowSteps = (workflow_id: number) =>
  trpc.organization.getWorkflowSteps.useQuery({ workflow_id });

export const useCreateWorkflowStep = (workflowId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (step: NewWorkflowStep) =>
      createWorkflowStep(supabase, step),
    onSuccess: (data) => {
      queryClient.setQueriesData<WorkflowStep[]>(
        { queryKey: ['workflow_steps', workflowId] },
        (old_data) => [...(old_data || []), data],
      );
    },
  });
};

export const useUpdateWorkflowStep = (workflowId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (contract: WorkflowStep) =>
      updateWorkflowStep(supabase, contract),
    onSuccess: (data) => {
      queryClient.setQueriesData<WorkflowStep[]>(
        { queryKey: ['workflow_steps', workflowId] },
        (old_data) =>
          (old_data || []).map((c) => (c.id === data.id ? data : c)),
      );
    },
  });
};

export const useDeleteWorkflowStep = (workflowId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (contract_id: number) =>
      deleteWorkflowStep(supabase, contract_id),
    onSuccess: (_, contract_id) => {
      queryClient.setQueriesData<WorkflowStep[]>(
        { queryKey: ['workflow_steps', workflowId] },
        (old_data) => (old_data || []).filter((c) => c.id !== contract_id),
      );
    },
  });
};

export const useUploadWorkflowStepAttachment = (
  organisation: Organisation,
  workflowStep: WorkflowStep,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (files: File[]) => {
      const uploadedFilePaths = await batchUploadCampaignAttachments(
        supabase,
        files,
        organisation.id,
      );
      return updateWorkflowStep(supabase, {
        ...workflowStep,
        attachments: uploadedFilePaths,
      });
    },
    onError() {
      notifications.show({
        title: 'Error',
        message: 'Duplicated file name. Please rename your attachment.',
        color: 'red',
      });
    },
    onSuccess(data) {
      queryClient.setQueriesData<WorkflowStep[]>(
        { queryKey: ['workflow_steps', workflowStep.workflow_id] },
        (previous) =>
          (previous || []).map((template) =>
            template.id === data.id ? data : template,
          ),
      );
      notifications.show({
        title: 'Attachment uploaded',
        message: 'Attachment saved. You can close the modal now.',
      });
    },
  });
};

export const useDeleteWorkflowStepAttachment = (workflowStep: WorkflowStep) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (file: string) => {
      return updateWorkflowStep(supabase, {
        ...workflowStep,
        attachments: workflowStep.attachments.filter((path) => path !== file),
      });
    },
    onSuccess(data) {
      queryClient.setQueriesData<WorkflowStep[]>(
        { queryKey: ['workflow_steps', workflowStep.workflow_id] },
        (previous) => {
          notifications.show({
            title: 'Attachment deleted.',
            message: 'You will not see it once you close this modal.',
          });
          return (previous || []).map((template) =>
            template.id === data.id ? data : template,
          );
        },
      );
    },
  });
};
