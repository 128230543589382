import { SupabaseClient } from '@supabase/supabase-js';

export interface PricingStrategy {
  id: number;
  organisation_id: number;
  name: string;
  include_repair_costs: boolean;
  flat_discount: number;
  arv_pct_discount: number;
  description_target: string;
}

export type NewPricingStrategy = Omit<PricingStrategy, 'id'>;

export async function createPricingStrategy(
  supabase: SupabaseClient,
  pricingStrategy: Omit<PricingStrategy, 'id'>,
): Promise<PricingStrategy> {
  const { data, error } = await supabase
    .from('organisation_pricing_strategies')
    .insert(pricingStrategy)
    .select()
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't create contract");
  }

  return data;
}

export async function updatePricingStrategy(
  supabase: SupabaseClient,
  pricingStrategy: PricingStrategy,
) {
  const { error } = await supabase
    .from('organisation_pricing_strategies')
    .update(pricingStrategy)
    .eq('id', pricingStrategy.id);

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't update pricing strategy");
  }

  return pricingStrategy;
}

export async function fetchPricingStrategies(
  supabase: SupabaseClient,
  organisation_id: number,
): Promise<PricingStrategy[]> {
  const { data, error } = await supabase
    .from('organisation_pricing_strategies')
    .select()
    .eq('organisation_id', organisation_id);

  if (error) {
    console.log('error fetching pricing strategies: ', error);
    return [];
  }

  return data;
}

export async function deletePricingStrategy(
  supabase: SupabaseClient,
  contract_id: number,
) {
  const { error } = await supabase
    .from('organisation_pricing_strategies')
    .delete()
    .eq('id', contract_id);

  if (error) {
    console.log('error deleting contract: ', error);
  }
}
