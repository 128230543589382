import { TextInput, Text, SegmentedControl, Box } from "@mantine/core";
import { LocationsSelector } from "../LocationSelector/LocationSelector";
import { CampaignFormValues } from './useCampaignForm'
import { UseFormReturnType } from "@mantine/form";
import { Organisation, Products } from "shared/db";

interface CampaignsBasicsFormExtra {
    form: UseFormReturnType<CampaignFormValues>;
    organisation: Organisation;
    campaignSmart: string,
    setCampaignSmart: (value: string) => void,
}

export function CampaignsBasicsForm({
    form,
    organisation,
    campaignSmart,
    setCampaignSmart
}: CampaignsBasicsFormExtra) {
    if (!organisation) return null

    return (
        <Box>
            <Text fz="sm" mb="xs">Campaign Type</Text>
            <SegmentedControl
                value={campaignSmart}
                onChange={setCampaignSmart}
                fullWidth
                mb="sm"
                data={[
                    { label: 'Basic', value: 'basic' },
                    { label: 'Smart', value: 'smart' },
                ]}
            />

            <SegmentedControl
                fullWidth
                mb="sm"
                data={[
                    { label: 'Active listings', value: Products.LISTINGS },
                    { label: 'FSBO', value: Products.FSBO },
                    { label: 'Expired', value: Products.EXPIRED },
                ]}
                {...form.getInputProps('type')}
            />

            <TextInput
                mb="sm"
                withAsterisk
                label="Name"
                placeholder="Campaign name"
                {...form.getInputProps('name')}
            />

            <LocationsSelector
                organisationId={organisation!.id}
                inputProps={form.getInputProps('locations')}
                fetchCampaignLocations
            />
        </Box>
    )

}