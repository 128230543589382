import { forwardRef, useState } from 'react';
import { IconChevronRight, IconX } from '@tabler/icons-react';
import {
  Group,
  Avatar,
  Text,
  Menu,
  UnstyledButton,
  ScrollArea,
  TextInput,
  ActionIcon,
  Flex,
  Tooltip,
} from '@mantine/core';
import { useIsAdmin, useOrganisationStore, useUser } from 'fe/queries';
import classes from './OrganisationSwitch.module.scss';

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  image: string;
  name: string;
  email: string;
  icon?: React.ReactNode;
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ disabled, image, name, email, icon, ...others }: UserButtonProps, ref) => (
    <UnstyledButton
      ref={ref}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.md,
        color:
          theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        cursor: disabled ? 'default' : 'cursor',
        ...(!disabled && {
          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        }),
      })}
      {...others}
    >
      <Group>
        <Avatar src={image} radius="xl" />

        <div style={{ flex: 1, overflow: 'hidden' }}>
          <Text size="sm" weight={500}>
            {name}
          </Text>

          <Text color="dimmed" size="xs" truncate>
            {email}
          </Text>
        </div>

        {icon}
      </Group>
    </UnstyledButton>
  ),
);

export function OrganizationSwitch() {
  const { organisation, isLoading, organisations, setOrganisation } =
    useOrganisationStore();
  const { data: isAdmin } = useIsAdmin();
  const { data: user } = useUser();

  const [filterText, setFilterText] = useState('');

  const clearText = () => setFilterText('');

  if (isLoading || !organisation) return null;

  const filteredOrganisations = organisations.filter((org) =>
    org.name.toLowerCase().includes(filterText.toLowerCase()),
  );
  const sortedOrganisations = filteredOrganisations.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  if (!isAdmin) {
    return (
      <UserButton
        image="https://img.freepik.com/free-vector/illustration-user-avatar-icon_53876-5907.jpg?w=1060&t=st=1709739375~exp=1709739975~hmac=0f51736d08c2a5a2310ccc0b3232172737a6c56ce9a875ab2f45cc7033b02c63"
        name={organisation.name}
        email={user?.email || ''}
        icon={isAdmin && <IconChevronRight size="1rem" />}
        disabled
      />
    );
  }

  return (
    <Group position="center">
      <Menu withArrow>
        <Menu.Target>
          <UserButton
            image="https://img.freepik.com/free-vector/illustration-user-avatar-icon_53876-5907.jpg?w=1060&t=st=1709739375~exp=1709739975~hmac=0f51736d08c2a5a2310ccc0b3232172737a6c56ce9a875ab2f45cc7033b02c63"
            name={organisation.name}
            email={user?.email || ''}
            icon={isAdmin && <IconChevronRight size="1rem" />}
          />
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            closeMenuOnClick={false}
            className={classes.searchItem}
            key="search"
          >
            <Flex align="flex-end" gap="xs">
              <TextInput
                label="Filter"
                style={{ flex: 1 }}
                onChange={(e) => setFilterText(e.target.value)}
              />
              <Tooltip label="Clear search">
                <ActionIcon
                  onClick={clearText}
                  size="lg"
                  variant="outline"
                  component="a"
                >
                  <IconX size="1.125rem" />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Menu.Item>
          <ScrollArea h={250}>
            {sortedOrganisations.map((org) => (
              <Menu.Item
                key={org.id}
                onClick={() => setOrganisation(org)}
                icon={
                  <Avatar
                    src="https://img.freepik.com/free-vector/illustration-user-avatar-icon_53876-5907.jpg?w=1060&t=st=1709739375~exp=1709739975~hmac=0f51736d08c2a5a2310ccc0b3232172737a6c56ce9a875ab2f45cc7033b02c63"
                    radius="xl"
                  />
                }
              >
                {org.name}
              </Menu.Item>
            ))}
          </ScrollArea>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
