import { InboxItem, Products } from 'shared/db';
import { create } from 'zustand';

interface InboxState {
  activeInboxItem: InboxItem | null;
  setActiveInboxItem: (item: InboxItem) => void;
  markAllMessagesAsSeen: () => void;
  filterLeadStatus: string[];
  setFilterLeadStatus: (status: string[]) => void;
  filterCampaigns: number[];
  setFilterCampaigns: (campaigns: number[]) => void;
  filterCampaignType: Products | '';
  setFilterCampaignType: (campaignType: Products) => void;
  filterSearchTerm: string;
  setFilterSearchTerm: (searchTerm: string) => void;
}

export const useInboxStore = create<InboxState>()((set, get) => {
  return {
    activeInboxItem: null,
    setActiveInboxItem: (item: InboxItem) => set({ activeInboxItem: item }),
    markAllMessagesAsSeen: async () => {
      const activeInboxItem = get().activeInboxItem;
      const copyOfItem = { ...activeInboxItem! };
      copyOfItem.num_unseen = 0;
      set({ activeInboxItem: copyOfItem });
    },
    filterLeadStatus: [],
    setFilterLeadStatus: (status: string[]) =>
      set({ filterLeadStatus: status }),
    filterCampaigns: [],
    setFilterCampaigns: (campaigns: number[]) =>
      set({ filterCampaigns: campaigns }),
    filterCampaignType: '',
    setFilterCampaignType: (campaignType: Products) =>
      set({ filterCampaignType: campaignType }),
    filterSearchTerm: '',
    setFilterSearchTerm: (searchTerm: string) =>
      set({ filterSearchTerm: searchTerm }),
  };
});
