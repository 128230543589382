import { Flex, Title } from '@mantine/core';
import { ResponseInsightsCards } from './ResponseInsightsCards';
// import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Tooltip, Pie, PieChart, Legend } from 'recharts';
import { useInsightsStore, useOrganisationStore, useResponseInsights } from 'fe/queries';

export function ResponseInsights() {
    const { organisation } = useOrganisationStore();
    if (!organisation) {
        return null
    }
    const params = useInsightsStore((state) => ({
        campaignId: state.campaignId,
        dateRange: state.dateRange,
        granularity: state.granularity,
    }))
    const { data, isLoading } = useResponseInsights(organisation.id, params)
    return (
        <Flex gap="lg" direction="column">
            <Title order={2}>Response insights</Title>

            <ResponseInsightsCards data={data?.quickData || []} loading={isLoading} />
            {/* 
            <Flex mt="xl" justify="flex-start">
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart width={400} height={250} margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}>
                        <Pie
                            dataKey="value"
                            isAnimationActive={false}
                            data={data?.responseSentimentTypes}
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            fill="#8884d8"
                            label
                            labelLine={false}
                        />

                        <Tooltip />
                        <Legend margin={{ top: 100 }} />
                    </PieChart>
                </ResponsiveContainer>

                <ResponsiveContainer width="100%" height={300}>
                    <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data?.responsePropertyTypes}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="subject" />
                        <PolarRadiusAxis />
                        <Radar name="Mike" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                    </RadarChart>
                </ResponsiveContainer>
            </Flex> */}
        </Flex>
    );
}
