import {
  archiveOrganization,
  Organisation,
  updateOrganisation,
} from 'shared/db';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { callApi, supabase } from '../utils';
import {
  createOrganizationEndpoint,
  createOrganizationParamsSchema,
} from 'shared/api';
import { z } from 'zod';

export enum Products {
  LISTINGS = 'listings',
  FSBO = 'fsbo',
  EXPIRED = 'expired',
  COMMERCIAL = 'commercial',
}

export const useUpdateOrganisation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (organisation: Partial<Organisation>) =>
      updateOrganisation(supabase, organisation),
    onSuccess: (data) => {
      queryClient.setQueryData(['organisation'], data);
      queryClient.setQueriesData<Organisation[]>(
        { queryKey: ['organisations'] },
        (previous) =>
          previous?.map((prevCampaign) =>
            prevCampaign.id === data.id ? data : prevCampaign,
          ) || [],
      );
    },
  });
};

export const useArchiveOrganisation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (organisation: Organisation) => {
      return archiveOrganization(supabase, organisation);
    },
    onSuccess: (data) => {
      queryClient.setQueriesData<Organisation[]>(
        { queryKey: ['organisations'] },
        (previous) =>
          previous?.filter((prevCampaign) => prevCampaign.id !== data.id) || [],
      );
    },
  });
};

// Create

export async function createOrganisation(
  organisation: Omit<
    Organisation,
    'id' | 'products' | 'disable_email_forwarding'
  >,
): Promise<Organisation> {
  const { data, error } = await supabase
    .from('organisations')
    .insert(organisation)
    .select()
    .single();

  if (error) {
    throw new Error("Couldn't create organisation");
  }

  return data;
}

export const useCreateOrganisation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: z.infer<typeof createOrganizationParamsSchema>) =>
      callApi(createOrganizationEndpoint, null, params),
    onSuccess: (data) => {
      queryClient.setQueryData(['organisation'], data);
      queryClient.setQueriesData<Organisation[]>(
        { queryKey: ['organisations'] },
        (previous) => [...(previous || []), data],
      );
    },
  });
};

// Upload signature image

async function uploadOrganisationSignature(
  attachment: File,
  organisation: Organisation,
) {
  const { data, error } = await supabase.storage
    .from('signatures')
    .upload(`${organisation.id}-${attachment.name}`, attachment, {
      upsert: true,
    });

  if (error) {
    console.log('error', error);
    throw new Error("Couldn't upload organisation signature attachment");
  }

  const { data: url } = supabase.storage
    .from('signatures')
    .getPublicUrl(data.path);

  return url.publicUrl || '';
}

export const useUploadOrganisationSignature = (organisation: Organisation) =>
  useMutation({
    mutationFn: (attachment: File) =>
      uploadOrganisationSignature(attachment, organisation),
  });
