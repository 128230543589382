import { Button, Group, Text, Box } from '@mantine/core';
import { useNavigate } from '@tanstack/react-router';
import { useArchiveCampaign } from 'fe/queries';
import { Campaign } from 'shared/db';

interface CampaignFormProps {
  campaign: Campaign;
  onClose: () => void;
  smallMode?: boolean;
}

export function DeleteCampaignConfirmation({
  campaign,
  onClose,
}: CampaignFormProps) {
  const { mutateAsync: deleteCampaign, isPending: isDeleteLoading } =
    useArchiveCampaign();
  const navigate = useNavigate();

  const onClickDeleteCampaign = async () => {
    await deleteCampaign(campaign);
    onClose();
    navigate({ to: '/', search: () => ({}) });
  };

  return (
    <Box>
      <Text>
        Are you sure you want to archive your campaign:
        <Text fw={700}>{campaign?.name}</Text>
      </Text>
      <Group position="right" mt="xl">
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <Button
          color="red"
          loading={isDeleteLoading}
          onClick={onClickDeleteCampaign}
        >
          Archive
        </Button>
      </Group>
    </Box>
  );
}
