import { ActionIcon, Button, Flex, Popover, Textarea, } from "@mantine/core";
import { useUpdatePropertyComp } from "fe/queries";
import { IconX } from "@tabler/icons-react";
import { PropertyComp } from "shared/db";
import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";

interface PropertyInfoProps {
    comparable: PropertyComp;
}

export function ComparableAction({
    comparable,
}: PropertyInfoProps) {
    const [rejectionReason, setRejectionReason] = useState('')
    const [openedPopover, { toggle }] = useDisclosure(false);

    const { mutateAsync: updatePropertyComp, isPending } = useUpdatePropertyComp()

    const hide = async () => {
        await updatePropertyComp({
            ...comparable,
            negative_feedback: rejectionReason ? rejectionReason : 'Bad comp',
        })
        setRejectionReason('')
        toggle()
    }

    return (
        <Popover opened={openedPopover} onChange={toggle} withinPortal>
            <Popover.Target>
                <ActionIcon variant="outline" color="red" onClick={toggle} size="xs">
                    <IconX />
                </ActionIcon>
            </Popover.Target>

            <Popover.Dropdown>
                <Flex direction="column" gap="lg">
                    <Textarea
                        w={250}
                        value={rejectionReason}
                        onChange={event => setRejectionReason(event.currentTarget.value)}
                        label="Why are you rejecting this comparable? (Optional)"
                        placeholder="Your comment"
                        minRows={4}
                        size="sm"
                    />
                    <Button
                        color="red"
                        fullWidth
                        compact
                        loading={isPending}
                        onClick={hide}
                    >
                        Remove comparable
                    </Button>
                </Flex>
            </Popover.Dropdown>
        </Popover>

    )
}