import { StatusOfCampaignProperty } from 'shared/db';
import { ApiEndpoint } from './types';
import { z } from 'zod';

const fetchLeadContractRouteSchema = z.object({
  lead_id: z.number(),
});

export const fetchLeadContractEndpoint: ApiEndpoint<
  typeof fetchLeadContractRouteSchema
> = {
  apiUrl: '/lead/:lead_id/contract',
  clientUrl: ({ lead_id }) => `/lead/${lead_id}/contract`,
  method: 'GET',
};

const fetchLeadInboxRouteSchema = z.object({
  lead_id: z.number(),
});

export const fetchLeadInboxEndpoint: ApiEndpoint<
  typeof fetchLeadInboxRouteSchema
> = {
  apiUrl: '/lead/:lead_id/inbox',
  clientUrl: ({ lead_id }) => `/lead/${lead_id}/inbox`,
  method: 'GET',
};

const analyzeLeadInboxRouteSchema = z.object({
  lead_id: z.number(),
});

export const analyzeLeadInboxEndpoint: ApiEndpoint<
  typeof analyzeLeadInboxRouteSchema
> = {
  apiUrl: '/lead/:lead_id/analyzeInbox',
  clientUrl: ({ lead_id }) => `/lead/${lead_id}/analyzeInbox`,
  method: 'POST',
};

const postLeadAiFeedbackRouteSchema = z.object({
  suggestion_id: z.number(),
});

export const postLeadAiFeedbackRouteParams = z.object({
  approved: z.boolean(),
});

export const postLeadAiFeedbackEndpoint: ApiEndpoint<
  typeof postLeadAiFeedbackRouteSchema,
  typeof postLeadAiFeedbackRouteParams
> = {
  apiUrl: '/lead_ai_suggestions/:suggestion_id/feedback',
  clientUrl: ({ suggestion_id }) =>
    `/lead_ai_suggestions/${suggestion_id}/feedback`,
  method: 'POST',
  schema: postLeadAiFeedbackRouteParams,
};

const updateLeadRouteSchema = z.object({
  lead_id: z.number(),
});

export const updateLeadRouteParams = z.object({
  status: z.nativeEnum(StatusOfCampaignProperty),
});

export const updateLeadEndpoint: ApiEndpoint<
  typeof updateLeadRouteSchema,
  typeof updateLeadRouteParams
> = {
  apiUrl: '/lead/:lead_id',
  clientUrl: ({ lead_id }) => `/lead/${lead_id}`,
  method: 'PUT',
  schema: updateLeadRouteParams,
};
