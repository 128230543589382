import classes from './LogoLoader.module.scss';

export function LogoLoader() {
  return (
    <svg
      className={classes.logo}
      width={39 * 2}
      height={42 * 2}
      viewBox="0 0 39 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3031_6115)">
        <path
          d="M15.3672 35.3532L25.1796 41H22.8441L13.6931 35.7343V30.0049L23.4376 24.3978L23.9389 24.1093V23.531V20.2957V19.7174L23.4376 19.429L19.9011 17.394L19.4024 17.1069L18.9036 17.394L9.0209 23.0814L8.51969 23.3698V23.9481V41H7.93299H6.34629H3.17289H1.46911H1V19.0479L6.84507 15.6839L6.8451 15.6839L10.0184 13.8577L10.0185 13.8577L19.4024 8.45793L31.4586 15.3955V28.4316L25.2505 32.0033L23.7441 32.87L25.2504 33.7368L28.4238 35.563L28.4239 35.5631L31.5971 37.3887L31.5973 37.3888L35.1331 39.4242L36.632 40.287V38.5575V34.487V30.8351V12.991V12.4127L36.1308 12.1243L19.9012 2.7851L19.4024 2.49807L18.9036 2.78513L9.02112 8.47285L9.02087 8.47299L5.84772 10.2981L5.84744 10.2982L1 13.0883V11.7437L6.84504 8.38027L6.84507 8.38026L19.4024 1.15376L37.8048 11.7432V22.3307V32.6608V40.3832V41H35.632H35.5356L29.4214 37.482L29.4213 37.4819L21.4078 32.8701L29.784 28.05L30.2852 27.7616V27.1833V16.6434V16.0651L29.784 15.7767L19.9012 10.0893L19.4024 9.80223L18.9036 10.0893L9.02091 15.7767L9.0209 15.7767L5.84753 17.6029L5.84751 17.6029L2.67413 19.429L2.17289 19.7174V20.2957V34.6953V38.8375V39.8375H3.17289H6.34629H7.34629V38.8375V36.5215V22.7007L10.0185 21.1629L10.0185 21.1629L19.4025 15.7621L25.1123 19.0473V24.7793L18.9037 28.3515L18.9037 28.3515L15.3673 30.386L14.866 30.6744V31.2528V34.4865V35.0648L15.3672 35.3532Z"
          stroke="#00D6B9"
          strokeWidth={2}
          className="svg-elem-1"
        />
      </g>

      <defs>
        <clipPath id="clip0_3031_6115">
          <rect
            width={39 * 2}
            height={42 * 2}
            fill="white"
            className="svg-elem-2"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
