import {
  ActionIcon,
  Flex,
  NumberInput,
  SegmentedControl,
  Skeleton,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconCurrencyDollar,
  IconInfoCircle,
  IconPencil,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import classes from './CustomSelector.module.scss';
import React from 'react';
import { Drawer } from '../Drawer/Drawer';

interface OfferSettingsProps {
  value: number;
  onChange: (newVal: number) => void;
  label: string;
  placeholder: string;
  tooltip?: string;
  options: {
    label: string;
    value: number;
  }[];
  defaultValue: string;
  step: number;
  popover?: React.ReactNode;
  id?: number | string | null;
  loading?: boolean;
}

export function CustomSelector({
  value,
  onChange,
  label,
  placeholder,
  tooltip,
  options,
  step,
  defaultValue,
  popover,
  id,
  loading,
}: OfferSettingsProps) {
  const [type, setOfferType] = useState(defaultValue);
  const computedValue =
    options.find((option) => option.label === type)?.value || 0;

  useEffect(() => {
    if (type !== 'custom') {
      if (computedValue > 9999) {
        onChange(computedValue);
      } else {
        onChange(10000);
      }
    }
  }, [computedValue]);

  useEffect(() => {
    setOfferType(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (id) {
      setOfferType(defaultValue);
    }
  }, [id]);

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Flex direction="column" gap={4}>
      <Drawer
        opened={opened}
        onClose={close}
        classNames={{
          content: classes.drawer,
          header: classes.drawer,
        }}
      >
        {popover}
      </Drawer>

      {loading ? (
        <Skeleton height={54.797} />
      ) : (
        <NumberInput
          value={value}
          placeholder={placeholder}
          onChange={(value) => {
            setOfferType('custom');
            onChange(Number(value));
          }}
          min={0}
          label={
            <Flex align="center" justify="space-between" gap="xs">
              <span>{label}</span>

              {tooltip && (
                <Tooltip multiline label={tooltip}>
                  <IconInfoCircle size="1rem" />
                </Tooltip>
              )}
            </Flex>
          }
          size="xs"
          step={step}
          icon={<IconCurrencyDollar size="1rem" />}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
        />
      )}
      <Flex align="center">
        <SegmentedControl
          value={type}
          onChange={setOfferType}
          fullWidth
          data={[
            ...options.map((option) => ({
              label: option.label,
              value: option.label,
            })),
            { label: 'Custom', value: 'custom' },
          ]}
          style={{ flex: 1 }}
          size="xs"
        />
        {popover && (
          <ActionIcon onClick={open}>
            <IconPencil size="1rem" />
          </ActionIcon>
        )}
      </Flex>
    </Flex>
  );
}
