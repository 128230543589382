import {
  OrganisationBlacklistedDomain,
  createBlacklistedDomain,
  deleteBlacklistedDomain,
  fetchBlacklistedDomains,
} from 'shared/db';
import { supabase } from '../utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const BLACKLISTED_DOMAINS_KEY = 'blacklisted_domains';

export const useBlacklistedDomains = (organisation_id: number) =>
  useQuery({
    queryKey: [BLACKLISTED_DOMAINS_KEY, organisation_id],
    queryFn: () => fetchBlacklistedDomains(supabase, organisation_id),
  });

export const useBlacklistDomain = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (domain: string) =>
      createBlacklistedDomain(supabase, {
        domain,
        organisation_id: organisationId,
      }),
    onSuccess: (data) => {
      queryClient.setQueriesData<OrganisationBlacklistedDomain[]>(
        { queryKey: [BLACKLISTED_DOMAINS_KEY] },
        (previous) => [...(previous || []), data],
      );
    },
  });
};

export const useDeleteBlacklistedDomain = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (domainId: number) =>
      deleteBlacklistedDomain(supabase, domainId),
    onSuccess: (domainId) => {
      queryClient.setQueriesData<OrganisationBlacklistedDomain[]>(
        { queryKey: [BLACKLISTED_DOMAINS_KEY] },
        (previous) =>
          (previous || []).filter((domain) => domain.id !== domainId),
      );
    },
  });
};
