import { ApiEndpoint } from './types';
import { z } from 'zod';

// For all
export const analyzePropertyRouteSchema = z.object({
  organization_id: z.number(),
});

export const analyzePropertyInfoParamsSchema = z.object({
  address: z.string().min(1),
});

export const analyzePropertyInfoEndpoint: ApiEndpoint<
  typeof analyzePropertyRouteSchema,
  typeof analyzePropertyInfoParamsSchema
> = {
  apiUrl: '/organizations/:organization_id/analyzePropertyInfo',
  clientUrl: ({ organization_id }) =>
    `/organizations/${organization_id}/analyzePropertyInfo`,
  method: 'POST',
  schema: analyzePropertyInfoParamsSchema,
};

export const analyzePropertyParamsSchema = z.object({
  street_address: z.string().min(1),
  zip_code: z.number().positive(),
});

export const analyzeComparablesEndpoint: ApiEndpoint<
  typeof analyzePropertyRouteSchema,
  typeof analyzePropertyParamsSchema
> = {
  apiUrl: '/organizations/:organization_id/analyzeComparables',
  clientUrl: ({ organization_id }) =>
    `/organizations/${organization_id}/analyzeComparables`,
  method: 'POST',
  schema: analyzePropertyParamsSchema,
};

export const analyzeConditionEndpoint: ApiEndpoint<
  typeof analyzePropertyRouteSchema,
  typeof analyzePropertyParamsSchema
> = {
  apiUrl: '/organizations/:organization_id/analyzeCondition',
  clientUrl: ({ organization_id }) =>
    `/organizations/${organization_id}/analyzeCondition`,
  method: 'POST',
  schema: analyzePropertyParamsSchema,
};

export const analyzeContactEndpoint: ApiEndpoint<
  typeof analyzePropertyRouteSchema,
  typeof analyzePropertyParamsSchema
> = {
  apiUrl: '/organizations/:organization_id/analyzeContact',
  clientUrl: ({ organization_id }) =>
    `/organizations/${organization_id}/analyzeContact`,
  method: 'POST',
  schema: analyzePropertyParamsSchema,
};
