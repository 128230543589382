import { SupabaseClient } from '@supabase/supabase-js';

export async function uploadCampaignAttachment(
  supabase: SupabaseClient,
  attachment: File,
  organisationId: number,
) {
  const { data, error } = await supabase.storage
    .from('campaign_attachments')
    .upload(`private/${organisationId}/${attachment.name}`, attachment, {
      upsert: true,
    });

  if (error) {
    console.log('error', error);
    throw new Error("Couldn't upload campaign attachment");
  }

  return data.path || '';
}
export async function batchUploadCampaignAttachments(
  supabase: SupabaseClient,
  attachments: File[],
  organisationId: number,
) {
  return await Promise.all(
    attachments.map((file) =>
      uploadCampaignAttachment(supabase, file, organisationId),
    ),
  );
}
