import { ApiEndpoint } from '../types';
import { z } from 'zod';
import { propertySchema } from 'shared/db';

export const scheduleOfferRouteSchema = z.object({
  organization_id: z.number(),
  workflow_id: z.number().positive(),
});

export const scheduleOfferParamsSchema = z.object({
  street_address: z.string().min(1),
  zip_code: z.number().positive(),
  contract_id: z.number().positive(),
  offer_price: z.number().positive(),
  subject: z.string().min(1),
  content: z.string().min(1),
  email: z.string().min(1),
  name: z.string().min(1),
});

export const organizationScheduleOfferEndpoint: ApiEndpoint<
  typeof scheduleOfferRouteSchema,
  typeof scheduleOfferParamsSchema
> = {
  apiUrl: '/organizations/:organization_id/workflows/:workflow_id/scheduleSend',
  clientUrl: ({ organization_id, workflow_id }) =>
    `/organizations/${organization_id}/workflows/${workflow_id}/scheduleSend`,
  method: 'POST',
  schema: scheduleOfferParamsSchema,
};

const calculateCompScoreRouteSchema = z.object({
  organization_id: z.number(),
});

const calculateCompScoreParamsSchema = z.object({
  subjectProperty: propertySchema,
  comparableProperty: propertySchema,
});

export const organizationCalculateCompScoreEndpoint: ApiEndpoint<
  typeof calculateCompScoreRouteSchema,
  typeof calculateCompScoreParamsSchema
> = {
  apiUrl: '/organizations/:organization_id/comp_score',
  clientUrl: ({ organization_id }) =>
    `/organizations/${organization_id}/comp_score`,
  method: 'POST',
  schema: calculateCompScoreParamsSchema,
};

const fetchOrgContractsRouteSchema = z.object({
  organization_id: z.number(),
});

export const organizationFetchContractsEndpoint: ApiEndpoint<
  typeof fetchOrgContractsRouteSchema
> = {
  apiUrl: '/organizations/:organization_id/contracts',
  clientUrl: ({ organization_id }) =>
    `/organizations/${organization_id}/contracts`,
  method: 'GET',
};

//
export const fetchInboxRouteSchema = z.object({
  organization_id: z.number(),
});

export const fetchInboxParamsSchema = z.object({
  search_term: z.string().nullable(),
  lead_status: z.array(z.string()).nullable(),
  campaign_ids: z.array(z.number().positive()).nullable(),
  campaign_type: z.string().nullable(),
  latest_property_message_id: z.number(),
});

export const organizationFetchInboxEndpoint: ApiEndpoint<
  typeof fetchInboxRouteSchema,
  typeof fetchInboxParamsSchema
> = {
  apiUrl: '/organizations/:organization_id/inbox',
  clientUrl: ({ organization_id }) => `/organizations/${organization_id}/inbox`,
  method: 'POST',
  schema: fetchInboxParamsSchema,
};

//

export const inviteMemberRouteSchema = z.object({
  organization_id: z.number(),
});
export const inviteMemberParamsSchema = z.object({
  email: z.string(),
});

export const inviteMemberEndpoint: ApiEndpoint<
  typeof inviteMemberRouteSchema,
  typeof inviteMemberParamsSchema
> = {
  apiUrl: '/organizations/:organization_id/members',
  clientUrl: ({ organization_id }) =>
    `/organizations/${organization_id}/members`,
  method: 'POST',
  schema: inviteMemberParamsSchema,
};

export const fetchMembersRouteSchema = z.object({
  organization_id: z.number(),
});

export const fetchMembersEndpoint: ApiEndpoint<typeof fetchMembersRouteSchema> =
  {
    apiUrl: '/organizations/:organization_id/members',
    clientUrl: ({ organization_id }) =>
      `/organizations/${organization_id}/members`,
    method: 'GET',
  };

const createOrganizationRouteSchema = z.null();

export const createOrganizationParamsSchema = z.object({
  name: z.string(),
  from_email: z.string().email(),
  from_name: z.string(),
  owner_email: z.string().email(),
});

export const createOrganizationEndpoint: ApiEndpoint<
  typeof createOrganizationRouteSchema,
  typeof createOrganizationParamsSchema
> = {
  apiUrl: '/organizations',
  clientUrl: () => `/organizations`,
  method: 'POST',
  schema: createOrganizationParamsSchema,
};

//

const sendEmailRouteSchema = z.object({
  organization_id: z.number(),
});

export const sendEmailParamsSchema = z.object({
  street_address: z.string().min(1),
  zip_code: z.number().positive(),
  content: z.string().min(1),
});

export const sendEmailEndpoint: ApiEndpoint<
  typeof sendEmailRouteSchema,
  typeof sendEmailParamsSchema
> = {
  apiUrl: '/organizations/:organization_id/sendEmail',
  clientUrl: ({ organization_id }) =>
    `/organizations/${organization_id}/sendEmail`,
  method: 'POST',
  schema: sendEmailParamsSchema,
};
