import { SupabaseClient } from '@supabase/supabase-js';

export interface OrganisationBlacklistedEmail {
  id: number;
  email: string;
  organisation_id: number;
}

export type NewBlacklistedEmail = Omit<OrganisationBlacklistedEmail, 'id'>;

const TABLE_NAME = 'organisation_blacklisted_emails';

export async function fetchBlacklistedEmails(
  supabase: SupabaseClient,
  organisationId: number,
): Promise<OrganisationBlacklistedEmail[]> {
  const { data, error } = await supabase
    .from(TABLE_NAME)
    .select('*')
    .eq('organisation_id', organisationId);

  if (error) {
    console.error(error);
    throw new Error(
      `Error fetching blacklisted domains for id: ${organisationId}`,
    );
  }

  return data;
}

export async function deleteBlacklistedEmail(
  supabase: SupabaseClient,
  domainId: number,
): Promise<OrganisationBlacklistedEmail['id']> {
  const { error } = await supabase.from(TABLE_NAME).delete().eq('id', domainId);

  if (error) {
    throw new Error("Couldn't delete blacklisted email");
  }

  return domainId;
}

export async function createBlacklistedEmail(
  supabase: SupabaseClient,
  blacklistedDomain: NewBlacklistedEmail,
): Promise<OrganisationBlacklistedEmail> {
  const { data, error } = await supabase
    .from(TABLE_NAME)
    .insert(blacklistedDomain)
    .select()
    .single();

  if (error) {
    throw new Error("Couldn't blacklist email");
  }

  return data;
}
