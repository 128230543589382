import { PageLoader } from 'fe/ui/shared';
import React, { useEffect } from 'react';
import { useOrganisationStore, useUser } from 'fe/queries';
import { LoginPage } from './LoginPage';
import { useMatchRoute } from '@tanstack/react-router';
import { usePostHog } from 'posthog-js/react';

interface LoginProps {
  children: React.ReactNode;
}

export function AuthWrapper({ children }: LoginProps) {
  const { data: user, isLoading } = useUser();
  const { organisation } = useOrganisationStore();

  const matchRoute = useMatchRoute();
  const bypass =
    matchRoute({ to: '/terms-of-service' }) ||
    matchRoute({ to: '/privacy-policy' }) ||
    matchRoute({ to: '/service-agreement' });

  const posthog = usePostHog();
  useEffect(() => {
    if (!user || !organisation || !posthog) {
      return;
    }
    if (
      user.email === 'ruben@heyrafi.com' ||
      user.email === 'jarod@heyrafi.com' ||
      user.email === 'kenneth@heyrafi.com'
    ) {
      posthog.opt_out_capturing();
      return;
    }
    posthog?.identify(user.id, {
      email: user.email,
    });
    posthog?.group('company', String(organisation.id), {
      name: organisation.name,
    });
  }, [posthog, user?.id, organisation?.id]);

  if (bypass) {
    return children;
  }

  if (isLoading) {
    return <PageLoader noMenu />;
  }

  if (!user) {
    return <LoginPage />;
  } else {
    return children;
  }
}
