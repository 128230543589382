import { useState, useEffect } from 'react';
import { ActionIcon, Box, Flex, ScrollArea } from '@mantine/core';
import {
  useDeleteOrganisationContract,
  useIsAdmin,
  useOrganisationContracts,
  useOrganisationStore,
} from 'fe/queries';
import { OrganisationContract } from 'shared/db';
import { Typography } from 'fe/ui/shared';
import { IconPlus } from '@tabler/icons-react';
import { ContractForm } from './ContractForm';
import { modals } from '@mantine/modals';
import { ContractCard } from './ContractCard';
import { ContractFields } from './ContractFields';

interface ContractManagementProps {
  title?: string;
  onChange?: (value: number | null) => void;
  isLoadingChange?: boolean;
  selectedValue?: number | null;
  hideFields?: boolean;
}

export function ContractManagement({
  title = 'Contracts',
  onChange,
  isLoadingChange,
  selectedValue,
  hideFields = false,
}: ContractManagementProps) {
  const { organisation } = useOrganisationStore();
  if (!organisation) return null;

  const [internalValue, setInternalValue] = useState<number | null>(
    selectedValue || null,
  );

  useEffect(() => {
    setInternalValue(selectedValue || null);
  }, [selectedValue]);

  const { data: isAdmin } = useIsAdmin();
  const { data: contracts } = useOrganisationContracts(organisation.id);
  const { mutateAsync: deleteContract, isPending: isDeleting } =
    useDeleteOrganisationContract(organisation.id);

  const open = (contract?: OrganisationContract) => {
    modals.open({
      title: contract ? 'Update contract' : 'Create contract',
      children: (
        <ContractForm onSuccess={() => modals.closeAll()} contract={contract} />
      ),
    });
  };

  const handleContractClick = (contractId: number) => {
    if (onChange) {
      const newValue = internalValue === contractId ? null : contractId;
      setInternalValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <Flex display="flex" h="100%" py="md">
      <Flex px="xl" maw={800} w="100%" direction="column">
        <Flex align="center" justify="space-between">
          <Typography variant="label">{title}</Typography>
          {isAdmin && (
            <ActionIcon variant="filled" color="brand" onClick={() => open()}>
              <IconPlus size="1.125rem" />
            </ActionIcon>
          )}
        </Flex>

        <Box style={{ flex: 1, position: 'relative' }}>
          <ScrollArea
            style={{
              flex: 1,
              position: 'absolute',
              height: '100%',
              width: '100%',
            }}
          >
            <Flex mt="xl" direction="column" gap="md" pr="xs">
              {contracts?.map((contract) => (
                <ContractCard
                  key={contract.id}
                  contract={contract}
                  onEdit={open}
                  onDelete={deleteContract}
                  isDeleting={isDeleting}
                  isSelected={internalValue === contract.id}
                  onChange={() => handleContractClick(contract.id)}
                  isLoadingChange={isLoadingChange}
                />
              ))}
            </Flex>
          </ScrollArea>
        </Box>
      </Flex>

      {isAdmin && !hideFields && (
        <Box w="40%" px="xl">
          <ContractFields />
        </Box>
      )}
    </Flex>
  );
}
