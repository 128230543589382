import { Box, Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  useOrganisationStore,
  useUploadOrganisationSignature
} from 'fe/queries';
import { Organisation } from 'shared/db';
import { StyledEditor } from 'fe/ui/shared';

interface SignatureEditorProps {
  organisation: Organisation;
}

export function SignatureEditor({ organisation }: SignatureEditorProps) {
  const { updateOrganisation, isUpdating } = useOrganisationStore();
  const { mutateAsync: uploadOrganisationSignature } =
    useUploadOrganisationSignature(organisation);

  const saveNewSignature = async (content: string) => {
    await updateOrganisation({
      ...organisation,
      email_signature: content
    });
    notifications.show({
      title: 'Signature saved',
      message: `The new signature for your organization: "${organisation.name}", has been saved successfully!`
    });
  };

  const handleImageUpload = async (file: File): Promise<string> => {
    return await uploadOrganisationSignature(file);
  };

  console.log('email signature: ', organisation?.email_signature);
  return (
    <Box w="100%">
      <StyledEditor
        initialContent={organisation?.email_signature || ''}
        uploadImage={handleImageUpload}
        buttons={({ content }) => (
          <Button
            mt="xl"
            mb="md"
            loading={isUpdating}
            onClick={() => saveNewSignature(content)}
          >
            Save Signature
          </Button>
        )}
      />
    </Box>
  );
}
