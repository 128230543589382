import {
  Property,
  PropertyStatus,
  StatusOfCampaignPropertyLabels,
} from 'shared/db';
import { PropertyPriceHistory } from 'be/db';
import { Selectable } from 'kysely';

export function mergeProperties(base: Property, overlay: Property): Property {
  return Object.keys(overlay).reduce(
    (merged, key) => {
      const typedKey = key as keyof Property;
      const overlayValue = overlay[typedKey];
      if (overlayValue !== null) {
        return { ...merged, [typedKey]: overlayValue };
      }
      return merged;
    },
    { ...base },
  );
}

export const getHighestCompPrice = (comps: Property[]) => {
  return Math.max(...comps.map((comp) => comp.price));
};

export const formatPropertyStatusToLabel = (status: PropertyStatus) => {
  switch (status) {
    case PropertyStatus.RECENTLY_SOLD:
      return 'Recently Sold';
    case PropertyStatus.OFF_MARKET:
      return 'Off Market';
    case PropertyStatus.FOR_SALE:
    default:
      return 'Active';
  }
};

export const findLastSoldPrice = (
  priceChanges: Selectable<PropertyPriceHistory>[] | undefined,
) => {
  return priceChanges?.find((priceChange) => priceChange.event_name === 'Sold');
};

export const formatStatusToLabel = (status: string) => {
  return (
    StatusOfCampaignPropertyLabels.find((label) => label.value === status)
      ?.label || status
  );
};

export const findStatusKey = (statusLabel: string) =>
  StatusOfCampaignPropertyLabels.find((label) => label.label === statusLabel)
    ?.value;
