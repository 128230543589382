import { parseFullName } from 'parse-full-name';
import { Property, WorkflowStep, Location } from 'shared/db';
import { formatPropertyAddress } from './formatters';
import Handlebars from 'handlebars';

export const getDomainFromEmail = (email: string) => email.split('@').pop();

interface GetFormattedEmailParams {
  property: Property;
  workflowStep: WorkflowStep;
  offerPrice: number;
  location: Location;
}

export const getFormattedEmail = ({
  property,
  workflowStep,
  offerPrice,
  location,
}: GetFormattedEmailParams) => {
  const parsedAgentName = parseFullName(property.agent_name || '');
  const templateVariables = {
    name: property?.agent_name,
    address: property ? formatPropertyAddress(property, location) : '',
    offerPrice: offerPrice.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
    firstName: parsedAgentName.first,
  };

  const subjectTemplate = Handlebars.compile(workflowStep.subject);
  const contentTemplate = Handlebars.compile(workflowStep.content);

  return {
    subject: subjectTemplate(templateVariables),
    content: contentTemplate(templateVariables),
  };
};
