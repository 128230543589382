import { Button, Flex, ThemeIcon, Modal, TextInput } from '@mantine/core';
import {
  useAnalyzeInbox,
  useForwardConversation,
  useIsAdmin,
} from 'fe/queries';
import React from 'react';
import {
  IconArrowBack,
  IconArrowRight,
  IconSparkles,
} from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useForm, isNotEmpty } from '@mantine/form';
import { InboxItem } from 'shared/db';

interface ConversationDetailProps {
  item: InboxItem;
  toggleReply: () => void;
}

export function ConversationActions({
  item,
  toggleReply,
}: ConversationDetailProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const { data: isAdmin } = useIsAdmin();
  const { mutateAsync: forward, isPending: isForwarding } =
    useForwardConversation();
  const { mutateAsync: analyze, isPending: isAnalyzing } = useAnalyzeInbox(
    Number(item.status_id),
  );
  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: isNotEmpty(),
    },
  });
  const onSubmit = form.onSubmit(async (values) => {
    await forward({
      forward_to_email: values.email,
      street_address: item.street_address,
      zip_code: item.zip_code,
    });
    close();
  });

  return (
    <>
      <Modal opened={opened} onClose={close} title="Forward">
        <form onSubmit={onSubmit}>
          <TextInput
            label="Email to send conversations to"
            placeholder="john@doe.com"
            data-autofocus
            {...form.getInputProps('email')}
          />
          <Button type="submit" loading={isForwarding} fullWidth mt="md">
            Submit
          </Button>
        </form>
      </Modal>
      <Flex gap="xs">
        <Button
          variant="default"
          leftIcon={<IconArrowBack />}
          onClick={toggleReply}
        >
          Reply
        </Button>
        <Button
          onClick={open}
          variant="empty"
          rightIcon={
            <ThemeIcon variant="empty">
              <IconArrowRight />
            </ThemeIcon>
          }
        >
          Forward
        </Button>

        {isAdmin && (
          <Button
            onClick={() => analyze()}
            loading={isAnalyzing}
            variant="light"
            leftIcon={
              <ThemeIcon variant="empty">
                <IconSparkles />
              </ThemeIcon>
            }
          >
            Analyze
          </Button>
        )}
      </Flex>
    </>
  );
}
