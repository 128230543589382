import { useQuery } from '@tanstack/react-query';
import { getCampaignProperties } from 'shared/db';
import { supabase } from '../utils';

export const useListings = (campaignId: number | null | undefined) =>
  useQuery({
    queryKey: ['listings', campaignId],
    queryFn: () =>
      campaignId ? getCampaignProperties(supabase, campaignId) : [],
  });
