import { Flex, Paper, Text } from "@mantine/core";

interface InputWrapperProps {
    title: string;
    description: string;
    children: React.ReactNode;
    rightWidth?: string;
}

export function InputWrapper({ title, description, children, rightWidth = "20%" }: InputWrapperProps) {
    return (
        <Paper px="xl" p="md" radius="md" my="md">
            <Flex direction="row" align="center" justify="space-between">
                <Flex direction="column" style={{ flex: 1 }}>
                    <Text fz="sm">{title}</Text>
                    <Text c="dimmed" fz="xs">{description}</Text>
                </Flex>

                <Flex justify="flex-end" w={rightWidth}>
                    {children}
                </Flex>
            </Flex>
        </Paper>
    )
}