import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  NewRepairLevel,
  RepairLevel,
  createRepairLevels,
  deleteRepairLevels,
  fetchRepairLevels,
  updateRepairLevels,
} from 'shared/db';
import { supabase } from '../utils';

export const useRepairLevels = (organisationId: number) =>
  useQuery({
    queryKey: ['repair_levels', organisationId],
    queryFn: () => fetchRepairLevels(supabase, organisationId),
  });

export const useCreateRepairLevel = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (repairLevel: NewRepairLevel) =>
      createRepairLevels(supabase, repairLevel),
    onSuccess: (data) => {
      queryClient.setQueriesData<RepairLevel[]>(
        { queryKey: ['repair_levels', organisationId] },
        (old_data) => [...(old_data || []), data],
      );
    },
  });
};

export const useUpdateRepairLevel = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (repairLevel: RepairLevel) =>
      updateRepairLevels(supabase, repairLevel),
    onSuccess: (data) => {
      queryClient.setQueriesData<RepairLevel[]>(
        { queryKey: ['repair_levels', organisationId] },
        (old_data) =>
          (old_data || []).map((c) => (c.id === data.id ? data : c)),
      );
    },
  });
};

export const useDeleteRepairLevel = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (contract_id: number) =>
      deleteRepairLevels(supabase, contract_id),
    onSuccess: (_, contract_id) => {
      queryClient.setQueriesData<RepairLevel[]>(
        { queryKey: ['repair_levels', organisationId] },
        (old_data) => (old_data || []).filter((c) => c.id !== contract_id),
      );
    },
  });
};
