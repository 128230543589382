import { useMutation, useQuery } from '@tanstack/react-query';
import { getApi } from '../utils';
import {
  OutreachInsightsApiResponse,
  ResponseInsightsApiResponse,
} from 'shared/api';
import { InsightsGranularity } from './useInsightsStore';

interface InsightsParams {
  campaignId?: string;
  dateRange: [Date | null, Date | null];
  granularity: InsightsGranularity;
}

export const useResponseInsights = (
  organisation_id: number,
  params: InsightsParams,
) =>
  useQuery({
    queryKey: [
      'response_insights',
      organisation_id,
      params.dateRange[0],
      params.dateRange[1],
      params.granularity,
      params.campaignId,
    ],
    queryFn: () => fetchResponseInsights(organisation_id, params),
  });

export async function fetchResponseInsights(
  organisation_id: number,
  params: InsightsParams,
): Promise<ResponseInsightsApiResponse> {
  const requestParams = {
    organisation_id,
    campaignId: params.campaignId === '' ? undefined : params.campaignId,
    dateStart: params.dateRange[0],
    dateEnd: params.dateRange[1],
    granularity: params.granularity,
  };

  return getApi<ResponseInsightsApiResponse>(
    `/response_insights`,
    requestParams,
  );
}

export const useOutreachInsights = (
  organisation_id: number,
  params: InsightsParams,
) =>
  useQuery({
    queryKey: [
      'outreach_insights',
      organisation_id,
      params.dateRange[0],
      params.dateRange[1],
      params.granularity,
      params.campaignId,
    ],
    queryFn: () => fetchOutreachInsights(organisation_id, params),
  });

export async function fetchOutreachInsights(
  organisation_id: number,
  params: InsightsParams,
): Promise<OutreachInsightsApiResponse> {
  const requestParams = {
    organisation_id,
    campaignId: params.campaignId === '' ? undefined : params.campaignId,
    dateStart: params.dateRange[0],
    dateEnd: params.dateRange[1],
    granularity: params.granularity,
  };

  return getApi<OutreachInsightsApiResponse>(
    `/outreach_insights`,
    requestParams,
  );
}

// Admin only
export const useForceRunInsights = () =>
  useMutation({
    mutationFn: forceRunInsights,
  });

export async function forceRunInsights(
  organisation_id: number,
): Promise<ResponseInsightsApiResponse> {
  return getApi<ResponseInsightsApiResponse>(`/process_organisation_insights`, {
    organisation_id,
  });
}
