import { SupabaseClient } from '@supabase/supabase-js';
import { subDays } from 'date-fns';

export enum CATEGORY_KEYS {
  COUNTER_WITH_PRICE_OR_TERMS = 'COUNTER_WITH_PRICE_OR_TERMS',
  COUNTER_WITHOUT_PRICE_OR_TERMS = 'COUNTER_WITHOUT_PRICE_OR_TERMS',
  REQUEST_FOR_MORE_INFORMATION = 'REQUEST_FOR_MORE_INFORMATION',
  PENDING_CONVERSATION_WITH_SELLER = 'PENDING_CONVERSATION_WITH_SELLER',
  POSITIVE_NO_COUNTER = 'POSITIVE_NO_COUNTER',
  NEGATIVE_NO_COUNTER = 'NEGATIVE_NO_COUNTER',
  WRONG_CONTACT_INFO = 'WRONG_CONTACT_INFO',
  INITIAL_OFFER_NOT_DELIVERED = 'INITIAL_OFFER_NOT_DELIVERED',
  PREVIOUS_RESPONSE = 'PREVIOUS_RESPONSE',
}

export enum MessageSentiment {
  COUNTER = 'COUNTER',
  UNKNOWN = 'UNKNOWN',
}

export enum SendgridMessageStatus {
  PROCESSED = 'processed',
  DELIVERED = 'delivered',
  NOT_DELIVERED = 'not_delivered',
  PENDING = 'pending',
}

export interface PropertyMessage {
  id: number;
  created_at?: string;
  type: 'EMAIL';
  content: string;
  outbound: boolean;
  subject: string;
  campaign_id: number | null;
  campaign_step_id: string | null;
  street_address: string;
  zip_code: number;
  organisation_id: number;
  email_id?: string | null;
  from_email?: string | null;
  from_name?: string | null;
  seen?: boolean;
  email_opened: boolean | null;
  email_clicked: boolean | null;
  email_status: SendgridMessageStatus | null;
  sendgrid_msg_id: string | null;
  sentiment_analysis: CATEGORY_KEYS | null;
  counter_offer: number | null;
  workflow_step_id: number | null;
}

export async function createPropertyMessage(
  supabase: SupabaseClient,
  newLeadMessage: Omit<PropertyMessage, 'id' | 'created_at'>,
): Promise<PropertyMessage> {
  const { data, error } = await supabase
    .from('campaign_property_messages')
    .insert(newLeadMessage)
    .select()
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't create lead message");
  }

  return data;
}

export async function updatePropertyMessage(
  supabase: SupabaseClient,
  propertyMessage: PropertyMessage,
) {
  const { error } = await supabase
    .from('campaign_property_messages')
    .update(propertyMessage)
    .eq('id', propertyMessage.id);

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't update lead message");
  }
}

export async function fetchPropertyMessage(
  supabase: SupabaseClient,
  id: number,
): Promise<PropertyMessage> {
  const { data, error } = await supabase
    .from('campaign_property_messages')
    .select()
    .eq('id', id)
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't fetch message");
  }

  return data;
}

export async function fetchPropertyMessages(
  supabase: SupabaseClient,
  street_address: string,
  zip_code: number,
  campaignId: number,
): Promise<PropertyMessage[]> {
  const { data, error } = await supabase
    .from('campaign_property_messages')
    .select()
    .eq('street_address', street_address)
    .eq('zip_code', zip_code)
    .eq('campaign_id', campaignId);

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't fetch lead messages:");
  }

  return data;
}

export async function fetchPropertyMessagesByOrganisation(
  supabase: SupabaseClient,
  street_address: string,
  zip_code: number,
): Promise<PropertyMessage[]> {
  const { data, error } = await supabase
    .from('campaign_property_messages')
    .select()
    .eq('street_address', street_address)
    .eq('zip_code', zip_code)
    .order('id', { ascending: true });

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't fetch lead messages:");
  }

  return data;
}

export async function fetchInboundPropertyMessagesByOrganisation(
  supabase: SupabaseClient,
  street_address: string,
  zip_code: number,
): Promise<PropertyMessage[]> {
  const { data, error } = await supabase
    .from('campaign_property_messages')
    .select()
    .eq('street_address', street_address)
    .eq('zip_code', zip_code)
    .eq('outbound', false)
    .order('id', { ascending: true });

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't fetch lead messages:");
  }

  return data;
}

export async function fetchOutboundPropertyMessages(
  supabase: SupabaseClient,
  street_address: string,
  zip_code: number,
): Promise<PropertyMessage> {
  const { data, error } = await supabase
    .from('campaign_property_messages')
    .select()
    .eq('street_address', street_address)
    .eq('zip_code', zip_code)
    .limit(1)
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't fetch lead messages:");
  }

  return data;
}

export async function fetchUnprocessedOutboundMessages(
  supabase: SupabaseClient,
  organisation_id: number,
): Promise<PropertyMessage[]> {
  const thirtyDaysAgo = subDays(new Date(), 30).toISOString();

  const { data, error } = await supabase
    .from('campaign_property_messages')
    .select()
    .eq('organisation_id', organisation_id)
    .gte('created_at', thirtyDaysAgo)
    .is('email_clicked', null)
    .is('outbound', true);

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't fetch lead messages:");
  }

  return data;
}

export async function fetchUnprocessedInboundMessages(
  supabase: SupabaseClient,
  organisation_id: number,
): Promise<PropertyMessage[]> {
  const { data, error } = await supabase
    .from('campaign_property_messages')
    .select()
    .eq('organisation_id', organisation_id)
    .is('sentiment_analysis', null)
    .is('outbound', false)
    .limit(200); // TODO: Remove

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't fetch lead messages:");
  }

  return data;
}

export async function fetchMessageByEmailId(
  supabase: SupabaseClient,
  emailId: string,
): Promise<PropertyMessage | null> {
  const { data, error } = await supabase
    .from('campaign_property_messages')
    .select()
    .eq('email_id', emailId)
    .limit(1)
    .single();

  if (error) {
    console.log('Error fetching message by email id: ', error);
    return null;
  }

  return data;
}

export async function fetchMessagesByEmail(
  supabase: SupabaseClient,
  email: string,
): Promise<PropertyMessage[] | null> {
  const { data, error } = await supabase
    .from('campaign_property_messages')
    .select('*, properties!inner(*)')
    .eq('properties.agent_email', email);

  if (error) {
    console.log('Error fetching message by email: ', error);
    return null;
  }

  return data.map((message) => ({ ...message, properties: undefined }));
}
