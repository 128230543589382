import { Title, Text, Grid, Button, Flex, Loader, } from "@mantine/core";
import classes from './index.module.css'
import { Organisation } from "shared/db"
import { EmailSettings } from "fe/feature/organisation-settings";
import { useAuthenticatedDomain, useStartDomainAuthentication, useValidateDomainAuthentication } from "fe/queries";

interface OrganisationEmailVerificationProps {
    organisation: Organisation;
}

export function OrganisationEmailVerification({
    organisation
}: OrganisationEmailVerificationProps) {
    const organisationId = organisation.id!
    const { data: domain, isPending } = useAuthenticatedDomain(organisationId)
    const {
        mutateAsync: startDomainAuthentication,
        isPending: isLoadingStartAuthentication
    } = useStartDomainAuthentication(organisationId)
    const { mutateAsync: validateDomainAuthentication } = useValidateDomainAuthentication(
        domain?.id || 0,
        organisationId
    )

    const dnsRecords = Object.values(domain?.dns || {})
    const records = dnsRecords.map(record => ({
        type: record.type,
        name: record.host,
        value: record.data,
        valid: record.valid.toString(),
    }))

    if (isPending) {
        return (
            <Flex justify="center" align="center" style={{ height: '400px' }}>
                <Loader />
            </Flex>
        )
    }

    if (!domain) {
        return (
            <div>
                <Title mt="sm" order={3}>Instructions</Title>

                <Text my="xl">Start verification process for your email: {organisation?.from_email}</Text>

                <Button
                    loading={isLoadingStartAuthentication}
                    onClick={() => startDomainAuthentication()}
                >
                    Start verification
                </Button>

            </div>
        )
    }

    if (domain.valid) {
        return <EmailSettings organisation={organisation} />
    }

    return (
        <div>
            <Title mt="sm" order={3}>Instructions</Title>

            <Text mt="xl">Add DNS records and click "Verify"</Text>

            <Grid my="md" style={{ margin: '20px 0' }}>
                <Grid.Col className={classes.gridCol} span={2}>TYPE</Grid.Col>
                <Grid.Col className={classes.gridCol} span={4}>NAME</Grid.Col>
                <Grid.Col className={classes.gridCol} span={4}>VALUE</Grid.Col>
                <Grid.Col className={classes.gridCol} span={2}>VERIFIED</Grid.Col>

                {records.map(record => (
                    <>
                        <Grid.Col className={classes.gridCol} span={2}>{record.type}</Grid.Col>
                        <Grid.Col className={classes.gridCol} span={4}>{record.name}</Grid.Col>
                        <Grid.Col className={classes.gridCol} span={4} >{record.value}</Grid.Col>
                        <Grid.Col className={classes.gridCol} span={2} >{record.valid}</Grid.Col>
                    </>
                ))}
            </Grid>

            <Flex justify="flex-end">
                <Button onClick={() => validateDomainAuthentication()}>Verify DNS records</Button>
            </Flex>
        </div>
    )
}
