import { Button, FileInput, Flex, TextInput } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCreateOrganisationContract, useOrganisationStore, useUpdateOrganisationContract } from "fe/queries";
import { OrganisationContract } from "shared/db";

interface ContractFormProps {
    contract?: OrganisationContract;
    onSuccess: () => void;
}

export function ContractForm({
    contract,
    onSuccess
}: ContractFormProps) {
    const { organisation } = useOrganisationStore()

    if (!organisation) return null

    const { mutateAsync: create, isPending: isCreateLoading } = useCreateOrganisationContract(organisation.id)
    const { mutateAsync: update, isPending: isUpdateLoading } = useUpdateOrganisationContract(organisation.id)

    const form = useForm({
        initialValues: {
            label: contract?.label || '',
            buyer_name: contract?.buyer_name || '',
            file: null as any,
        },
        validate: {
            label: isNotEmpty(),
        }
    });

    const onSubmit = form.onSubmit(async (values) => {
        if (contract) {
            await update({
                ...contract,
                label: values.label,
                buyer_name: values.buyer_name,
            })
        } else {
            await create(values)
        }
        onSuccess()
    })

    return (
        <div>
            <form onSubmit={onSubmit}>
                <TextInput
                    mb="sm"
                    withAsterisk
                    label="Label"
                    placeholder="Initial contract"
                    {...form.getInputProps('label')}
                />

                <TextInput
                    mb="sm"
                    label="Buyer's name"
                    placeholder="John Doe"
                    {...form.getInputProps('buyer_name')}
                />

                {!contract && (
                    <FileInput
                        placeholder="Pick file"
                        label="Contract file"
                        withAsterisk
                        {...form.getInputProps('file')}
                    />
                )}

                <Flex w="100%" justify="flex-end">
                    <Button type="submit" mt="md" loading={isCreateLoading || isUpdateLoading}>
                        {contract ? 'Update' : 'Create'}
                    </Button>
                </Flex>
            </form>
        </div>
    )
}