import { Flex, Table } from '@mantine/core';
import classes from './CampaignContractUpload.module.scss';
import { Typography } from 'fe/ui/shared';

const elements = [
  { name: 'CreatedDate', type: 'string', description: '' },
  {
    name: 'PropertyAddress',
    type: 'string',
    description: 'Address of the property',
  },
  { name: 'PropertyCounty', type: 'string', description: '' },
  { name: 'PropertyType', type: 'string', description: '' },
  { name: 'ListingBrokerage', type: 'string', description: '' },
  { name: 'ListingAgent', type: 'string', description: '' },
  { name: 'BrokerageAddress', type: 'string', description: '' },
  { name: 'BrokeragePhone', type: 'string', description: '' },
  { name: 'AgentPhone', type: 'string', description: '' },
  { name: 'AgentEmail', type: 'string', description: '' },
  { name: 'PropertyZip', type: 'string', description: '' },
  { name: 'PropertyCity', type: 'string', description: '' },
  { name: 'PropertyTaxParcelId', type: 'string', description: '' },
  { name: 'PropertyMls', type: 'string', description: '' },
  { name: 'PropertyTaxId', type: 'string', description: '' },
  { name: 'BrokerageLicense', type: 'string', description: '' },
  { name: 'SellerName', type: 'string', description: '' },
  {
    name: 'HasOwnedThePropertyForAtLeastOneYear',
    type: '"X"',
    description:
      'Marks with an "X" if property has been owned for at least one year by the current owner',
  },
  {
    name: 'HasNotOwnedThePropertyForAtLeastOneYear',
    type: '"X"',
    description:
      'Marks with an "X" if property has NOT been owned for at least one year by the current owner',
  },
  { name: 'PropertyLot', type: 'string', description: '' },
  { name: 'PropertyBlock', type: 'string', description: '' },
  { name: 'PropertyCondominium', type: 'string', description: '' },
  { name: 'OfferPrice', type: 'string', description: '' },
  {
    name: 'OfferPriceMinFees',
    type: 'string',
    description: 'Offer price minus $10,000',
  },
  { name: 'OfferPriceNumber', type: 'string', description: '' },
  {
    name: 'OfferPriceMinOnePercent',
    type: 'string',
    description: 'Offer price minus 1% of the offer price',
  },
  { name: 'OfferDeposit', type: 'string', description: '' },
  {
    name: 'OfferBalance',
    type: 'string',
    description: 'Offer price minus $1,000',
  },
  { name: 'OfferDate', type: 'string', description: '' },
  { name: 'OfferDateTwoDays', type: 'string', description: '' },
  { name: 'OfferDateTwoWeeks', type: 'string', description: '' },
  {
    name: 'OfferDateThreeWeeks',
    type: 'string',
    description: 'Three weeks from the offer date',
  },
  { name: 'OfferDateThirtyFiveDays', type: 'string', description: '' },
  { name: 'OfferDateDay', type: 'string', description: '' },
  { name: 'OfferDateMonth', type: 'string', description: '' },
  { name: 'OfferDateYear', type: 'string', description: '' },
  { name: 'OfferDateYearShort', type: 'string', description: '' },
  { name: 'OnePercentOfOfferPrice', type: 'string', description: '' },
  { name: 'ThirtyDaysFromOfferDate', type: 'string', description: '' },
  {
    name: 'HomeIsOlderThan1978',
    type: '"X"',
    description: 'Marks with an "X" if property was built before 1978',
  },
  { name: 'X', type: '"X"', description: '' },
  { name: 'HoaFees', type: 'string', description: '' },
  { name: 'BuyerFirstInitial', type: 'string', description: '' },
  { name: 'BuyerLastInitial', type: 'string', description: '' },
  { name: 'BuyerInitials', type: 'string', description: '' },
  { name: 'LegalDescription', type: 'string', description: '' },
  { name: 'IsHOA', type: 'checkbox', description: '' },
  { name: 'Ishoa', type: 'checkbox', description: '' },
  { name: 'ZoningClassification', type: 'string', description: '' },
  {
    name: 'OfferPriceMinTenPercent',
    type: 'string',
    description: '10% of offer price',
  },
  { name: 'FourDaysFromOfferDate', type: 'string', description: '' },
  {
    name: 'AgentLicense',
    type: 'string',
    description: 'Agent state license number if available',
  },
  {
    name: 'OfferPriceWritten',
    type: 'string',
    description:
      'Offer price written out. Example: If offer price is $100,300, this field value will be "One Hundred Thousand Three Hundred"',
  },
  {
    name: 'OfferPriceMinThreeThousand',
    type: 'string',
    description: 'Offer price minus three thousand',
  },
  {
    name: 'OfferPriceMinFiveHundred',
    type: 'string',
    description: 'Offer price minus five hundred',
  },
];

export function ContractFields() {
  const rows = elements.map((element) => (
    <tr key={element.name}>
      <td>{element.name}</td>
      <td>{element.description}</td>
      <td>{element.type}</td>
    </tr>
  ));

  elements.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Flex direction="column" className={classes.tableContainer} h="100%">
      <Typography variant="label">Available fields:</Typography>

      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </div>
    </Flex>
  );
}
