import { Flex, Container, Button, TextInput, Divider, List, ThemeIcon, ActionIcon } from "@mantine/core";
import { IconUser, IconX } from "@tabler/icons-react";
import React from "react";
import { useForm, zodResolver } from "@mantine/form";
import { z } from "zod";

interface CreateDeleteListProps {
    data: string[];
    onCreate: (value: string) => Promise<any>;
    isCreating: boolean;
    onDeleteClick: (values: string) => void;
    isDeleting: boolean;
    label: string;
    placeholder: string;
}


export function CreateDeleteList({
    data,
    onCreate,
    isCreating: isSubmitting,
    onDeleteClick,
    isDeleting,
    label,
    placeholder
}: CreateDeleteListProps) {
    const form = useForm({
        initialValues: {
            value: '',
        },
        validate: zodResolver(z.object({
            value: z.string().min(1),
        })),
    });

    const onFormSubmit = form.onSubmit(async (values) => {
        await onCreate(values.value)
        form.reset()
    })


    return (
        <Container p={0} style={{ flex: 1 }}>
            <form onSubmit={onFormSubmit} style={{ width: '100%' }}>
                <Flex align="flex-end">
                    <TextInput
                        placeholder={placeholder}
                        label={label}
                        withAsterisk
                        {...form.getInputProps('value')}
                        style={{ flex: 1 }}
                    />
                    <Button type="submit" variant="outline" loading={isSubmitting}>
                        +
                    </Button>
                </Flex>
            </form>

            {data && data?.length > 0 && (
                <>
                    <Divider />
                    <List py="md" icon={
                        <ThemeIcon color="teal" size={24} radius="xl">
                            <IconUser size="1rem" />
                        </ThemeIcon>
                    }>
                        {data?.map(member => (
                            <List.Item key={member} my="md">
                                <Flex gap="md" align="center">
                                    {member}
                                    <ActionIcon
                                        loading={isDeleting}
                                        onClick={() => onDeleteClick(member)}
                                        variant="filled"
                                        color="red"
                                        size="xs"
                                    >
                                        <IconX />
                                    </ActionIcon>
                                </Flex>
                            </List.Item>
                        ))}
                    </List>
                </>
            )}
        </Container>
    )
}