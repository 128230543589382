/* eslint-disable react-hooks/rules-of-hooks */
import { Outlet, Router, Route, RootRoute } from '@tanstack/react-router';
import Home from './App';
import { SettingsPage } from './pages/settings';
import { InboxPage } from 'fe/feature/inbox';
import { InsightsPage } from 'fe/feature/insights';
import { SearchPage } from './pages/offer/SearchPage/SearchPage';
import { OfferToolPage } from './pages/offer/OfferToolPage';
import {
  AuthWrapper,
  PrivacyPolicy,
  ServiceAgreement,
  TermsOfService
} from 'fe/feature/auth';
import { useDocumentTitle } from '@mantine/hooks';
import { useCampaign } from 'fe/queries';

const rootRoute = new RootRoute({
  component: Root
});

function Root() {
  return (
    <AuthWrapper>
      <Outlet />
    </AuthWrapper>
  );
}

// Create an index route
export const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/',
  component: () => {
    useDocumentTitle('Inbox - Hey Rafi');
    return <InboxPage />;
  }
});

export const termsOfServiceRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/terms-of-service',
  component: () => {
    useDocumentTitle('Terms of Service - Hey Rafi');
    return <TermsOfService />;
  }
});

export const privacyPolicyRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/privacy-policy',
  component: () => {
    useDocumentTitle('Privacy Policy - Hey Rafi');
    return <PrivacyPolicy />;
  }
});

export const serviceAgreementRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/service-agreement',
  component: () => {
    useDocumentTitle('Service Agreement - Hey Rafi');
    return <ServiceAgreement />;
  }
});

export const insightsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/insights',
  component: () => {
    useDocumentTitle('Insights - Hey Rafi');
    return <InsightsPage />;
  }
});

export const offerRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/offer/$zip_code/$street_address',
  component: () => {
    const { street_address } = offerRoute.useParams();
    useDocumentTitle(`${street_address} - Hey Rafi`);
    return <OfferToolPage />;
  },
  parseParams: (params) => {
    return {
      zip_code: params.zip_code,
      street_address: decodeURIComponent(params.street_address)
    };
  },
  stringifyParams: (params) => {
    return {
      zip_code: params.zip_code,
      street_address: encodeURIComponent(params.street_address)
    };
  }
});

export const campaignsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/campaigns/$campaignId',
  component: () => {
    const { campaignId } = campaignsRoute.useParams();
    const { data: campaign } = useCampaign(campaignId);
    useDocumentTitle(
      campaign ? `${campaign.name} - Hey Rafi` : 'Campaign - Hey Rafi'
    );
    return <Home />;
  }
});

export const searchRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/offer',
  component: () => {
    useDocumentTitle(`Search - Hey Rafi`);
    return <SearchPage />;
  }
});

export const settingsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/settings',
  component: () => {
    useDocumentTitle(`Settings - Hey Rafi`);
    return <SettingsPage />;
  }
});

// Create the route tree using your routes
const routeTree = rootRoute.addChildren([
  indexRoute,
  insightsRoute,
  settingsRoute,
  campaignsRoute,
  searchRoute,
  offerRoute,
  termsOfServiceRoute,
  privacyPolicyRoute,
  serviceAgreementRoute
]);
// Create the router using your route tree
const router = new Router({ routeTree });
export default router;

// Register your router for maximum type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
