import { Button, Flex, TextInput } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCreateOrganisationWorkflow, useOrganisationStore, useUpdateOrganisationWorkflow } from "fe/queries";
import { OrganisationWorkflow } from "shared/db";

interface ContractFormProps {
    workflow?: OrganisationWorkflow;
    onSuccess: () => void;
}

export function WorkflowForm({
    workflow,
    onSuccess
}: ContractFormProps) {
    const { organisation } = useOrganisationStore()

    if (!organisation) return null

    const { mutateAsync: create, isPending: isCreateLoading } = useCreateOrganisationWorkflow(organisation.id)
    const { mutateAsync: update, isPending: isUpdateLoading } = useUpdateOrganisationWorkflow(organisation.id)

    const form = useForm({
        initialValues: {
            name: workflow?.name || '',
        },
        validate: {
            name: isNotEmpty(),
        }
    });

    const onSubmit = form.onSubmit(async (values) => {
        if (workflow) {
            await update({
                ...workflow,
                name: values.name,
            })
        } else {
            await create(values.name)
        }
        onSuccess()
    })

    return (
        <div>
            <form onSubmit={onSubmit}>
                <TextInput
                    mb="sm"
                    withAsterisk
                    label="Name"
                    placeholder="Main workflow"
                    {...form.getInputProps('name')}
                />

                <Flex w="100%" justify="flex-end">
                    <Button type="submit" mt="md" loading={isCreateLoading || isUpdateLoading}>
                        {workflow ? 'Update' : 'Create'}
                    </Button>
                </Flex>
            </form>
        </div>
    )
}