import React from 'react';
import { CampaignPropertyNote } from 'shared/db';
import { LeadStatusChanges, PropertyMessageWithAttachments } from 'be/db';
import { ConvoItemMessage } from './ConvoItemMessage';
import { ConvoItemNote } from './ConvoItemNote';
import { ConvoItemStatus } from './ConvoItemStatus';
import { Selectable } from 'kysely';

interface ConversationMessageProps {
  item:
    | PropertyMessageWithAttachments
    | CampaignPropertyNote
    | Selectable<LeadStatusChanges>;
}

export function ConvoItem({ item }: ConversationMessageProps) {
  switch (true) {
    case 'outbound' in item:
      return <ConvoItemMessage message={item} />;
    case 'new_status' in item:
      return <ConvoItemStatus change={item} />;
    default:
      return <ConvoItemNote note={item} />;
  }
}
