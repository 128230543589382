import { createTRPCReact } from '@trpc/react-query';
import type { AppRouter } from 'shared/types';
import { supabase } from './utils';

export const trpc = createTRPCReact<AppRouter>();

export const getAuthorization = async () => {
  const session = await supabase.auth.getSession();
  if (!session || !session.data.session) {
    return undefined;
  }

  return session.data.session.access_token;
};
