import React, { useState } from 'react';
import {
  Divider,
  TextInput,
  Text,
  Box,
  Flex,
  MultiSelect,
  SegmentedControl,
  ActionIcon,
  ScrollArea,
  Skeleton,
} from '@mantine/core';
import { ConversationsListItem } from './ConversationsListItem';
import classes from './ConversationsList.module.scss';
import { InboxItem, Products, StatusOfCampaignPropertyLabels } from 'shared/db';
import {
  IconFilter,
  IconHeartRateMonitor,
  IconMessage,
} from '@tabler/icons-react';
import { useLocalStorage } from '@mantine/hooks';
import { useCampaigns, useInboxStore, useOrganisationStore } from 'fe/queries';
import { useVirtualizer, Virtualizer } from '@tanstack/react-virtual';

interface ConversationsListProps {
  items: InboxItem[];
  isLoading: boolean;
  fetchNextPage: () => Promise<any>;
}

export function ConversationsList({
  items,
  isLoading,
  fetchNextPage,
}: ConversationsListProps) {
  const { organisation } = useOrganisationStore();
  const {
    filterCampaignType,
    setFilterCampaignType,
    filterCampaigns,
    setFilterCampaigns,
    filterLeadStatus,
    setFilterLeadStatus,
    setFilterSearchTerm,
  } = useInboxStore((state) => ({
    setFilterLeadStatus: state.setFilterLeadStatus,
    filterLeadStatus: state.filterLeadStatus,
    setFilterCampaigns: state.setFilterCampaigns,
    filterCampaigns: state.filterCampaigns,
    setFilterCampaignType: state.setFilterCampaignType,
    filterCampaignType: state.filterCampaignType,
    setFilterSearchTerm: state.setFilterSearchTerm,
  }));

  const [displayFilters, setDisplayFilters] = useLocalStorage({
    key: 'inbox-display-filters',
    defaultValue: false,
  });
  const { data: campaigns } = useCampaigns(organisation?.id || 0);

  const anyFiltersSet =
    filterCampaigns.length > 0 ||
    filterLeadStatus.length > 0 ||
    filterCampaignType !== '';

  const scrollAreaViewportElem = document.getElementsByClassName(
    'mantine-ScrollArea-viewport',
  )?.[1];

  const handleScroll = ({ y }: { y: number }) => {
    const totalScrollableHeight =
      scrollAreaViewportElem.scrollHeight - scrollAreaViewportElem.clientHeight;
    const isWithinThreshold = y >= totalScrollableHeight - 40;

    if (isWithinThreshold) {
      fetchNextPage();
    }
  };

  const [rowVirtualizer, setVirtualizer] = useState<Virtualizer<
    Element,
    Element
  > | null>(null);

  const parentRef = React.useRef();
  const count = isLoading ? 16 : items.length;
  useVirtualizer({
    count,
    estimateSize: () => 70,
    getScrollElement: () => scrollAreaViewportElem || null,
    onChange: (i) => setVirtualizer(i),
  });

  return (
    <div className={classes.wrapper}>
      <Box py="sm" px="xs">
        <Text fz="lg" mb="xs" fw={900}>
          All conversations
        </Text>

        <Flex gap="xs" key="actions">
          <TextInput
            onChange={(event) => setFilterSearchTerm(event.currentTarget.value)}
            size="xs"
            placeholder="Search by property or name"
            style={{ flex: 1 }}
          />
          <ActionIcon
            onClick={() => setDisplayFilters((oldVal) => !oldVal)}
            variant={anyFiltersSet ? 'filled' : 'light'}
            color="blue"
          >
            <IconFilter size="1rem" />
          </ActionIcon>
        </Flex>

        {displayFilters && (
          <Flex my="sm" direction="column" key="filters">
            <Flex w="100%" direction="column">
              <Text size="xs" fw={400} c="#212529">
                Campaign Type
              </Text>
              <SegmentedControl
                value={filterCampaignType}
                onChange={(value) => setFilterCampaignType(value as Products)}
                size="xs"
                data={[
                  { label: 'All', value: '' },
                  { label: 'Active', value: Products.LISTINGS },
                  { label: 'Expired', value: Products.EXPIRED },
                  { label: 'FSBO', value: Products.FSBO },
                ]}
              />
            </Flex>
            <MultiSelect
              size="xs"
              label="Campaign"
              value={filterCampaigns.map(String)}
              onChange={(newVal) => setFilterCampaigns(newVal.map(Number))}
              data={(campaigns || []).map((campaign) => ({
                value: String(campaign.id),
                label: campaign.name,
              }))}
              placeholder="Any"
              variant="filled"
              icon={<IconMessage size="1rem" />}
            />

            <MultiSelect
              size="xs"
              label="Lead status"
              value={filterLeadStatus}
              onChange={(newVal) => setFilterLeadStatus(newVal)}
              data={StatusOfCampaignPropertyLabels}
              placeholder="Any"
              variant="filled"
              icon={<IconHeartRateMonitor size="1rem" />}
            />
          </Flex>
        )}
      </Box>

      <Divider />

      <div key="list" style={{ position: 'relative', flex: 1 }}>
        <ScrollArea
          type={isLoading ? 'never' : 'auto'}
          onScrollPositionChange={handleScroll}
          ref={parentRef as unknown as any}
          style={{
            height: `100%`,
            position: 'absolute',
            width: '100%',
          }}
        >
          {isLoading ? (
            <Flex
              key="loader-list"
              direction="column"
              px="xs"
              py="md"
              gap="xs"
              align="center"
              style={{ height: '100%', width: '100%' }}
            >
              {[...Array(16)].map((_, index) => (
                <Flex
                  key={'loader-' + index}
                  direction="row"
                  px="xs"
                  py="md"
                  gap="xs"
                  align="center"
                  w="100%"
                >
                  <Skeleton height={40} w={40} />
                  <Flex
                    direction="column"
                    style={{ flex: 1 }}
                    justify="space-between"
                    h={36}
                  >
                    <Skeleton height={8} radius="xl" w="80%" />
                    <Skeleton height={8} mt={6} radius="xl" w="80%" />
                  </Flex>

                  <Flex
                    direction="column"
                    justify="space-between"
                    h={40}
                    mt={-4}
                  >
                    <Skeleton height={22} w={22} circle />
                    <Skeleton height={8} radius="xl" />
                  </Flex>
                </Flex>
              ))}
            </Flex>
          ) : (
            <div
              style={{
                height: `${rowVirtualizer?.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {rowVirtualizer?.getVirtualItems().map((virtualItem) => {
                return (
                  <div
                    key={virtualItem.key}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: `${virtualItem.size}px`,
                      transform: `translateY(${virtualItem.start}px)`,
                    }}
                  >
                    <ConversationsListItem
                      key={
                        items[virtualItem.index].street_address +
                        items[virtualItem.index].zip_code
                      }
                      item={items[virtualItem.index]}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </ScrollArea>
      </div>
    </div>
  );
}
