import { useState } from 'react'
import { Box, Button, CopyButton, Divider, Flex, Text, TextInput, Title } from '@mantine/core'
import { useOrganisationStore } from 'fe/queries'
import { generateApiKey } from './utils'
import { useForm, zodResolver } from "@mantine/form";
import { z } from 'zod';
import { notifications } from '@mantine/notifications';

export function Integrations() {
    const { organisation, updateOrganisation } = useOrganisationStore()
    const [isLoading, setIsLoading] = useState(false)

    const onGenerateClick = async () => {
        setIsLoading(true)
        await updateOrganisation({
            ...organisation,
            api_key: await generateApiKey()
        })
        setIsLoading(false)
    }

    const form = useForm({
        initialValues: {
            lead_webhook_url: organisation?.lead_webhook_url,
        },
        validate: zodResolver(z.object({
            lead_webhook_url: z.string().url().min(1),
        }))
    });

    const onSubmit = form.onSubmit(async (values) => {
        setIsLoading(true)
        await updateOrganisation({
            ...organisation,
            lead_webhook_url: values.lead_webhook_url
        })
        setIsLoading(false)
        notifications.show({
            title: 'Webhook URL saved',
            message: 'Please start testing your integration.',
        })
    })

    if (!organisation) return null

    if (!organisation.api_key) {
        return (
            <Box my="xl">
                <Title>Integrate your CRM</Title>

                <Text mt="lg" mb="xl">Please click on "Generate API Key" and then use Zapier to connect.</Text>

                <Button loading={isLoading} onClick={onGenerateClick}>Generate API Key</Button>
            </Box>
        )
    }

    return (
        <Box my="xl">
            <Title>Integrate your CRM</Title>


            <Text mt="lg">Your API Key is</Text>
            <Flex mb="lg">
                <TextInput
                    value={organisation.api_key}
                    disabled
                    style={{ flex: 1 }}
                    radius="0"
                />

                <CopyButton value="https://mantine.dev">
                    {({ copied, copy }) => (
                        <Button color={copied ? 'teal' : 'blue'} onClick={copy} radius="0">
                            {copied ? 'Copied url' : 'Copy url'}
                        </Button>
                    )}
                </CopyButton>
            </Flex>

            <Text>You'll be prompted to type it as part of the Zapier Authentication</Text>

            <Divider my="xl" />

            <form onSubmit={onSubmit}>
                <TextInput label="Please input the Webhook URL you want to send leads you to"    {...form.getInputProps('lead_webhook_url')} />

                <Button type="submit" mt="lg" loading={isLoading}>Save Webhook URL</Button>
            </form>
        </Box >
    )
}