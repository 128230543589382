import {
  ActionIcon,
  Box,
  Divider,
  Flex,
  Loader,
  Paper,
  Skeleton,
  Tooltip,
} from '@mantine/core';
import {
  IconCircleCheck,
  IconDownload,
  IconPencil,
  IconX,
} from '@tabler/icons-react';
import { Typography } from 'fe/ui/shared';
import { OrganisationContract } from 'shared/db';
import { useOrganisationContractPDF } from 'fe/queries';

interface ContractCardProps {
  contract: OrganisationContract;
  onEdit: (contract: OrganisationContract) => void;
  onDelete: (id: number) => void;
  isDeleting: boolean;
  isSelected: boolean;
  onChange: () => void;
  isLoadingChange?: boolean;
}

export function ContractCard({
  contract,
  onEdit,
  onDelete,
  isDeleting,
  isSelected,
  onChange,
  isLoadingChange,
}: ContractCardProps) {
  const { data: contractPDF, isLoading } = useOrganisationContractPDF(
    contract.id,
  );

  const downloadPDF = () => {
    const a = document.createElement('a');
    a.href = 'data:application/pdf;base64,' + contractPDF;
    a.download = `Example ${contract.label}.pdf`;
    a.click();
  };

  return (
    <Paper
      p="xs"
      w="100%"
      radius="md"
      onClick={onChange}
      sx={(theme) => ({
        border: isSelected
          ? '2px solid ' + theme.colors['brand'][6]
          : '1px solid transparent',
        position: 'relative',
        cursor: 'pointer',
        ':hover': {
          border: !isSelected
            ? '1px solid ' + theme.colors['brand'][6]
            : '2px solid ' + theme.colors['brand'][8],
        },
      })}
      shadow={!isSelected ? 'sm' : undefined}
    >
      <Flex justify="space-between" w="100%" align="center" mb="xl">
        <Flex direction="column">
          <Typography fz="xs">Contract Name</Typography>
          <Typography>{contract.label}</Typography>
        </Flex>

        <Flex gap="xs">
          <Tooltip label="Download example filled contract">
            <ActionIcon
              variant="outline"
              color="brand"
              disabled={!contractPDF || isLoading}
              onClick={(e) => {
                e.stopPropagation();
                downloadPDF();
              }}
            >
              <IconDownload size="1.125rem" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Edit contract">
            <ActionIcon
              variant="outline"
              color="brand"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(contract);
              }}
            >
              <IconPencil size="1.125rem" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Archive contract">
            <ActionIcon
              variant="light"
              color="red"
              loading={isDeleting}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(contract.id);
              }}
            >
              <IconX size="1.125rem" />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </Flex>

      <Divider my="md" />

      {isLoading ? (
        <Skeleton height={300} radius="md" animate={true} />
      ) : contractPDF ? (
        <embed
          src={`data:application/pdf;base64,${contractPDF}#page=1&toolbar=0&view=FitH`}
          type="application/pdf"
          style={{
            height: '300px',
            width: '100%',
            border: 'none',
            overflow: 'hidden',
          }}
        />
      ) : (
        <Typography>No PDF available</Typography>
      )}

      {isSelected && (
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: -10,
            right: -10,
            borderRadius: 50,
            width: '1.5rem',
            height: '1.5rem',
            background: theme.colors['brand'][6],
          })}
        >
          {isLoadingChange ? (
            <Box pl=".25rem" pt=".1rem">
              <Loader size="1rem" color="white" />
            </Box>
          ) : (
            <IconCircleCheck size="1.5rem" color="white" />
          )}
        </Box>
      )}
    </Paper>
  );
}
