import { SupabaseClient } from '@supabase/supabase-js';
import { Property } from './properties.types';

export interface PropertyImage {
  id?: number;
  street_address: string;
  zip_code: number;
  url: string;
}

export async function savePropertyImages(
  supabase: SupabaseClient,
  property: Property,
  imageUrls: string[],
) {
  const { error: errorDelete } = await supabase
    .from('property_images')
    .delete()
    .eq('street_address', property.street_address)
    .eq('zip_code', property.zip_code);

  if (errorDelete) {
    throw new Error("Couldn't delete property images");
  }

  const formattedImages = imageUrls.map((url) => {
    return {
      street_address: property.street_address,
      zip_code: property.zip_code,
      url,
    };
  });
  const { error } = await supabase
    .from('property_images')
    .insert(formattedImages);
  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't save property images");
  }
}

export async function fetchPropertyImages(
  supabase: SupabaseClient,
  street_address: string,
  zip_code: number,
): Promise<PropertyImage[] | null> {
  const { data, error } = await supabase
    .from('property_images')
    .select()
    .eq('street_address', street_address)
    .eq('zip_code', zip_code);

  if (error) {
    console.log('error: ', error);
    return null;
  }

  return data;
}
