import { Divider, Flex, Text, ThemeIcon, Paper, Badge } from '@mantine/core';
import React from 'react';
import { IconSparkles } from '@tabler/icons-react';
import { LeadAiSuggestions } from 'be/db';
import { Selectable } from 'kysely';
import { MessageSuggestion } from './SuggestionTypes/MessageSuggestion';
import { StatusSuggestion } from './SuggestionTypes/StatusSuggestion';

interface ConversationDetailProps {
  suggestions: Selectable<LeadAiSuggestions>[];
}

export function ConvoSuggestions({ suggestions }: ConversationDetailProps) {
  if (!suggestions || suggestions.length === 0) {
    return null;
  }
  const mapSuggestion = (suggestion: Selectable<LeadAiSuggestions>) =>
    suggestion.type === 'message' ? (
      <MessageSuggestion suggestion={suggestion} key={suggestion.id} />
    ) : (
      <StatusSuggestion suggestion={suggestion} key={suggestion.id} />
    );

  return (
    <>
      <Divider />

      <Paper p="md" radius="xs" bg="brand.0">
        <Flex align="center" justify="space-between">
          <Text>
            Hello! This is{' '}
            <Text span fw="bold">
              Rafi
            </Text>
            , I have a couple of suggestions:
          </Text>
          <Badge
            color="violet"
            pl={3}
            variant="outline"
            leftSection={
              <ThemeIcon size="xs" variant="transparent">
                <IconSparkles size={10} />
              </ThemeIcon>
            }
          >
            AI
          </Badge>
        </Flex>

        <Divider my="lg" />

        <Flex gap="sm" direction="column">
          {suggestions.map((suggestion) => mapSuggestion(suggestion))}
        </Flex>
      </Paper>
    </>
  );
}
