import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  OrganisationCompsCriteria,
  fetchOrganisationCompsCriteria,
  upsertOrganisationCompsCriteria,
} from 'shared/db';
import { supabase } from '../utils';

export const useOrganisationCompCriteria = (organisation_id: number) =>
  useQuery({
    queryKey: ['organisation_comp _criteria', organisation_id],
    queryFn: () => fetchOrganisationCompsCriteria(supabase, organisation_id),
  });

export const useUpsertCompCriteria = (organisation_id: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (compCriteria: OrganisationCompsCriteria) =>
      upsertOrganisationCompsCriteria(supabase, compCriteria),
    onSuccess: (data) => {
      queryClient.setQueriesData<OrganisationCompsCriteria>(
        { queryKey: ['organisation_comp', organisation_id] },
        () => data,
      );
    },
  });
};
