import { SupabaseClient } from '@supabase/supabase-js';

export interface Organisation {
  id: number;
  owned_by: string;
  name: string;
  from_email: string;
  from_name: string;
  email_signature: string;
  enable_incoming_email_parsing?: boolean;
  max_campaign_offers_limit: number;
  lead_webhook_url: string | null;
  api_key: string | null;
}

export async function fetchOrganisationById(
  supabase: SupabaseClient,
  organisationId: number,
): Promise<Organisation | null> {
  const { data, error } = await supabase
    .from('organisations')
    .select()
    .eq('id', organisationId)
    .limit(1)
    .single();

  if (error) {
    return null;
  }

  return data;
}

export async function fetchOrganisation(
  supabase: SupabaseClient,
  organisationId: number,
): Promise<Organisation> {
  const { data, error } = await supabase
    .from('organisations')
    .select()
    .eq('id', organisationId)
    .limit(1)
    .single();

  if (error) {
    console.log(error);
    throw new Error('Error fetching organisation');
  }

  return data;
}

export async function updateOrganisation(
  supabase: SupabaseClient,
  organisation: Partial<Organisation>,
): Promise<Organisation> {
  const { data, error } = await supabase
    .from('organisations')
    .update(organisation)
    .eq('id', organisation.id)
    .select()
    .single();

  if (error) {
    throw new Error("Couldn't update organisation");
  }

  return data;
}

export async function fetchOrganisations(
  supabase: SupabaseClient,
): Promise<Organisation[] | null> {
  const { data, error } = await supabase
    .from('organisations')
    .select()
    .is('deleted_at', null);

  if (error) {
    return null;
  }

  return data;
}

export async function fetchOrganisationByForwardEmail(
  supabase: SupabaseClient,
  forwardEmail: string,
): Promise<Organisation | null> {
  const { data, error } = await supabase
    .from('organisations')
    .select()
    .eq('forward_email', forwardEmail)
    .limit(1)
    .single();

  if (error || data === null) {
    return null;
  }

  return data;
}

export async function archiveOrganization(
  supabase: SupabaseClient,
  organization: Organisation,
): Promise<Organisation> {
  const { error } = await supabase
    .from('organisations')
    .update({
      deleted_at: new Date(),
    })
    .eq('id', organization.id);

  if (error) {
    throw new Error("Couldn't archive organization");
  }

  return organization;
}
