import { SupabaseClient } from '@supabase/supabase-js';

export enum PropertySearchSoldInLast {
  OneDay = '1',
  OneWeek = '7',
  TwoWeeks = '14',
  OneMonth = '30',
  ThreeMonths = '90',
  SixMonths = '6m',
  OneYear = '12m',
  TwoYears = '24m',
  ThreeYears = '36m',
}

export interface OrganisationCompsCriteria {
  organisation_id: number;
  sqft_range: number;
  proximity_miles: number | null;
  sold_days: PropertySearchSoldInLast;
  year_built_range: number | null;
  lot_size_range: number | null;
  must_match_stories: boolean | null;
  must_match_basement: boolean | null;
  must_match_parking: boolean | null;
  must_match_school_district: boolean | null;
}

export async function upsertOrganisationCompsCriteria(
  supabase: SupabaseClient,
  compsCriteria: OrganisationCompsCriteria,
): Promise<OrganisationCompsCriteria> {
  const { data, error } = await supabase
    .from('organisation_comp_criteria')
    .upsert(compsCriteria)
    .select()
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't upsert comp criteria");
  }

  return data;
}

export async function fetchOrganisationCompsCriteria(
  supabase: SupabaseClient,
  organisation_id: number,
): Promise<OrganisationCompsCriteria | null> {
  const { data, error } = await supabase
    .from('organisation_comp_criteria')
    .select()
    .eq('organisation_id', organisation_id)
    .single();

  if (error) {
    console.log('error fetching comp criteria: ', error);
    return null;
  }

  return data;
}
