import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  OrganisationContract,
  createOrganisationContract,
  updateOrganisationContract,
  uploadOrganisationContract,
} from 'shared/db';
import { callApi, supabase } from '../utils';
import {
  deleteContractEndpoint,
  fetchExampleContractEndpoint,
  organizationFetchContractsEndpoint,
} from 'shared/api';

export const useOrganisationContracts = (organisationId: number) =>
  useQuery({
    queryKey: ['organisation_contracts', organisationId],
    queryFn: () =>
      callApi(organizationFetchContractsEndpoint, {
        organization_id: organisationId,
      }).then((res) => res.data as OrganisationContract[]),
  });

interface CreateContractParams {
  file: File;
  label: string;
  buyer_name: string | null;
}

export const useCreateOrganisationContract = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ file, label, buyer_name }: CreateContractParams) => {
      const filename = await uploadOrganisationContract(
        supabase,
        organisationId,
        file,
      );
      return createOrganisationContract(supabase, {
        organisation_id: organisationId,
        filename,
        label,
        buyer_name,
      });
    },
    onSuccess: (data) => {
      queryClient.setQueriesData<OrganisationContract[]>(
        { queryKey: ['organisation_contracts', organisationId] },
        (old_data) => [...(old_data || []), data],
      );
    },
  });
};

export const useUpdateOrganisationContract = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (contract: OrganisationContract) =>
      updateOrganisationContract(supabase, contract),
    onSuccess: (data) => {
      queryClient.setQueriesData<OrganisationContract[]>(
        { queryKey: ['organisation_contracts', organisationId] },
        (old_data) =>
          (old_data || []).map((c) => (c.id === data.id ? data : c)),
      );
    },
  });
};

export const useDeleteOrganisationContract = (organisationId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (contract_id: number) =>
      callApi(deleteContractEndpoint, { contract_id }),
    onSuccess: (_, contract_id) => {
      queryClient.setQueriesData<OrganisationContract[]>(
        { queryKey: ['organisation_contracts', organisationId] },
        (old_data) => (old_data || []).filter((c) => c.id !== contract_id),
      );
    },
  });
};

export const useOrganisationContractPDF = (
  contract_id: number | null | undefined,
) =>
  useQuery({
    queryKey: ['organisation_contracts_pdf', contract_id],
    queryFn: async () =>
      contract_id &&
      callApi(fetchExampleContractEndpoint, { contract_id }).then(
        (res) => res.data as string,
      ),
  });
