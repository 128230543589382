import { useQuery } from '@tanstack/react-query';
import { fetchCampaignLocations } from 'shared/db';
import { supabase } from '../utils';

const keys = {
  all: ['campaign_locations'] as const,
  campaignLocations: (campaignId: number | undefined) => [
    ...keys.all,
    campaignId || null,
  ],
};

export const useCampaignLocations = (campaignId?: number) =>
  useQuery({
    queryKey: [keys.campaignLocations(campaignId)],
    queryFn: () =>
      campaignId ? fetchCampaignLocations(supabase, campaignId) : [],
  });
