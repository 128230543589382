import { useEffect, useState } from 'react';
import { supabase } from '../utils';
import { User as SupabaseUser } from '@supabase/supabase-js';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

export const useUser = () => {
  const [user, setUser] = useState<SupabaseUser | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchUserData = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    setUser(user);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  return { data: user, isLoading };
};

export interface User {
  id?: string;
  email: string;
}

export const useLogout = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: () => supabase.auth.signOut(),
    onSuccess: () => {
      navigate({ to: '/' });
    },
  });
};

export const useLogin = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (email: string) => {
      return supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: window.location.origin,
        },
      });
    },
    onSuccess: () => {
      navigate({ to: '/' });
    },
  });
};
