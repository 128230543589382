import { SupabaseClient } from '@supabase/supabase-js';

export interface OrganisationBlacklistedDomain {
  id: number;
  domain: string;
  organisation_id: number;
}

export type NewBlacklistedDomain = Omit<OrganisationBlacklistedDomain, 'id'>;

export async function fetchBlacklistedDomains(
  supabase: SupabaseClient,
  organisationId: number,
): Promise<OrganisationBlacklistedDomain[]> {
  const { data, error } = await supabase
    .from('organisation_blacklisted_domains')
    .select('*')
    .eq('organisation_id', organisationId);

  if (error) {
    console.error(error);
    throw new Error(
      `Error fetching blacklisted domains for id: ${organisationId}`,
    );
  }

  return data;
}

export async function deleteBlacklistedDomain(
  supabase: SupabaseClient,
  domainId: OrganisationBlacklistedDomain['id'],
): Promise<OrganisationBlacklistedDomain['id']> {
  const { error } = await supabase
    .from('organisation_blacklisted_domains')
    .delete()
    .eq('id', domainId);

  if (error) {
    throw new Error("Couldn't blacklist domain");
  }

  return domainId;
}

export async function createBlacklistedDomain(
  supabase: SupabaseClient,
  blacklistedDomain: NewBlacklistedDomain,
): Promise<OrganisationBlacklistedDomain> {
  const { data, error } = await supabase
    .from('organisation_blacklisted_domains')
    .insert(blacklistedDomain)
    .select()
    .single();

  if (error) {
    throw new Error("Couldn't blacklist domain");
  }

  return data;
}
