import {
  CompCriteriaSettings,
  ContractManagement,
  Integrations,
  LocationsForm,
  OrganisationBasicForm,
  Overview,
  PricingStrategySettings,
  RepairLevelSettings,
  WorkflowManagement
} from 'fe/feature/organisation-settings';
import { PageLoader } from './../../components/PageLoader';
import { SignatureEditor } from './../../components/SignatureEditor';
import { OrganisationEmailVerification } from './../../modules/OrganisationEmailVerification';
import {
  useAuthenticatedDomain,
  useIsAdmin,
  useLogout,
  useOrganisationStore
} from 'fe/queries';
import {
  Flex,
  Button,
  Tabs,
  Badge,
  Modal,
  Loader,
  ScrollArea,
  Container,
  Divider,
  Title
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconPhoto,
  IconMessageCircle,
  IconPaperclip,
  IconClock,
  IconTool,
  IconSearch,
  IconCurrencyDollar
} from '@tabler/icons-react';
import { Layout } from 'fe/feature/layout';
import classes from './settings.module.css';

export function SettingsPage() {
  const { data: isAdmin } = useIsAdmin();
  const { organisation, isLoading, archive, isArchiving } =
    useOrganisationStore();
  const { mutateAsync: logout, isPending: isPendingLogout } = useLogout();
  const { data: domain, isLoading: isLoadingDomainVerification } =
    useAuthenticatedDomain(organisation?.id || 0);
  const [
    openedOrganisation,
    { open: openOrganisation, close: closeOrganisation }
  ] = useDisclosure(false);

  const NotDoneBadge = (
    <Badge
      w={16}
      h={16}
      sx={{ pointerEvents: 'none' }}
      variant="filled"
      size="xs"
      p={0}
      color="red"
    >
      X
    </Badge>
  );

  const DoneBadge = (
    <Badge
      w={16}
      h={16}
      sx={{ pointerEvents: 'none' }}
      variant="filled"
      size="xs"
      p={0}
    >
      ✓
    </Badge>
  );

  if (isLoading || !organisation) return <PageLoader />;

  return (
    <Layout noScrolling noPadding>
      <Modal
        opened={openedOrganisation}
        onClose={closeOrganisation}
        title="Create organization"
        size="lg"
      >
        <OrganisationBasicForm onSucess={closeOrganisation} />
      </Modal>

      <Flex direction="column" w="100%" gap="md" my="md">
        <Tabs
          orientation="vertical"
          defaultValue="overview"
          w="100%"
          keepMounted={false}
          classNames={{
            root: classes.tabsRoot,
            panel: classes.tabsPanel,
            tabsList: classes.tabsList
          }}
        >
          <Tabs.List>
            <Flex direction="column" justify="space-between" h="100%" w="100%">
              <Flex w="100%" direction="column" gap="sm">
                <Tabs.Tab value="overview" icon={<IconPhoto size="0.8rem" />}>
                  Overview
                </Tabs.Tab>
                {isAdmin && (
                  <Tabs.Tab
                    value="locations"
                    icon={<IconClock size="0.8rem" />}
                  >
                    Locations
                  </Tabs.Tab>
                )}
                <Tabs.Tab
                  value="contracts"
                  icon={<IconPaperclip size="0.8rem" />}
                >
                  Contracts
                </Tabs.Tab>
                <Tabs.Tab value="workflow" icon={<IconClock size="0.8rem" />}>
                  Workflow
                </Tabs.Tab>
                <Tabs.Tab
                  value="repair_costs"
                  icon={<IconTool size="0.8rem" />}
                >
                  Repair Costs
                </Tabs.Tab>
                <Tabs.Tab value="comps" icon={<IconSearch size="0.8rem" />}>
                  Comps Search Criteria
                </Tabs.Tab>
                <Tabs.Tab
                  value="pricing"
                  icon={<IconCurrencyDollar size="0.8rem" />}
                >
                  Offer Pricing Strategy
                </Tabs.Tab>
                <Tabs.Tab
                  value="crm"
                  icon={<IconMessageCircle size="0.8rem" />}
                >
                  CRM Integration
                </Tabs.Tab>
                <Tabs.Tab
                  value="email"
                  icon={<IconMessageCircle size="0.8rem" />}
                  rightSection={
                    isLoadingDomainVerification ? (
                      <Loader size={16} />
                    ) : domain?.valid ? (
                      DoneBadge
                    ) : (
                      NotDoneBadge
                    )
                  }
                >
                  Email Settings
                </Tabs.Tab>
              </Flex>

              <Flex direction="column" gap="xs" px="xs">
                {isAdmin && (
                  <>
                    <Button
                      w="100%"
                      variant="outline"
                      color="red"
                      onClick={() => archive()}
                      loading={isArchiving}
                    >
                      Archive organization
                    </Button>
                    <Button
                      w="100%"
                      onClick={openOrganisation}
                      variant="outline"
                    >
                      Create new organization
                    </Button>
                  </>
                )}

                <Button
                  w="100%"
                  onClick={() => logout()}
                  loading={isPendingLogout}
                  variant="light"
                  color="red"
                >
                  Logout
                </Button>
              </Flex>
            </Flex>
          </Tabs.List>

          <Tabs.Panel value="overview" pt="xs">
            <div className={classes.inboxWrapper}>
              <ScrollArea style={{ height: '100%' }}>
                <Container>
                  <Title order={1}>Overview</Title>
                  <Divider my="sm" />

                  <Overview />
                </Container>
              </ScrollArea>
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="contracts" pt="xs">
            <div className={classes.inboxWrapper}>
              <ContractManagement />
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="workflow" pt="xs">
            <div className={classes.inboxWrapper}>
              <ScrollArea style={{ height: '100%' }}>
                <WorkflowManagement />
              </ScrollArea>
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="locations" pt="xs">
            <div className={classes.inboxWrapper}>
              <ScrollArea style={{ height: '100%' }}>
                <Container>
                  <LocationsForm organisation={organisation} />
                </Container>
              </ScrollArea>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="repair_costs" pt="xs">
            <div className={classes.inboxWrapper}>
              <ScrollArea style={{ height: '100%' }}>
                <RepairLevelSettings />
              </ScrollArea>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="comps" pt="xs">
            <div className={classes.inboxWrapper}>
              <ScrollArea style={{ height: '100%' }}>
                <CompCriteriaSettings />
              </ScrollArea>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="pricing" pt="xs">
            <div className={classes.inboxWrapper}>
              <ScrollArea style={{ height: '100%' }}>
                <PricingStrategySettings />
              </ScrollArea>
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="crm" pt="xs">
            <Integrations />
          </Tabs.Panel>

          <Tabs.Panel value="email" pt="xs">
            <div className={classes.inboxWrapper}>
              <ScrollArea style={{ height: '100%' }}>
                <Container>
                  <Title order={1}>Email Settings</Title>
                  <Divider my="sm" />

                  <OrganisationEmailVerification organisation={organisation} />

                  <Title order={2} mt="xl">
                    Email Signature
                  </Title>

                  <Divider my="sm" />

                  <SignatureEditor organisation={organisation} />
                </Container>
              </ScrollArea>
            </div>
          </Tabs.Panel>
        </Tabs>
      </Flex>
    </Layout>
  );
}
