import { ActionIcon, Button, Flex, Text, ThemeIcon, } from "@mantine/core";
import React from "react";
import { IconCheck, } from "@tabler/icons-react";

interface CampaignOnboardingStepProps {
    icon: React.ReactNode;
    title: string;
    description: string;
    onClick: () => void;
    buttonTxt: string;
    done?: boolean;
    optional?: boolean;
}

export function CampaignOnboardingStep({
    icon,
    title,
    description,
    done = false,
    onClick,
    buttonTxt,
    optional
}: CampaignOnboardingStepProps) {
    return (
        <Flex gap="md" align="center" justify="space-between">
            <Flex gap="md" style={{ opacity: done ? .4 : .7 }} align="center">
                <ThemeIcon size="xl" color="gray">
                    {icon}
                </ThemeIcon>

                <Flex direction="column">
                    <Text fw="bold">{title}</Text>
                    <Text>{description}</Text>
                </Flex>
            </Flex>

            {done ? (
                <ActionIcon onClick={onClick} size="lg" color="brand" variant="filled" radius="xl">
                    <IconCheck size="1.5rem" />
                </ActionIcon>
            ) : (

                <Button onClick={onClick} variant={optional ? 'outline' : 'filled'}>
                    {buttonTxt}
                </Button>
            )}
        </Flex>
    )
}