import { Highlight, Button } from '@mantine/core';
import React from 'react';
import { LeadAiSuggestions } from 'be/db';
import { Selectable } from 'kysely';
import { useUpdateLead } from 'fe/queries';
import { StatusOfCampaignProperty } from 'shared/db';
import { ConvoSuggestionSkeleton } from './ConvoSuggestionSkeleton';
import { formatStatusToLabel } from 'shared/formatter';

interface ConvoStatusSuggestionProps {
  suggestion: Selectable<LeadAiSuggestions>;
}

export function StatusSuggestion({ suggestion }: ConvoStatusSuggestionProps) {
  const { mutateAsync: updateLead, isPending: updatingLead } = useUpdateLead(
    suggestion.lead_id,
  );

  const onClick = async () => {
    await updateLead(suggestion.data as StatusOfCampaignProperty);
  };

  const formattedStatus = formatStatusToLabel(suggestion.data);
  return (
    <ConvoSuggestionSkeleton
      suggestion_id={suggestion.id}
      title={
        <Highlight
          highlight={formattedStatus}
          highlightColor="violet.0"
          highlightStyles={{ padding: 4 }}
        >
          {`Change status to ${formattedStatus}`}
        </Highlight>
      }
      reasoning={suggestion.reasoning}
      callToActions={
        <Button
          loading={updatingLead}
          onClick={onClick}
          color="violet"
          variant="outline"
          size="xs"
        >
          Apply
        </Button>
      }
    />
  );
}
