import { SupabaseClient } from '@supabase/supabase-js';
import { Location } from '../campaign/campaign_locations';

interface FetchPaginatedAvailableLocationsParams {
  supabase: SupabaseClient;
  organisationId: number;
  state: string;
  previousZip?: number;
}

export type AvailableLocation = {
  zip_code_string: string;
} & Location;

const fetchPaginatedAvailableLocations = async ({
  supabase,
  organisationId,
  state,
  previousZip,
}: FetchPaginatedAvailableLocationsParams): Promise<AvailableLocation[]> => {
  const query = supabase
    .from('available_locations')
    .select('*')
    .eq('state_id', state)
    .or(`zip_code.is.null, id.eq.${organisationId}`)
    .order('zip')
    .limit(1000);

  if (previousZip) {
    query.gt('zip', previousZip);
  }

  const { data, error } = await query;

  if (error) {
    throw new Error("Couldn't fetch locations");
  }

  return data;
};

export async function fetchAvailableLocations(
  supabase: SupabaseClient,
  organisationId: number,
  state: string,
): Promise<AvailableLocation[]> {
  console.log('start');
  if (!organisationId) {
    return [];
  }
  const { count } = await supabase
    .from('available_locations')
    .select('*', { count: 'exact', head: true })
    .eq('state_id', state)
    .or(`zip_code.is.null, id.eq.${organisationId}`);

  if (!count) {
    throw new Error('No locations found');
  }

  const locations: AvailableLocation[] = [];
  while (locations.length < count) {
    const previousZip = locations[locations.length - 1]?.zip;
    const nextData = await fetchPaginatedAvailableLocations({
      supabase,
      organisationId,
      state,
      previousZip,
    });
    locations.push(...nextData);
  }

  return locations;
}
