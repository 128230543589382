import { Button, Group, Flex, ScrollArea, Stepper } from '@mantine/core';
import { useOrganisationStore } from 'fe/queries';
import { SmartCampaignFormExtra } from './SmartCampaignFormExtra';
import { BasicCampaignFormExtra } from './BasicCampaignFormExtra';
import { useCampaignForm } from './useCampaignForm';
import { modals } from '@mantine/modals';
import { DeleteCampaignConfirmation } from './DeleteCampaignConfirmation';
import { InputWrapper } from '../InputWrapper/InputWrapper';
import { CampaignsBasicsForm } from './CampaignBasicsForm';

interface CampaignFormProps {
  campaign?: any;
  smallMode?: boolean;
}

export function CampaignForm({
  campaign,
  smallMode = false,
}: CampaignFormProps) {
  const { organisation } = useOrganisationStore();
  const {
    form,
    isLoading,
    campaignSmart,
    setCampaignSmart,
    onSubmit,
    active,
    nextStep,
    prevStep,
  } = useCampaignForm({ campaign });

  const openDeleteModal = () =>
    modals.open({
      title: 'Archive campaign?',
      children: (
        <DeleteCampaignConfirmation
          campaign={campaign}
          onClose={modals.closeAll}
        />
      ),
    });

  if (!organisation) return null;

  if (!campaign) {
    return (
      <form onSubmit={onSubmit} style={{ width: '100%', height: '95%' }}>
        <ScrollArea w="100%" h="100%">
          <Stepper size="xs" active={active} breakpoint="sm">
            <Stepper.Step label="Basics">
              <CampaignsBasicsForm
                form={form}
                organisation={organisation}
                campaignSmart={campaignSmart}
                setCampaignSmart={setCampaignSmart}
              />
            </Stepper.Step>

            <Stepper.Step label="Options">
              {campaignSmart === 'basic' ? (
                <BasicCampaignFormExtra
                  form={form}
                  organisation={organisation}
                />
              ) : (
                <SmartCampaignFormExtra
                  form={form}
                  organisation={organisation}
                />
              )}
            </Stepper.Step>
          </Stepper>

          {active < 2 && (
            <Group position="center" mt="xl">
              <Button variant="default" onClick={prevStep}>
                Back
              </Button>
              <Button onClick={nextStep} loading={isLoading}>
                {active === 0 ? 'Next step' : 'Create'}
              </Button>
            </Group>
          )}
        </ScrollArea>
      </form>
    );
  }

  return (
    <form onSubmit={onSubmit} style={{ width: '100%', height: '95%' }}>
      <ScrollArea w="100%" h="100%">
        <Flex gap="xl" direction={smallMode ? 'column' : 'row'}>
          <div style={{ width: smallMode ? '100%' : '50%' }}>
            <CampaignsBasicsForm
              form={form}
              organisation={organisation}
              campaignSmart={campaignSmart}
              setCampaignSmart={setCampaignSmart}
            />

            <BasicCampaignFormExtra form={form} organisation={organisation} />

            <InputWrapper
              title="Archive campaign"
              description="Hide and deactivate this campaign."
            >
              <Button
                loading={isLoading}
                onClick={openDeleteModal}
                color="red"
                variant="outline"
              >
                Archive
              </Button>
            </InputWrapper>
          </div>
        </Flex>
        <Group position="right" my="md">
          <Button type="submit" loading={isLoading}>
            {campaign ? 'Update campaign' : 'Create campaign'}
          </Button>
        </Group>
      </ScrollArea>
    </form>
  );
}
