import { Property } from 'shared/db';
import omitBy from 'lodash.omitby';
import isNull from 'lodash.isnull';
import { Optional } from 'utility-types';
import { Buffer } from 'buffer';

export const mergeTwoObjects = (
  obj1: Property,
  obj2: Optional<Property, 'listed_date'>,
): Property => {
  return { ...omitBy(obj1, isNull), ...omitBy(obj2, isNull) } as Property;
};

export const extractMessageContent = (emailBody: string) => {
  // Regular expression to match the message content above the signature with dotAll flag
  const regex = /([\s\S]*?)(?=\bOn\b.*\bwrote\b)/;
  const match = regex.exec(emailBody);
  if (match && match.length > 1) {
    return match[1].trim(); // Return the captured group excluding "On"
  }
  return emailBody; // Return the entire email body if no match found
};

export const scrollToBottom = (id: string) => {
  const element = document.querySelector(
    '#chat-scroll .mantine-ScrollArea-viewport',
  );
  if (!element) return;

  element.scrollTop = element.scrollHeight;
};

export const bufferToArray = (reqBody: any) => {
  const payload = Buffer.from(reqBody).toString(); // Convert from base64 to string
  return payload.split('--xYzZY'); //This is the default boundary for multipart MIME messages
};

export function getRandomInt(min: number, max: number) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
}

export function sortMessages<T extends { created_at: string | Date }>(
  messages: T[],
) {
  return messages.sort((a, b) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  });
}

export const sanitizeFilename = (filename: string) => {
  return filename
    .replace(/[^a-zA-Z0-9.-]/g, '_') // Replace unsupported characters with '_'
    .replace(/_+/g, '_'); // Remove consecutive underscores
};
