import { NumberInput, Switch, Box } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Organisation } from 'shared/db';
import { InputWrapper } from '../InputWrapper/InputWrapper';
import { CampaignFormValues } from './useCampaignForm';

interface BasicCampaignFormExtra {
  form: UseFormReturnType<CampaignFormValues>;
  organisation: Organisation;
}

export function BasicCampaignFormExtra({
  form,
  organisation,
}: BasicCampaignFormExtra) {
  return (
    <Box>
      <InputWrapper
        title="Offer discount"
        description="What percentage discount should be offered to leads? (Based on the latest listed price)"
      >
        <NumberInput
          mb="sm"
          withAsterisk
          {...form.getInputProps('price_discount')}
        />
      </InputWrapper>

      <InputWrapper
        title="Daily Offers Limit"
        description={`How many offers do you want to send per day? Your organisation is limited to a maximum of ${organisation?.max_campaign_offers_limit} daily per campaign. Contact support to increase this limit.`}
      >
        <NumberInput
          mb="sm"
          withAsterisk
          {...form.getInputProps('daily_offers_limit')}
          max={organisation?.max_campaign_offers_limit}
        />
      </InputWrapper>

      <InputWrapper
        title="Offers Require Approval Before Sending"
        description="Enable this option if you want to be able to review offers before they're sent to leads."
      >
        <Switch
          styles={{
            input: { cursor: 'pointer' },
            label: { cursor: 'pointer' },
          }}
          mt="sm"
          checked={form.values['offers_require_approval']}
          {...form.getInputProps('offers_require_approval')}
        />
      </InputWrapper>

      <InputWrapper
        title="Campaign Is Active"
        description="Turn off campaign to stop sending offers."
      >
        <Switch
          styles={{
            input: { cursor: 'pointer' },
            label: { cursor: 'pointer' },
          }}
          mb="sm"
          checked={form.values['active']}
          {...form.getInputProps('active')}
        />
      </InputWrapper>
    </Box>
  );
}
