import { ApiEndpoint } from './types';
import { z } from 'zod';

const parseIncomingEmailRouteSchema = z.object({
  organization_id: z.number(),
});

export const parseIncomingEmailEndpoint: ApiEndpoint<
  typeof parseIncomingEmailRouteSchema
> = {
  apiUrl: '/organizations/:organization_id/parseIncomingEmail',
  clientUrl: ({ organization_id }) =>
    `/organizations/${organization_id}/parseIncomingEmail`,
  method: 'POST',
};
