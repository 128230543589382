import { ActionIcon, Box, Flex, Paper, } from "@mantine/core";
import { useDeletePricingStrategy, useOrganisationStore, usePricingStrategies } from "fe/queries";
import { PricingStrategy } from "shared/db";
import { Typography } from "fe/ui/shared";
import { IconPencil, IconPlus, IconX } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { PricingStrategyForm } from "./PricingStrategyForm";


export function PricingStrategySettings() {
    const { organisation } = useOrganisationStore()
    if (!organisation) return null
    const { data: pricingStrategies } = usePricingStrategies(organisation.id)
    const { mutateAsync: deletePricingStrategy, isPending: isDeleting } = useDeletePricingStrategy(organisation.id)

    const open = (pricingStrategy?: PricingStrategy) => {
        modals.open({
            title: pricingStrategy ? 'Update pricing strategy' : 'Create pricing strategy',
            children: (
                <PricingStrategyForm onSuccess={() => modals.closeAll()} pricingStrategy={pricingStrategy} />
            ),
        });
    }

    return (
        <Flex display="flex" h="100%" py="md" bg="#F8F9FA">
            <Box px="xl" maw={800} w="100%">
                <Flex align="center" justify="space-between">
                    <Typography variant="label">Pricing Strategies</Typography>
                    <ActionIcon variant="filled" color="brand" onClick={() => open()}>
                        <IconPlus size="1.125rem" />
                    </ActionIcon>
                </Flex>

                <Flex mt="xl" direction="column" gap="md">
                    {pricingStrategies?.map(strategy => (
                        <Paper p="xs" w="100%" radius="md">
                            <Flex justify="space-between" w="100%" align="center">
                                <Flex direction="column">
                                    <Typography fz="xs">Strategy Name</Typography>
                                    <Typography>{strategy.name}</Typography>
                                </Flex>

                                <Flex gap="xs">
                                    <ActionIcon variant="outline" color="brand" onClick={() => open(strategy)}>
                                        <IconPencil size="1.125rem" />
                                    </ActionIcon>
                                    <ActionIcon
                                        variant="light"
                                        color="gray"
                                        loading={isDeleting}
                                        onClick={() => deletePricingStrategy(strategy.id)}
                                    >
                                        <IconX size="1.125rem" />
                                    </ActionIcon>
                                </Flex>
                            </Flex>
                        </Paper>
                    ))}
                </Flex>
            </Box>
        </Flex>
    )
}