import React from "react";
import { Flex, Text } from "@mantine/core";
import classes from './SidebarButton.module.scss';

interface SidebarButtonProps {
    leftSection?: React.ReactNode;
    children: React.ReactNode;
    reverse?: boolean;
    mt?: string;
    active: boolean;
    onClick?: () => void;

}
export function SidebarButton({
    children,
    leftSection,
    active,
    onClick,
    reverse = false,
    mt = undefined,
}: SidebarButtonProps) {
    return (
        <Flex
            onClick={onClick}
            px={20}
            py={mt}
            className={`${classes.SidebarButton} ${active ? classes.active : ''}`}
            align="center"
            gap="xs"
            style={{ opacity: .8 }}
            direction={reverse ? "row-reverse" : 'row'}
        >

            <Text fz="xs" style={{ flex: 1 }}>
                {children}
            </Text>

            {leftSection}
        </Flex>
    )
}