import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Box, Textarea } from '@mantine/core';
import React from 'react';
import { modals } from '@mantine/modals';
import { useCreateNote } from 'fe/queries';
import { InboxItem } from 'shared/db';
import { isNotEmpty, useForm } from '@mantine/form';

interface ForwardConversationButtonProps {
    item: InboxItem;
}

export function CreateNoteButton({
    item
}: ForwardConversationButtonProps) {
    const [opened, { open, close }] = useDisclosure(false);
    const { mutateAsync, isPending } = useCreateNote(Number(item.organisation_id))
    const form = useForm({
        initialValues: {
            content: '',
        },
        validate: {
            content: isNotEmpty(),
        }
    });
    const onSubmit = form.onSubmit(async (values) => {
        await mutateAsync({
            content: values.content,
            street_address: item.street_address,
            zip_code: Number(item.zip_code),
            organisation_id: Number(item.organisation_id),
        })
        close()
        modals.closeAll()
    })

    return (
        <Box>
            <Modal opened={opened} onClose={close} title="Create note">
                <form onSubmit={onSubmit}>
                    <Textarea
                        autosize
                        minRows={6}
                        maxRows={10}
                        label="Content of the new note"
                        placeholder="Property is..."
                        data-autofocus
                        {...form.getInputProps('content')}
                    />
                    <Button type="submit" loading={isPending} fullWidth mt="md">
                        Create
                    </Button>
                </form>
            </Modal>

            <Button fullWidth variant='subtle' size="xs" onClick={open}>Create note</Button>
        </Box>
    );
}


