import { SupabaseClient } from '@supabase/supabase-js';

export interface OrganisationWorkflow {
  id: number;
  organisation_id: number;
  name: string;
}

export async function fetchOrganisationWorkflow(
  supabase: SupabaseClient,
  id: number,
): Promise<OrganisationWorkflow | null> {
  const { data, error } = await supabase
    .from('organisation_workflows')
    .select()
    .eq('id', id)
    .single();

  if (error) {
    return null;
  }

  return data;
}

export async function fetchOrganisationWorkflows(
  supabase: SupabaseClient,
  organisation_id: number,
): Promise<OrganisationWorkflow[]> {
  const { data, error } = await supabase
    .from('organisation_workflows')
    .select()
    .eq('organisation_id', organisation_id);

  if (error) {
    console.log('error fetching workflows: ', error);
    return [];
  }

  return data;
}

export async function createOrganisationWorkflow(
  supabase: SupabaseClient,
  workflow: Omit<OrganisationWorkflow, 'id'>,
): Promise<OrganisationWorkflow> {
  const { data, error } = await supabase
    .from('organisation_workflows')
    .insert(workflow)
    .select()
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't create workflow");
  }

  return data;
}

export async function updateOrganisationWorkflow(
  supabase: SupabaseClient,
  workflow: OrganisationWorkflow,
) {
  const { error } = await supabase
    .from('organisation_workflows')
    .update(workflow)
    .eq('id', workflow.id);

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't update workflow");
  }

  return workflow;
}

export async function deleteOrganisationWorkflow(
  supabase: SupabaseClient,
  contract_id: number,
) {
  const { error } = await supabase
    .from('organisation_workflows')
    .delete()
    .eq('id', contract_id);

  if (error) {
    console.log('error deleting workflow: ', error);
  }
}

// TODO: Remove

export async function createTest(
  supabase: SupabaseClient,
  name: string,
): Promise<OrganisationWorkflow> {
  const { data, error } = await supabase
    .from('test')
    .insert({ name })
    .select()
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't create test");
  }

  return data;
}
